import './styles.scss'
import React, { useState } from 'react'
import { LoaderIcon } from 'Assets/Icons'

const Loader = (WrappedComponent) => {
  function WrappedWithLoader(props) {
    const [isLoading, setLoading] = useState(false)

    const setLoadingState = (isComponentLoading) => {
      setLoading(isComponentLoading)
    }

    return (
      <div className="loderHOC">
        {isLoading && (
          <div className="loader_wrapper">
            <LoaderIcon data-testid="loader-icon" />
          </div>
        )}
        <WrappedComponent {...props} setLoading={setLoadingState} />
      </div>
    )
  }

  return WrappedWithLoader
}

export default Loader
