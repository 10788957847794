import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TabView, TabPanel } from 'primereact/tabview'
import LayoutContainer from 'Components/LayoutComponent'
import ApproveGlobalDifferential from './ApproveGlobalDifferential'
import ApproveGlobalRawCoffeePrice from './ApproveGlobalRawCoffeePrice'

const ApproveGlobalData = () => {
  const { i18n, t } = useTranslation()

  return (
    <LayoutContainer title={t('APPROVE_GLOBAL_DATA')} breadCrumbsTitle={'approveGlobalData'}>
      <div className="card global-tab-view-container">
        <TabView>
          <TabPanel className="approval-global-tab-view" header={t('GLOBAL_COFFEE_PRICE')}>
            <ApproveGlobalRawCoffeePrice />
          </TabPanel>
          <TabPanel header={t('GLOBAL_DIFFERENTIAL')}>
            <ApproveGlobalDifferential />
          </TabPanel>
        </TabView>
      </div>
    </LayoutContainer>
  )
}

export default ApproveGlobalData
