import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { TABLE_CONST } from 'Utils/Constants'
import { DialogModal } from 'Components/UIComponents'
import { TabPanel, TabView } from 'primereact/tabview'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import coffeeReportJsonLinks from 'Utils/Constants/coffeeReportsJsonLink'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import AddWeeklyWiseReport from './AddWeeklyWiseReport'
import EditWeeklyWiseReport from './EditWeeklyWiseReport'

const WeeklyWiseReports = ({ setLoading, mockContractDate, mockWeeklyWiseReportData }) => {
  const { t } = useTranslation()
  let rowCount = 8
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const { robustaWeeklyWiseCotReports, arabicaWeeklyWiseCotReports } = coffeeReportJsonLinks

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [leftTableHeader, setLeftTableHeader] = useState({ contract: '', date: '' })
  const [leftTableData, setLeftTableData] = useState([])
  const [weeklyWiseReportData, setWeeklyWiseReportData] = useState([])
  const [getApiData, setGetApiData] = useState([])
  const [dateList, setDateList] = useState([])
  const [dateSelected, setDateSelected] = useState(null)
  const [labelReport, setLabelReport] = useState([])
  const [labelSelected, setLabelSelected] = useState(null)
  const [visible, setVisible] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [selectedMarket, setSelectedMarket] = useState({
    idMarket: 1,
    marketName: 'ROBUSTA',
    marketCode: 'RC'
  })
  const [editDataSelected, setEditDataSelected] = useState({
    rowData: null,
    categoryData: null
  })

  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {} })

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    fetchDataByReportName()
  }, [selectedMarket, activeTab])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockContractDate) {
      setLeftTableData(mockContractDate)
      setWeeklyWiseReportData(mockWeeklyWiseReportData)
      setLabelSelected(mockWeeklyWiseReportData.label)
    }
  }, [])

  const reportName = selectedMarket.idMarket === 1 ? robustaWeeklyWiseCotReports : arabicaWeeklyWiseCotReports

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const fetchDataByReportName = async () => {
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setGetApiData(response.data)

      const { contract, date, details, netChange, openInterest, percentageOpenInterest, totalTraders } = response.data[0]

      const labelData = details.map((item) => item).map((e) => e.label)
      const dateData = response.data.map((item) => item).map((e) => e.date)

      setWeeklyWiseReportData(details[0])
      setLabelReport(labelData)
      setLabelSelected(labelData[0])
      setDateList(dateData)
      setDateSelected(dateData[0])
      setLeftTableHeader({ contract, date })

      setLeftTableData([
        { label: t('OPEN_INTEREST'), value: openInterest },
        { label: t('NET_CHANGE'), value: netChange },
        { label: t('PERCENTAGE_OPEN_INTEREST'), value: percentageOpenInterest },
        { label: t('TOTAL_TRADERS'), value: totalTraders }
      ])
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => fetchDataByReportName,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => {
    setErrorModal({ ...errorModal, showModal: false })
  }

  const handleLabelSelection = (event) => {
    const selectedLabel = event.target.value
    const dateFilteredData = getApiData.filter((element) => element.date === dateSelected)
    const filteredByLabel = dateFilteredData[0]?.details.filter((item) => item.label === selectedLabel)

    setLabelSelected(selectedLabel)

    if (filteredByLabel && filteredByLabel.length > 0) {
      setWeeklyWiseReportData(filteredByLabel[0])
    } else {
      setWeeklyWiseReportData([])
    }
  }

  const handleDateSelection = (event) => {
    const selectedDate = event.target.value

    const dateFilteredData = getApiData.filter((element) => element.date === selectedDate)

    if (dateFilteredData.length > 0) {
      const { contract, date, netChange, openInterest, percentageOpenInterest, totalTraders, details } = dateFilteredData[0]
      const updatedLabelReport = details.map((item) => item.label)

      setDateSelected(selectedDate)
      setWeeklyWiseReportData(details[0])
      setLabelReport(updatedLabelReport)
      setLabelSelected(updatedLabelReport[0])
      setLeftTableHeader({ contract, date })
      setLeftTableData([
        { label: t('OPEN_INTEREST'), value: openInterest },
        { label: t('NET_CHANGE'), value: netChange },
        { label: t('PERCENTAGE_OPEN_INTEREST'), value: percentageOpenInterest },
        { label: t('TOTAL_TRADERS'), value: totalTraders }
      ])
    } else {
      setDateSelected(selectedDate)
      setWeeklyWiseReportData([])
      setLabelReport([])
      setLabelSelected(null)
      setLeftTableHeader({ contract: '', date: '' })
      setLeftTableData([])
    }
  }

  const handleTabChange = (index) => {
    setActiveTab(index)

    const reportName = index === 0 ? robustaWeeklyWiseCotReports : arabicaWeeklyWiseCotReports

    const updatedMarket = index === 0 ? { idMarket: 1, marketName: 'ROBUSTA', marketCode: 'RC' } : { idMarket: 2, marketName: 'ARABICA', marketCode: 'KC' }

    setSelectedMarket(updatedMarket)
    setWeeklyWiseReportData(reportName)
    setEditDataSelected({
      rowData: null,
      categoryData: null
    })
  }

  const dataField = [
    {
      dataField: 'long',
      text: t('LONG'),
      className: 'long-column'
    },
    {
      dataField: 'short',
      text: t('SHORT'),
      className: 'short-column'
    },
    {
      dataField: 'total',
      text: t('TOTAL'),
      className: 'total-column'
    },

    {
      dataField: 'percentLong',
      text: t('PERCENT_LONG'),
      className: 'percent-long-column'
    },
    {
      dataField: 'percentShort',
      text: t('PERCENT_SHORT'),
      className: 'percent-short-column'
    },
    {
      dataField: 'net',
      text: t('NET'),
      className: 'net-column'
    },
    {
      dataField: 'spreading',
      text: t('SPREADING'),
      className: 'spreading-column'
    }
  ]

  const leftTable = [
    {
      dataField: 'label',
      text: leftTableHeader.date,
      className: 'long-column'
    },
    {
      dataField: 'value',
      text: leftTableHeader.contract,
      className: 'short-column'
    }
  ]

  const deleteWeeklyWiseReport = async () => {
    setLoading(true)
    setErrorModal({ ...errorModal, showModal: false })

    try {
      const response = await apiAdapterCoffeeWeb.DeleteForWeeklyWiseReport({ fileName: reportName, date: dateSelected })

      const updatedData = response.data

      setGetApiData(updatedData)

      const { contract, date, details, netChange, openInterest, percentageOpenInterest, totalTraders } = updatedData[0]

      const labelData = details.map((item) => item).map((e) => e.label)
      const dateData = updatedData.map((item) => item).map((e) => e.date)

      setWeeklyWiseReportData(details[0])
      setLabelReport(labelData)
      setLabelSelected(labelData[0])
      setDateList(dateData)
      setDateSelected(dateData[0])

      setLeftTableHeader({ contract, date })
      setLeftTableData([
        { label: t('OPEN_INTEREST'), value: openInterest },
        { label: t('NET_CHANGE'), value: netChange },
        { label: t('PERCENTAGE_OPEN_INTEREST'), value: percentageOpenInterest },
        { label: t('TOTAL_TRADERS'), value: totalTraders }
      ])

      if (response.status === 200) {
        setErrorModal({
          ...errorModal,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_HAS_BEEN_DELETED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'info',
        message: t('DELETE_WEEKLY_WISE_REPORT'),
        onConfirmation: deleteWeeklyWiseReport,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    setErrorModal({
      ...errorModal,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_WEEKLY_REPORT'),
      onConfirmation: deleteWeeklyWiseReport,
      onHide: handleDismiss
    })
  }

  const editData = () => {
    const chosenData = getApiData?.find((item) => item.date === dateSelected)

    const chosenCategory = chosenData?.details.find((category) => category.label === labelSelected)

    setEditDataSelected({
      rowData: chosenData,
      categoryData: chosenCategory
    })

    setOpenModal(true)
  }

  const hasSpreadingData = labelSelected === 'FUND BUYERS' || labelSelected === 'SWAP DEALERS' || labelSelected === 'OTHER REPORTABLE'
  const filteredDataFields = hasSpreadingData ? dataField : dataField.filter((item) => item.dataField !== 'spreading')

  const renderTableData = () => (
    <>
      <DataTable value={leftTableData} paginator={leftTableData?.length} rows={rowCount} pageLinkSize={paginationButtons} className="left-data-table">
        {leftTable.map((item, index) => (
          <Column key={index} field={item.dataField} header={item.text} body={item.body} className={item.className} />
        ))}
      </DataTable>
      <div className="dropdown-container" data-testid="category-dropdown">
        <Dropdown value={labelSelected} options={labelReport} onChange={handleLabelSelection} placeholder={t('SELECT_REPORT')} />
      </div>
      <DataTable value={weeklyWiseReportData?.data} paginator={weeklyWiseReportData?.data?.length} rows={rowCount} pageLinkSize={paginationButtons} className="right-data-table">
        {filteredDataFields.map((item, index) => (
          <Column key={index} field={item.dataField} header={item.text} body={item.body} className={item.className} />
        ))}
      </DataTable>
    </>
  )

  return (
    <LayoutContainer title={`${t('WEEKLY_WISE_REPORTS')} - ${activeTab === 0 ? t('ROBUSTA_TITLE') : t('ARABICA_TITLE')}`} breadCrumbsTitle={'weeklyWiseReports'} showAddButton={true} showEditButton={true} showDeleteButton={true} handleAddButtonClick={() => setVisible(true)} handleEditButtonClick={editData} handleDeleteButtonClick={handleDelete}>
      <div className="weekly-wise-reports-container" data-testid="weekly-wise-reports-container">
        <div className="weekly-wise-header-container">
          <div className="dropdown-wrapper" data-testid="dropdown-wrapper">
            <Dropdown value={dateSelected} options={dateList} onChange={handleDateSelection} placeholder={t('SELECT_DATE')} />
          </div>
        </div>
        <div className="table-container">
          <TabView activeIndex={activeTab} onTabChange={(e) => handleTabChange(e.index)}>
            <TabPanel header={t('ROBUSTA')}>{renderTableData()}</TabPanel>
            <TabPanel header={t('ARABICA')}>{renderTableData()}</TabPanel>
          </TabView>
        </div>
        <AddWeeklyWiseReport visible={visible} setVisible={setVisible} selectedMarket={selectedMarket} labelReport={labelReport} labelSelected={labelSelected} handleLabelSelection={handleLabelSelection} reportName={reportName} fetchDataByReportName={fetchDataByReportName} setWeeklyWiseReportData={setWeeklyWiseReportData} setLabelReport={setLabelReport} setLabelSelected={setLabelSelected} setDateList={setDateList} setDateSelected={setDateSelected} setLeftTableHeader={setLeftTableHeader} setLeftTableData={setLeftTableData} setGetApiData={setGetApiData} />
        <EditWeeklyWiseReport editDataSelected={editDataSelected} openModal={openModal} setOpenModal={setOpenModal} selectedMarket={selectedMarket} labelSelected={labelSelected} reportName={reportName} fetchDataByReportName={fetchDataByReportName} setWeeklyWiseReportData={setWeeklyWiseReportData} setLabelReport={setLabelReport} setLabelSelected={setLabelSelected} setDateList={setDateList} setDateSelected={setDateSelected} setLeftTableHeader={setLeftTableHeader} setLeftTableData={setLeftTableData} setGetApiData={setGetApiData} />
      </div>
      <DialogModal {...errorModal} onDismiss={handleDismiss} />
    </LayoutContainer>
  )
}

export default Loader(WeeklyWiseReports)
