import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useTranslation } from 'react-i18next'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import { Dialog } from 'primereact/dialog'
import AddMobileMenu from './AddMobileMenu'
import EditMobileMenu from './EditMobileMenu'

const MobileMenu = ({ setLoading, mockMobileMenus }) => {
  let rowCount = 8
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  const [mobileMenus, setMobileMenus] = useState([])
  const [mobileMenusForReOrder, setMobileMenusForReOrder] = useState([])
  const [mobileFilteredMenus, setFilteredMobileMenus] = useState([])
  const [countryList, setCountryList] = useState([])
  const [suscriptionTypes, setSuscriptionTypes] = useState([])
  const [activeModule, setActiveModule] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showReOrderDailogModal, setShowReOrderDailogModal] = useState(false)
  const [isReorderSaveButtonDisabled, setIsReorderSaveButtonDisabled] = useState(true)
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    init()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockMobileMenus) {
      setMobileMenus(mockMobileMenus)
    }
  }, [])

  useEffect(() => {
    if (window.location.search.includes('edit-menu')) {
      setActiveModule('edit-menu')
    } else if (window.location.search.includes('add-menu')) {
      setActiveModule('add-menu')
    } else {
      setActiveModule('list-menu')
    }
  }, [window.location.search])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const init = async () => {
    setShowDialog({ ...showDialog, showModal: false })
    setLoading(true)
    setShowTableMessage(true)
    const fetchAllCountryList = apiAdapterCoffeeWeb.getAllCountries()
    const fetchAllSubscriptionTypes = apiAdapterCoffeeWeb.getAllSubscriptionTypes()
    const fetchAllMobileMenus = apiAdapterCoffeeWeb.getAllMobileMenus()

    try {
      const [countryList, subscriptionTypes, mobileMenuList] = await Promise.all([fetchAllCountryList, fetchAllSubscriptionTypes, fetchAllMobileMenus])

      const filterdSubscriptionTypes = subscriptionTypes?.data?.returnLst?.filter((ele) => ele?.isActive)

      setCountryList(countryList?.data)
      setSuscriptionTypes(filterdSubscriptionTypes)
      setMobileMenus(mobileMenuList.data?.returnLst)
    } catch {
      setShowDialog({ ...showDialog, modalType: 'error', message: 'SOMETHING_WENT_WRONG', showModal: true, onHide: handleDismiss, onDismiss: handleDismiss, onRetry: init })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const renderToggleSwitchIcon = (item) => (
    <div className="toggle-switch-menu">
      <InputSwitch checked={item?.isActive} onChange={() => handleClickToggleMenu(item)} />
    </div>
  )

  const renderActionButtons = (rowData) => (
    <div className="action-buttons-wrapper">
      <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleClickEditButton(rowData)} data-testId="edit-button" />
      <Button type="button" icon="pi pi-trash" className="config-button" tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }} onClick={() => deleteConfirmationMenu(rowData)} data-testId="config-button" />
    </div>
  )

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  const deleteConfirmationMenu = (rowData) => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YPU_SURE_WANT_TO_DELETE_THIS_MOBILE_MENU'),
      onConfirmation: () => handleClickDeleteButton(rowData),
      onHide: handleDismiss
    })
  }

  const handleClickEditButton = (rowData) => {
    params.set('edit-menu', rowData.id)
    history.push({ pathname: ROUTE_STRINGS.listmenu, search: `tab=${tab}&${params.toString()}` })
  }

  const handleClickAddButton = () => {
    params.set('add-menu', true)
    history.push({ pathname: ROUTE_STRINGS.listmenu, search: `tab=${tab}&${params.toString()}` })
  }

  const handleClickToggleMenu = async (menuItem) => {
    setLoading(true)
    const updatedMenuItem = { ...menuItem, isActive: !menuItem.isActive }

    try {
      const res = await apiAdapterCoffeeWeb.editMobileMenu(updatedMenuItem)

      if (res.data?.isSuccess) {
        setMobileMenus(res.data?.returnLst)
      }
    } catch (error) {
      setShowDialog({ ...showDialog, modalType: 'error', message: 'SOMETHING_WENT_WRONG', showModal: true, onHide: handleDismiss, onDismiss: handleDismiss, onRetry: init })
    } finally {
      setLoading(false)
    }
  }

  const handleClickDeleteButton = async (menuItem) => {
    const { id: menuId } = menuItem

    setLoading(true)

    try {
      const res = await apiAdapterCoffeeWeb.deleteMobileMenu({ menuId })

      if (res.data?.isSuccess) {
        setMobileMenus(res.data?.returnLst)
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: res.data?.message,
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: error?.response?.data?.message,
        onRetry: () => handleClickDeleteButton(menuItem),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
    setShowReOrderDailogModal(false)
  }

  const handleMobileMenuSearch = (e) => {
    const { value } = e.target
    const filteredMenus = mobileMenus.filter((ele) => ele.menuName?.toLowerCase()?.includes(value.toLowerCase()))

    setSearchQuery(value)
    setFilteredMobileMenus(filteredMenus)
  }

  const footerContent = <ButtonComponent type="custom" label="SUBMIT" variant="action" onClick={() => handleClickReorderSaveButton()} disabled={isReorderSaveButtonDisabled} />

  const columns = [
    { field: 'menuName', header: t('MENU_NAME') },
    { field: 'menuPath', header: t('MENU_PATH') }
  ]

  const dynamicColumns = columns.map((col, i) => <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} />)

  const handleClickReOrderMenusButton = () => {
    setShowReOrderDailogModal(true)
    setIsReorderSaveButtonDisabled(true)
    setMobileMenusForReOrder(mobileMenus)
  }

  const onRewReorder = (e) => {
    if (JSON.stringify(mobileMenus) === JSON.stringify(e.value)) {
      setIsReorderSaveButtonDisabled(true)
    } else {
      setIsReorderSaveButtonDisabled(false)
    }
    setMobileMenusForReOrder(e.value)
  }

  const handleClickReorderSaveButton = async () => {
    setLoading(true)

    try {
      const res = await apiAdapterCoffeeWeb.reorderMobileMenus(mobileMenusForReOrder)

      if (res.data?.isSuccess) {
        setMobileMenus(res.data?.returnLst)
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: res.data?.message,
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: error.response.data.message,
        onRetry: handleClickReorderSaveButton,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="list-mobile-menu-component">
      {activeModule === 'list-menu' && (
        <div className="list-menu-wrapper">
          <div className="mobile-menu-header">
            <InputText placeholder="Search Menu" onChange={(e) => handleMobileMenuSearch(e)} />
            <div className="action-buttons">
              <ButtonComponent type="custom" label={t('REORDER_MENUS')} icon="pi pi-sort-amount-up" onClick={() => handleClickReOrderMenusButton()} />
              <ButtonComponent type="add" onClick={handleClickAddButton} />
            </div>
          </div>
          <DataTable emptyMessage={tableResponseMessage} value={searchQuery ? mobileFilteredMenus : mobileMenus} paginator={searchQuery ? mobileFilteredMenus?.length : mobileMenus?.length} rows={rowCount} pageLinkSize={paginationButtons} tableStyle={{ minWidth: '50rem' }}>
            <Column field="menuName" header={t('MENU_NAME')}></Column>
            <Column field="menuPath" header={t('MENU_PATH')}></Column>
            <Column className="isActive-column-cell" body={(e) => renderToggleSwitchIcon(e)} field="category" header={t('IS_ACTIVE')}></Column>
            <Column className="action-buttons-column-cell" body={(e) => renderActionButtons(e)} field="quantity" header={t('ACTIONS')}></Column>
          </DataTable>
        </div>
      )}
      <DialogModal {...showDialog} onDismiss={handleDismiss} />

      {/* Menu Reordering dailog modal */}
      <Dialog visible={showReOrderDailogModal} className="mobile-menus-reorder-dailog-modal" header={t('REORDER_MENUS')} onHide={() => setShowReOrderDailogModal(false)} footer={footerContent}>
        <DataTable value={mobileMenusForReOrder} reorderableColumns reorderableRows onRowReorder={(e) => onRewReorder(e)} tableStyle={{ minWidth: '50rem' }}>
          <Column rowReorder />
          {dynamicColumns}
        </DataTable>
      </Dialog>

      {activeModule === 'add-menu' && <AddMobileMenu countryList={countryList} suscriptionTypes={suscriptionTypes} setMobileMenus={setMobileMenus} />}
      {activeModule === 'edit-menu' && <EditMobileMenu countryList={countryList} suscriptionTypes={suscriptionTypes} mobileMenus={mobileMenus} setMobileMenus={setMobileMenus} />}
    </div>
  )
}

export default Loader(MobileMenu)
