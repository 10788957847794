import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menubar } from 'primereact/menubar'
import { Button } from 'primereact/button'
import { ReactComponent as Logo } from 'Assets/Icons/Logo/Main_Logo.svg'
import { devEnvLogo } from 'Assets/Icons'
import Loader from 'Components/Loader'
import { getLocalUserDetails, getLocalAuthToken, getLocalLoggedInStatus, getLocalReconcileTableColumns, setLocalWithReconcileTableColumns, coffeewebClearLocal } from 'Utils/LocalStorageHandler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { DialogModal } from 'Components/UIComponents'

const Header = ({ setLoading, handleToggleDrawer, mockShowLogoutModal, mockUserLogoutAPIFlag }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const tabId = window.sessionStorage.getItem('tabId')
  const userDetails = getLocalUserDetails()
  const isLoggedInStatus = getLocalLoggedInStatus()

  const [showButtons, setShowButtons] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showErrorLogoutModal, setShowErrorLogoutModal] = useState(false)

  useEffect(() => {
    const accessToken = getLocalAuthToken()

    accessToken && userDetails ? setShowButtons(true) : setShowButtons(false)
  }, [showButtons])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockShowLogoutModal) {
      setShowLogoutModal(true)
      setShowErrorLogoutModal(true)
    } else if (mockUserLogoutAPIFlag) {
      handleLogout()
    }
  }, [])

  const handleLogout = async () => {
    setShowLogoutModal(false)
    setLoading(true)
    try {
      const postData = {
        idUser: userDetails.id,
        deviceId: tabId,
        fbToken: userDetails.fbToken
      }

      await apiAdapterCoffeeWeb.userLogout(postData)
      const availableColumns = getLocalReconcileTableColumns()

      coffeewebClearLocal()
      setLocalWithReconcileTableColumns(availableColumns)
      history.push({
        pathname: ROUTE_STRINGS.login
      })
    } catch {
      setShowLogoutModal(false)
      setShowErrorLogoutModal(true)
    } finally {
      setLoading(false)
    }
  }

  const start = isLoggedInStatus ? (
    <div className="dashboard-header">
      <Button icon="pi pi-align-justify" className="sidebar-menu-button" onClick={() => handleToggleDrawer()} />
    </div>
  ) : (
    <div className="auth-screen-header">
      <div className="auth-header-logo">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />} </div>
    </div>
  )

  const end = isLoggedInStatus ? (
    <div className="dashboard-action-container">
      <div className="action-div">
        <div className="logout-container">
          <Button icon="pi pi-power-off" className="logout-button" tooltip={t('LOGOUT')} tooltipOptions={{ position: 'bottom' }} onClick={() => setShowLogoutModal(true)} />
        </div>
      </div>
    </div>
  ) : null

  return (
    <div className="header-container" id="header" data-testid="header">
      <Menubar start={start} end={end} />
      <DialogModal modalType={'info'} message={t('DO_YOU_WANT_TO_LOGOUT')} showModal={showLogoutModal} onDismiss={() => setShowLogoutModal(false)} onConfirmation={handleLogout} onHide={() => setShowLogoutModal(false)} />
      <DialogModal modalType={'error'} message={t('SORRY_COULD_NOT_LOGOUT_PLEASE_TRY_AGAIN_LATER')} showModal={showErrorLogoutModal} onDismiss={() => setShowErrorLogoutModal(false)} onRetry={commonMethods.handleRetry} onHide={() => setShowErrorLogoutModal(false)} />
    </div>
  )
}

export default Loader(Header)
