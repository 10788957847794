import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import Loader from 'Components/Loader'
import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import { Dropdown } from 'primereact/dropdown'
import { Tooltip } from 'primereact/tooltip'
import { FileUpload } from 'primereact/fileupload'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import LayoutContainer from 'Components/LayoutComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { DialogModal } from 'Components/UIComponents'
import { commonMethods } from 'Utils/commonMethods'
import GlobalDifferentialExcelUpload from './GlobalDifferentialExcelUpload'
import GlobalRawCoffeePriceExcelUpload from './GlobalRawCoffeePriceExcelUpload'

const GlobalPrice = ({ setLoading, mockSelectedCountry, mockGlobalPrice }) => {
  const { t } = useTranslation()

  const fileUploadRef = useRef(null)

  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [xlData, setXlData] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [showRawCoffeePricePreviewDailogModal, setShowRawCoffeePricePreviewDailogModal] = useState(false)
  const [showDifferentialPreviewDailogModal, setShowDifferentialPreviewDailogModal] = useState(false)
  const [showFreightRatePreviewDailogModal, setShowFreightRatePreviewDailogModal] = useState(false)
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    softAction: ''
  })

  useEffect(() => {
    getAllCountryData()

    // Testing Purpose
    if (mockGlobalPrice) {
      setSelectedCountry(mockSelectedCountry)
      setShowDifferentialPreviewDailogModal(true)
    }
  }, [])

  const getAllCountryData = async () => {
    try {
      setLoading(true)
      const { data } = await apiAdapterCoffeeWeb.getAllCountries()
      const sellerCountries = data.filter((ele) => ele.isCoffeeProducingCountry)

      setCountryList(sellerCountries)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.name} src={option.flagsUrl} className={`mr-2 flag flag-${option.iso3.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.name}</div>
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const countryOptionTemplate = (option) => (
    <div className="flex align-items-center">
      <img alt={option.name} src={option.flagsUrl} className={`mr-2 flag flag-${option.iso3.toLowerCase()}`} style={{ width: '18px' }} />
      <div>{option.name}</div>
    </div>
  )

  const handleExcelFileUpload = (e) => {
    const file = e.files[0]

    readExcel(file, setXlData)
  }

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (e) => {
        const bufferArray = e.target.result
        const workbook = XLSX.read(bufferArray, { type: 'buffer' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const data = XLSX.utils.sheet_to_json(worksheet)

        resolve(data)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise.then((data) => {
      setXlData(data)
    })
  }

  const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' }
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' }
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  }

  const handleClickPreviewButton = () => {
    if (!selectedCountry) {
      setErrorMessage(true)

      return
    }

    if (currentTabIndex === 0) {
      setShowRawCoffeePricePreviewDailogModal(true)
    } else if (currentTabIndex === 1) {
      setShowDifferentialPreviewDailogModal(true)
    } else if (currentTabIndex === 2) {
      setShowFreightRatePreviewDailogModal(true)
    }
  }

  const handleFileClearClick = () => {
    setXlData([])
  }

  const configes = [
    {
      tabHeading: t('GLOBAL_COFFEE_PRICE'),
      templateLink: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Excel+Templates/GlobalRawCoffeePriceTemplate.xlsx'
    },
    {
      tabHeading: t('GLOBAL_DIFFERENTIAL'),
      templateLink: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Excel+Templates/GlobalDifferentialTemplate.xlsx'
    }
    // {
    //   tabHeading: t('GLOBAL_FREIGHT_RATES'),
    //   templateLink: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Excel+Templates/GlobalFreightRateTemplate.xltx'
    // }
  ]

  const handleTabChange = ({ index }) => {
    setCurrentTabIndex(index)
    setXlData([])
    setSelectedCountry(null)
    setErrorMessage(false)
  }

  const onHide = () => setModalState({ showModal: false })

  const onDismissFunction = () => {
    resetStates()
    setModalState({ showModal: false })
  }

  const onSuccess = () => {
    resetStates()
    setModalState({ showModal: false })
  }

  const resetStates = () => {
    setXlData([])
    setSelectedCountry(null)
    fileUploadRef.current?.clear()
    setShowRawCoffeePricePreviewDailogModal(false)
    setShowDifferentialPreviewDailogModal(false)
    setShowFreightRatePreviewDailogModal(false)
  }

  return (
    <LayoutContainer title={t('GLOBAL_DATA')} breadCrumbsTitle="globalData">
      <div className="global-price-main-wrapper">
        <TabView activeIndex={currentTabIndex} onTabChange={(e) => handleTabChange(e)}>
          {configes.map((ele, index) => (
            <TabPanel key={index} header={ele.tabHeading}>
              <div className="tab-panel-wrapper">
                <div className="download-link">
                  <a href={ele.templateLink}>{t('CLICK_HERE_TO_DOWNLOAD_THE_EMPTY_TEMPLATE')}</a>
                </div>
                <div className="country-drop-down-wrapper">
                  <label>{t('SELECT_COUNTRY')}</label>
                  <Dropdown size="small" value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} options={countryList} optionLabel="name" placeholder="Select a Country" filter valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="" data-testid="country-dropdown" />
                  {!selectedCountry && errorMessage && <label className="error-massage">{t('PLEASE_SELECT_COUNTRY')}</label>}
                </div>
                <div className="file-upload-card">
                  <label>{t('UPLOAD_FILE_HERE')}</label>
                  <Tooltip target=".custom-upload-btn" content={t('UPLOAD_FILE')} position="top" />
                  <Tooltip target=".custom-cancel-btn" content={'CLEAR'} position="top" />
                  <FileUpload ref={fileUploadRef} accept=".xlsx, .xls" onSelect={(e) => handleExcelFileUpload(e, 'globalRawCoffeePrice')} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} onClear={handleFileClearClick} data-testid="file-upload" />

                  <Tooltip target=".custom-choose-btn" content={t('BROWSE_FILES')} position="top" />
                </div>
                <div className="preview-button-wrapper">
                  <Button className="primary-buttom-style" label={t('PREVIEW')} size="md" onClick={() => handleClickPreviewButton()} />
                </div>
                {showRawCoffeePricePreviewDailogModal && currentTabIndex === 0 && <GlobalRawCoffeePriceExcelUpload xlData={xlData} setXlData={setXlData} countryList={countryList} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} setShowRawCoffeePricePreviewDailogModal={setShowRawCoffeePricePreviewDailogModal} setModalState={setModalState} />}
                {showDifferentialPreviewDailogModal && currentTabIndex === 1 && <GlobalDifferentialExcelUpload xlData={xlData} setXlData={setXlData} countryList={countryList} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} setShowDifferentialPreviewDailogModal={setShowDifferentialPreviewDailogModal} setModalState={setModalState} />}
              </div>
            </TabPanel>
          ))}
        </TabView>
      </div>
      <DialogModal {...modalState} onDismiss={onDismissFunction} onRetry={commonMethods.handleRetry} onHide={onHide} onSuccess={onSuccess} onConfirmation={modalState?.confirmAction} />
    </LayoutContainer>
  )
}

export default Loader(GlobalPrice)
