import './styles.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { Calendar } from 'primereact/calendar'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import coffeeReportJsonLinks from 'Utils/Constants/coffeeReportsJsonLink'
import Loader from 'Components/Loader'

const EditTraderWiseReport = ({ setLoading, openEditModal, setOpenEditModal, activeTab, selectedRowData, editFormType, selectedData, setLotWiseReportData, setLeftTableData, setCotReportData }) => {
  const { t } = useTranslation()
  const { formId } = editFormType
  const { robustaTraderWiseComparison, arabicaTraderWiseComparison } = coffeeReportJsonLinks
  const reportName = activeTab === 0 ? robustaTraderWiseComparison : arabicaTraderWiseComparison

  const [dateSelected, setDateSelected] = useState(null)
  const [detailsDate, setDetailsDate] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [isFormEnabled, setIsFormEnabled] = useState({
    editMode: false,
    submitButton: false
  })
  const [editForm, setEditForm] = useState({
    date: '',
    terminalLevel: '',
    netChange: '',
    totalTraders: ''
  })

  const [inputForm, setInputForm] = useState({
    label: '',
    date: '',
    long: '',
    net: '',
    percentLong: '',
    percentShort: '',
    short: '',
    spreading: '',
    total: ''
  })

  const { date, terminalLevel, netChange, totalTraders } = editForm
  const { long, net, percentLong, percentShort, short, spreading, total, date: terminalDetailsDate } = inputForm

  useEffect(() => {
    if (selectedRowData) {
      const formattedDate = getFormattedDate(selectedRowData)

      if (formId === 1) {
        setDateSelected(formattedDate)
        setEditForm({
          date: selectedRowData.date,
          terminalLevel: selectedRowData.terminalLevel,
          netChange: selectedRowData.netChange,
          totalTraders: selectedRowData.totalTraders
        })
      } else {
        setDetailsDate(formattedDate)
        setInputForm({
          date: selectedRowData.date,
          long: selectedRowData.long,
          short: selectedRowData.short,
          percentLong: selectedRowData.percentLong,
          percentShort: selectedRowData.percentShort,
          net: selectedRowData.net,
          spreading: selectedRowData.spreading,
          total: selectedRowData.total
        })
      }
    }
  }, [selectedRowData, openEditModal])

  const updateLotWiseData = async () => {
    handleDismiss()
    setLoading(true)
    try {
      let data
      let response

      if (formId === 1) {
        data = {
          fileName: reportName,
          date: date,
          terminalLevel: terminalLevel,
          netChange: netChange,
          totalTraders: totalTraders
        }
      } else {
        data = {
          fileName: reportName,
          label: selectedData?.categoryLabel,
          date: terminalDetailsDate || 'NA',
          long: long,
          net: net,
          percentLong: percentLong,
          percentShort: percentShort,
          short: short,
          spreading: spreading || 0,
          total: total
        }
      }

      if (formId === 1) {
        response = await apiAdapterCoffeeWeb.updateTraderWiseTerminalDetailsData(data)
      } else {
        response = await apiAdapterCoffeeWeb.updateTraderWiseDetailsData(data)
      }

      if (response.status === 200) {
        const reportDetails = response?.data?.details
        const filteredData = reportDetails.filter((data) => data.label === selectedData?.categoryLabel)

        const emptyRow = {
          date: null,
          terminalLevel: '-',
          totalTraders: '-',
          netChange: '-'
        }

        const { long, short, total, percentLong, percentShort, net, spreading } = response.data.details[0]

        const rowData = {
          date: null,
          long: long,
          short: short,
          total: total,
          percentLong: percentLong,
          percentShort: percentShort,
          net: net,
          spreading: spreading
        }

        setCotReportData(reportDetails)

        const leftTableDataWithEmptyRow = [{ ...emptyRow }, ...response.data.terminalDetails]

        setLeftTableData(leftTableDataWithEmptyRow)

        const rightTableDataWithEmptyRow = [{ ...rowData }, ...filteredData[0].data]

        setLotWiseReportData(rightTableDataWithEmptyRow)

        setOpenEditModal(false)

        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_UPDATED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })

        setButtonDisabled(true)
        setIsFormEnabled({
          editMode: false,
          submitButton: false
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_UPDATE_REPORT_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: updateLotWiseData,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const getFormattedDate = (rowData) => {
    const dateValue = rowData.date

    return dateValue ? moment(dateValue, 'DD-MMM-YYYY').toDate() : null
  }

  const handleDateChange = (event) => {
    const chosenDate = event.value
    const formattedDate = chosenDate ? moment(chosenDate).format('DD-MMM-YYYY') : ''

    setButtonDisabled(false)

    if (formId === 1) {
      setDateSelected(chosenDate)

      setEditForm((prevState) => ({
        ...prevState,
        date: formattedDate
      }))
    } else {
      setDetailsDate(chosenDate)
      setInputForm((previousData) => ({
        ...previousData,
        date: formattedDate
      }))
    }
  }

  const handleInputChange = (fieldName, value) => {
    setButtonDisabled(false)

    if (formId === 1) {
      setEditForm((previousState) => ({
        ...previousState,
        [fieldName]: value
      }))
    } else {
      setInputForm((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    }
  }

  const confirmResetForm = () => {
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })
    setShowDialog({ ...showDialog, showModal: false })

    const formattedDate = getFormattedDate(selectedRowData)

    if (formId === 1) {
      setDateSelected(formattedDate)
      setEditForm({
        date: selectedRowData.date,
        terminalLevel: selectedRowData.terminalLevel,
        netChange: selectedRowData.netChange,
        totalTraders: selectedRowData.totalTraders
      })
    } else {
      setDetailsDate(formattedDate)
      setInputForm({
        date: selectedRowData.date,
        long: selectedRowData.long,
        short: selectedRowData.short,
        percentLong: selectedRowData.percentLong,
        percentShort: selectedRowData.percentShort,
        net: selectedRowData.net,
        spreading: selectedRowData.spreading,
        total: selectedRowData.total
      })
    }
  }

  const closeEditModal = () => {
    setOpenEditModal(false)
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })
  }

  const isDisable = () => {
    const fieldsToCheck = [date, terminalLevel, netChange, totalTraders]
    const fieldValidationCheck = [long, net, percentLong, percentShort, short, spreading, total]
    const fieldsToValidate = formId === 1 ? fieldsToCheck : fieldValidationCheck

    return fieldsToValidate.some((field) => field === '' || field === null)
  }

  const isFieldInvalid = (fieldName) => {
    const isFieldEmptyOrNull = formId === 1 ? editForm[fieldName] === '' || editForm[fieldName] === null : inputForm[fieldName] === '' || inputForm[fieldName] === null

    return isFieldEmptyOrNull
  }

  const getInputClassName = (fieldName) => {
    const className = isFieldInvalid(fieldName) ? 'error-fields' : ''

    return className
  }

  const enableFormEditing = () => {
    setIsFormEnabled({
      editMode: true,
      submitButton: true
    })
  }

  const showResetConfirmationDialog = () => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onConfirmation: confirmResetForm,
      onHide: handleDismiss
    })
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const footerButton = () => (
    <div className="trader-wise-report-footer">
      {isFormEnabled.submitButton && (
        <>
          <ButtonComponent type={'cancel'} onClick={closeEditModal} />
          <ButtonComponent type={'submit'} onClick={updateLotWiseData} disabled={buttonDisabled || isDisable()} />
        </>
      )}
    </div>
  )

  return (
    <>
      <Dialog visible={openEditModal} header={`${t('EDIT_TRADER_WISE_REPORT_TITLE')} - ${activeTab === 0 ? t('ROBUSTA_TITLE') : t('ARABICA_TITLE')}`} footer={footerButton} onHide={closeEditModal} className="trader-wise-report-edit-modal">
        <div className="edit-form-container">
          <div className="action-button">{isFormEnabled.editMode ? <ButtonComponent type={'reset'} onClick={showResetConfirmationDialog} /> : <ButtonComponent type={'edit'} onClick={enableFormEditing} />}</div>
          {formId === 1 && (
            <div className="form-wrapper">
              <div className="form-fields">
                <span className="p-float-label">
                  <Calendar showIcon={true} id="date" name="date" value={dateSelected} onChange={handleDateChange} maxDate={new Date()} className={`calendar-input ${getInputClassName('date')}`} disabled={true} data-testid="date" />
                  <label htmlFor="date" className={`field-label ${getInputClassName('date')}`}>
                    {t('DATE')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('date') && <small className="error-message">{t('DATE_IS_REQUIRED')}</small>}</div>
              </div>

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="terminal-level"
                    value={terminalLevel}
                    name="terminalLevel"
                    onChange={(event) => {
                      handleInputChange('terminalLevel', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('terminalLevel')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="terminal-level"
                  />
                  <label htmlFor="terminal-level" className={`field-label ${getInputClassName('terminalLevel')}`}>
                    {t('TERMINAL_LEVEL')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('terminalLevel') && <small className="error-message">{t('TERMINAL_LEVEL_ERROR_MESSAGE')}</small>}</div>
              </div>

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="total-traders"
                    name="totalTraders"
                    value={totalTraders}
                    onChange={(event) => {
                      handleInputChange('totalTraders', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('totalTraders')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="total-traders"
                  />
                  <label htmlFor="total-traders" className={`field-label ${getInputClassName('totalTraders')}`}>
                    {t('TOTAL_TRADERS')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('totalTraders') && <small className="error-message">{t('TOTAL_TRADERS_ERROR_MESSAGE')}</small>}</div>
              </div>

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="net-change"
                    name="netChange"
                    value={netChange}
                    onChange={(event) => {
                      handleInputChange('netChange', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('netChange')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="net-change"
                  />
                  <label htmlFor="net-change" className={`field-label ${getInputClassName('netChange')}`}>
                    {t('NET_CHANGE')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('netChange') && <small className="error-message">{t('NET_CHANGE_ERROR_MESSAGE')}</small>}</div>
              </div>
            </div>
          )}

          {formId === 2 && (
            <div className="form-wrapper">
              {selectedRowData?.date !== null && (
                <div className="form-fields">
                  <span className="p-float-label">
                    <Calendar showIcon={true} id="date" name="date" value={detailsDate} onChange={handleDateChange} maxDate={new Date()} className={`calendar-input ${getInputClassName('date')}`} disabled={true} data-testid="date" />
                    <label htmlFor="date" className={`field-label ${getInputClassName('date')}`}>
                      {t('DATE')}
                      <span className="required-text-field">*</span>
                    </label>
                  </span>
                  <div className="error-message"> {isFieldInvalid('date') && <small className="error-message">{t('DATE_IS_REQUIRED')}</small>}</div>
                </div>
              )}

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="long"
                    value={long}
                    name="long"
                    onChange={(event) => {
                      handleInputChange('long', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('long')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="long"
                  />
                  <label htmlFor="long" className={`field-label ${getInputClassName('long')}`}>
                    {t('LONG')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('long') && <small className="error-message">{t('TERMINAL_LEVEL_ERROR_MESSAGE')}</small>}</div>
              </div>

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="short"
                    name="short"
                    value={short}
                    onChange={(event) => {
                      handleInputChange('short', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('short')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="short"
                  />
                  <label htmlFor="short" className={`field-label ${getInputClassName('short')}`}>
                    {t('SHORT')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('short') && <small className="error-message">{t('SHORT_ERROR_MESSAGE')}</small>}</div>
              </div>

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="total"
                    name="total"
                    value={total}
                    onChange={(event) => {
                      handleInputChange('total', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('total')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="total"
                  />
                  <label htmlFor="percentage-short" className={`field-label ${getInputClassName('total')}`}>
                    {t('TOTAL')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('total') && <small className="error-message">{t('TOTAL_ERROR_MESSAGE')}</small>}</div>
              </div>

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="percentage-long"
                    name="percentLong"
                    value={percentLong}
                    onChange={(event) => {
                      handleInputChange('percentLong', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('percentLong')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="percentage-long"
                  />
                  <label htmlFor="percentage-long" className={`field-label ${getInputClassName('percentLong')}`}>
                    {t('PERCENTAGE_LONG')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('percentLong') && <small className="error-message">{t('PERCENTAGE_LONG_ERROR_MESSAGE')}</small>}</div>
              </div>

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="percentage-short"
                    name="percentShort"
                    value={percentShort}
                    onChange={(event) => {
                      handleInputChange('percentShort', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('percentShort')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="percentage-short"
                  />
                  <label htmlFor="percentage-short" className={`field-label ${getInputClassName('percentShort')}`}>
                    {t('PERCENTAGE_SHORT')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('percentShort') && <small className="error-message">{t('PERCENTAGE_SHORT_ERROR_MESSAGE')}</small>}</div>
              </div>

              <div className="form-fields">
                <span className="p-float-label">
                  <InputText
                    id="net"
                    name="net"
                    value={net}
                    onChange={(event) => {
                      handleInputChange('net', event.target.value)
                    }}
                    className={`text-field ${getInputClassName('net')}`}
                    disabled={!isFormEnabled.editMode}
                    data-testid="net"
                  />
                  <label htmlFor="percentage-short" className={`field-label ${getInputClassName('net')}`}>
                    {t('NET')}
                    <span className="required-text-field">*</span>
                  </label>
                </span>
                <div className="error-message"> {isFieldInvalid('net') && <small className="error-message">{t('NET_ERROR_MESSAGE')}</small>}</div>
              </div>

              {(selectedData?.categoryLabel === 'FUND BUYERS' || selectedData?.categoryLabel === 'SWAP DEALERS' || selectedData?.categoryLabel === 'OTHER REPORTABLE') && (
                <div className="form-fields">
                  <span className="p-float-label">
                    <InputText
                      id="spreading"
                      name="spreading"
                      value={spreading}
                      onChange={(event) => {
                        handleInputChange('spreading', event.target.value)
                      }}
                      className={`text-field ${getInputClassName('spreading')}`}
                      disabled={!isFormEnabled.editMode}
                      data-testid="spreading"
                    />
                    <label htmlFor="percentage-short" className={`field-label ${getInputClassName('spreading')}`}>
                      {t('SPREADING')}
                      <span className="required-text-field">*</span>
                    </label>
                  </span>
                  <div className="error-message"> {isFieldInvalid('spreading') && <small className="error-message">{t('SPREADING_ERROR_MESSAGE')}</small>}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </Dialog>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </>
  )
}

export default Loader(EditTraderWiseReport)
