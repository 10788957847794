import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumbs from 'Components/LayoutComponent/Components/BreadCrumbs'
import Footer from 'Components/LayoutComponent/Components/Footer'
import ButtonComponent from '../UIComponents/ButtonComponent'

const LayoutContainer = (props) => {
  const { t } = useTranslation()
  const { title, children, handleEditButtonClick, showEditButton, breadCrumbsTitle, showAddButton, handleAddButtonClick, showRoleFeatureButton, handleRoleFeatureButtonClick, showChangePasswordButton, setIsDialogOpen, showSettingsButton, setVisible, showPaymentDownloadButton, setDisplayButton, showDeleteButton, handleDeleteButtonClick, handleModalOpen } = props
  const showButtonContainer = showEditButton || showAddButton || showRoleFeatureButton || showChangePasswordButton || showSettingsButton || showPaymentDownloadButton || showDeleteButton

  const getClassName = () => {
    if (showButtonContainer) {
      return 'body-heading'
    }

    return 'body-heading-full'
  }

  return (
    <div className="body-container">
      {breadCrumbsTitle && <Breadcrumbs crumbsKey={breadCrumbsTitle} />}
      <div className="heading-action-wrapper">
        <div className={getClassName()} data-testid="heading-title">
          {t(title)}
        </div>
        {showButtonContainer && (
          <div className="heading-button-container">
            {showDeleteButton && <ButtonComponent type="Custom" variant="action" label="Delete" icon="pi pi-trash" onClick={handleDeleteButtonClick} />}
            {showEditButton && <ButtonComponent type={'edit'} onClick={handleEditButtonClick} />}
            {showAddButton && <ButtonComponent type={'add'} onClick={handleAddButtonClick} />}
            {showRoleFeatureButton && <ButtonComponent type={'custom'} label={'ROLE_FEATURES'} icon="pi pi-wrench" variant={'action'} onClick={() => handleRoleFeatureButtonClick()} />}
            {showChangePasswordButton && <ButtonComponent type={'custom'} variant={'action'} label={t('CHANGE_PASSWORD')} onClick={() => setIsDialogOpen(true)} />}
            {showSettingsButton && <ButtonComponent type="Custom" variant="action" label="Settings" icon="pi pi-cog" onClick={() => setVisible(true)} />}
            {showPaymentDownloadButton && <ButtonComponent type="Custom" icon="pi pi-plus" label={t('ADD_MISSING_REPORT')} variant="action" onClick={handleModalOpen} />}
            {showPaymentDownloadButton && <ButtonComponent type="Custom" variant="action" label="Download Report" icon="pi pi-arrow-down" onClick={setDisplayButton} />}
          </div>
        )}
      </div>
      <div className="body-content" data-testid="body-content">
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default LayoutContainer
