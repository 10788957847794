import './styles.scss'
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { Form, Field } from 'react-final-form'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import Loader from 'Components/Loader'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Validation from 'Utils/Validation'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { getLocalUserDetails, getLocalAuthToken, setLocalWithLoggedInStatus, setLocalWithAuthToken, setLocalWithUserDetails } from 'Utils/LocalStorageHandler'

const LoginWithOTP = ({ setLoading, generatedToken, setModalState, resetError, mockShowOtpFieldForEmail, mockShowEmailError, mockSeconds }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { showBoundary } = useErrorBoundary()
  const toast = useRef(null)

  const userDetails = getLocalUserDetails()
  const accessToken = getLocalAuthToken()
  const tabId = sessionStorage.tabId ? sessionStorage.tabId : (sessionStorage.tabId = Math.random())

  window.sessionStorage.setItem('tabId', tabId)

  const [seconds, setSeconds] = useState(60)
  const [firebaseToken, setFirebaseToken] = useState('')
  const [errorState, setErrorState] = useState(false)
  const [showOtpFieldForEmail, setShowOtpFieldForEmail] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [showChangeEmailAddress, setShowChangeEmailAddress] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const [receivedOtpData, setReceivedOtpData] = useState({
    sessionId: '',
    otp: ''
  })
  const [userInputValues, setUserInputValues] = useState({
    email: '',
    password: '',
    phoneNo: ''
  })

  const [validationState, setValidationState] = useState({
    email: false,
    otp: false
  })

  useEffect(() => {
    setValidationState((previousState) => ({ ...previousState, email: false, otp: false }))
  }, [resetError])

  if (userDetails && accessToken) {
    history.push(ROUTE_STRINGS.coffeequotes)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      } else {
        clearInterval(timer)
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [seconds])

  useEffect(() => {
    const fbToken = uuid()

    setFirebaseToken(fbToken)
  }, [])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockShowOtpFieldForEmail) {
      setShowOtpFieldForEmail(true)
      setShowChangeEmailAddress(true)
      setShowEmailError(mockShowEmailError)
      setErrorState(true)
      setSeconds(mockSeconds)
      setShowSuccessMessage(true)
    }
  }, [])

  const validateEmailId = async () => {
    const { email } = userInputValues

    if (email) {
      setLoading(true)
      try {
        const { data } = await apiAdapterCoffeeWeb.isEmailExist({ email }, generatedToken)

        if (data) {
          sendOtpOnMail(email)
          setShowChangeEmailAddress(true)
          setSeconds(60)
        } else {
          setShowEmailError((previousState) => !previousState)
        }
      } catch (error) {
        showBoundary(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const sendOtpOnMail = async (email) => {
    const postData = {
      emailId: email
    }

    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.sendOTPEmail(email, postData)
      const {
        status: responseStatus,
        data: { status, details }
      } = response

      if (responseStatus === 200) {
        setShowSuccessMessage(true)
        setShowOtpFieldForEmail(true)

        if (status === 'Success') {
          setLoading(true)
          setReceivedOtpData((previousValue) => ({
            ...previousValue,
            sessionId: details
          }))
        } else if (status === 'Error') {
          setModalState({
            showModal: true,
            modalType: 'error',
            message: details
          })
        }
      } else {
        setModalState({
          showModal: true,
          modalType: 'error',
          message: details
        })
      }
    } catch {
      setModalState({
        showModal: true,
        modalType: 'error',
        message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER')
      })
    } finally {
      setLoading(false)
    }
  }

  const authenticateEmailOtp = async () => {
    const { otp } = receivedOtpData
    const { email } = userInputValues

    if (otp) {
      const postData = {
        emailId: email,
        otp: otp,
        includeMenu: true,
        isMobile: false,
        deviceId: tabId,
        fbToken: firebaseToken,
        isAndroiodDevice: false,
        byPassOldDevice: true
      }

      setLoading(true)
      try {
        const response = await apiAdapterCoffeeWeb.authenticateByEmailOtp(postData)
        const { data } = response

        if (data.error === 'OTP Mismatch' || data.error === 'OTP Expired.') {
          setShowSuccessMessage(false)
          if (data.error === 'OTP Expired.') {
            setReceivedOtpData((prevData) => ({ ...prevData, otp: '' }))
          }
          setModalState({
            showModal: true,
            modalType: 'error',
            message: data.error
          })
        } else {
          setLoading(true)
          setLocalWithUserDetails(data)
          setLocalWithAuthToken(generatedToken)
          setLocalWithLoggedInStatus(true)
          history.push(ROUTE_STRINGS.coffeequotes)
        }
      } catch {
        setModalState({
          showModal: true,
          modalType: 'error',
          message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER')
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const handleChange = (e) => {
    setShowEmailError(false)
    const { name, value } = e.target

    if (name === 'email') {
      setValidationState({ ...validationState, email: false })
      setUserInputValues({
        ...userInputValues,
        email: value.toString().toLowerCase()
      })

      if (value.trim() && !Validation.checkEmailValidity(value)) {
        setErrorState(true)
      } else {
        setErrorState(false)
      }
    }
    if (name === 'otp') {
      setValidationState({ ...validationState, otp: false })
      setReceivedOtpData({
        ...receivedOtpData,
        [name]: value
      })
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if ((name === 'email' || name === 'otp') && (validationState[name] === '' || value === '')) {
      setValidationState((prevValidationState) => ({ ...prevValidationState, [name]: true }))
    }
  }

  const handleResendClick = () => {
    validateEmailId(userInputValues.email)
    setSeconds(60)
  }

  const handleChangeEmailAddress = () => {
    setValidationState(false)
    setShowChangeEmailAddress(false)
    setShowOtpFieldForEmail(false)
    setReceivedOtpData(false)
  }

  const onSubmit = (data) => {
    setUserInputValues(data)
  }

  const isEmail = !userInputValues?.email || errorState
  const isSubmitOtpDisabled = !receivedOtpData?.otp

  return (
    <>
      <div className="login-with-otp-form" data-testid="login-with-otp-form">
        <Form
          onSubmit={onSubmit}
          initialValues={userInputValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <div>
                <Field name="email">
                  {({ input, meta }) => (
                    <div className="field email-label">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <i className="pi pi-envelope" />
                        </span>
                        <span className="p-float-label">
                          <InputText {...input} placeholder={t('EMAIL')} id="email" value={userInputValues.email} onChange={handleChange} onBlur={handleBlur} disabled={showOtpFieldForEmail} className={validationState.email ? 'p-invalid' : ''} />
                          <label htmlFor="email" className={validationState.email ? 'p-error' : 'label-text'}>
                            {t('EMAIL')} <span className="required-star"> *</span>
                          </label>
                        </span>
                      </div>
                      {showChangeEmailAddress && (
                        <div className="change-email-address">
                          <div onClick={handleChangeEmailAddress} className="change-email-text">
                            {t('CHANGE_EMAIL')}
                          </div>
                        </div>
                      )}
                      <div className="error-message">
                        {showEmailError ? (
                          <p className="error-message email-error">{t('EMAIL_IS_NOT_REGISTERED')}</p>
                        ) : (
                          (validationState.email && (
                            <div>
                              <small className="p-error">{t('EMAIL_IS_REQUIRED')}</small>{' '}
                            </div>
                          )) ||
                          (errorState && <p className="error-message email-error">{t('INVALID_EMAIL_FORMAT')}</p>)
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                {showOtpFieldForEmail && (
                  <div>
                    <Field name="otp">
                      {({ input, meta }) => (
                        <div className="field">
                          <div className="p-inputgroup flex-1">
                            <span className="p-float-label">
                              <InputText keyfilter="int" {...input} placeholder={t('ENTER_OTP')} name="otp" value={receivedOtpData?.otp} onChange={handleChange} onBlur={handleBlur} maxLength={6} className={validationState.otp ? 'p-invalid' : ''} />
                              <label htmlFor="otp" className={validationState.otp ? 'p-error-message' : 'label-text-otp'}>
                                {t('ENTER_OTP')} <span className="required-star"> *</span>
                              </label>
                            </span>
                          </div>

                          {validationState.otp && (
                            <div>
                              <small className="p-error">{t('OTP_IS_REQUIRED')}</small>{' '}
                            </div>
                          )}
                        </div>
                      )}
                    </Field>

                    <div className="otp-success-div">
                      <div>{showSuccessMessage && <p className="otp-success-text">{t('OTP_SENT_SUCCESSFULLY')}</p>}</div>
                      {seconds === 0 ? (
                        <p className="otp-resend-text" onClick={handleResendClick}>
                          {t('RESEND_OTP')}
                        </p>
                      ) : (
                        <p className="otp-timer-text">
                          {'00'} : {seconds < 10 ? `0${seconds}` : seconds}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {!showOtpFieldForEmail ? <Button label={t('REQUEST_FOR_OTP')} className="login-with-otp-button" disabled={isEmail} onClick={() => validateEmailId(userInputValues.email)} /> : <Button label={t('SUBMIT_OTP')} className="login-with-otp-button" onClick={() => authenticateEmailOtp()} disabled={isSubmitOtpDisabled} />}
              </div>
            </form>
          )}
        />
      </div>
    </>
  )
}

export default Loader(LoginWithOTP)
