import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import { Column } from 'primereact/column'
import Loader from 'Components/Loader'
import { DialogModal } from 'Components/UIComponents'
import LayoutContainer from 'Components/LayoutComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import coffeeReportJsonLinks from 'Utils/Constants/coffeeReportsJsonLink'
import { TABLE_CONST } from 'Utils/Constants'
import AddLotWiseReport from './AddLotWiseReport'
import EditLotWiseReport from './EditLotWiseReport'

const LotWiseReport = ({ setLoading, mockLotWiseReport, mockSelectedData, mockLotWiseReportData, mockLeftTableData }) => {
  const { t } = useTranslation()
  let rowCount = 3
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const { robustaLotWiseComparison, arabicaLotWiseComparison } = coffeeReportJsonLinks

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [activeTab, setActiveTab] = useState(0)
  const [page, setPage] = useState(0)
  const [editFormType, setEditFormType] = useState({
    formId: 1,
    formType: 'details'
  })

  const [showDialog, setShowDialog] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [cotReportData, setCotReportData] = useState([])
  const [leftTableData, setLeftTableData] = useState([])
  const [lotWiseReportData, setLotWiseReportData] = useState([])
  const [labelCategories, setLabelCategories] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [selectedRowData, setSelectedRowData] = useState({})
  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })

  useEffect(() => {
    fetchData(robustaLotWiseComparison)

    // Testing Purpose
    if (mockLotWiseReport) {
      setLeftTableData(mockLeftTableData)
      setLotWiseReportData(mockLotWiseReportData)
      setSelectedData(mockSelectedData)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const fetchData = async (reportName) => {
    setLoading(true)
    handleDismiss()

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })
      const reportDetails = response?.data?.details
      const categories = reportDetails.map((ele) => ({ categoryLabel: ele.label }))

      const emptyRow = {
        date: null,
        terminalLevel: '-',
        openInterest: '-',
        netChange: '-'
      }

      const { long, short, total, percentLong, percentShort, net, spreading } = response.data.details[0]

      const rowData = {
        date: null,
        long: long,
        short: short,
        total: total,
        percentLong: percentLong,
        percentShort: percentShort,
        net: net,
        spreading: spreading
      }

      setCotReportData(reportDetails)
      setSelectedData(categories[0])
      setLabelCategories(categories)

      const leftTableDataWithEmptyRow = [{ ...emptyRow }, ...response.data.terminalDetails]

      setLeftTableData(leftTableDataWithEmptyRow)

      const rightTableDataWithEmptyRow = [{ ...rowData }, ...response.data.details[0].data]

      setLotWiseReportData(rightTableDataWithEmptyRow)
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => fetchData(reportName),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const deleteReportData = async (rowData) => {
    setLoading(true)
    setErrorModal({ ...errorModal, showModal: false })
    const date = rowData.date
    const reportName = activeTab === 0 ? robustaLotWiseComparison : arabicaLotWiseComparison

    try {
      const response = await apiAdapterCoffeeWeb.deleteLotWiseReport({ fileName: reportName, date: date })

      if (response.status === 200) {
        setErrorModal({
          ...errorModal,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_DELETED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
        const reportDetails = response?.data?.details
        const filteredData = reportDetails.filter((data) => data.label === selectedData?.categoryLabel)

        const emptyRow = {
          date: null,
          terminalLevel: '-',
          openInterest: '-',
          netChange: '-'
        }

        const { long, short, total, percentLong, percentShort, net, spreading } = response.data.details[0]

        const rowData = {
          date: null,
          long: long,
          short: short,
          total: total,
          percentLong: percentLong,
          percentShort: percentShort,
          net: net,
          spreading: spreading
        }

        setCotReportData(reportDetails)

        const leftTableDataWithEmptyRow = [{ ...emptyRow }, ...response.data.terminalDetails]

        setLeftTableData(leftTableDataWithEmptyRow)

        const rightTableDataWithEmptyRow = [{ ...rowData }, ...filteredData[0].data]

        setLotWiseReportData(rightTableDataWithEmptyRow)
      }
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_DELETE_REPORT_DATA'),
        onRetry: () => deleteReportData(rowData),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value

    setSelectedData(selectedValue)

    const reportDataByCategory = cotReportData?.find(({ label }) => label === selectedValue?.categoryLabel)

    if (reportDataByCategory) {
      const rowData = {
        date: null,
        long: reportDataByCategory.long,
        short: reportDataByCategory.short,
        total: reportDataByCategory.total,
        percentLong: reportDataByCategory.percentLong,
        percentShort: reportDataByCategory.percentShort,
        net: reportDataByCategory.net,
        spreading: reportDataByCategory.spreading
      }

      const rightTableDataWithEmptyRow = [{ ...rowData }, ...reportDataByCategory.data]

      setLotWiseReportData(rightTableDataWithEmptyRow)
    }
  }

  const handlePageChange = (event) => {
    setPage(event.first)
  }

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData)
    setOpenEditModal(true)
    setEditFormType({
      formId: 1,
      formType: 'details'
    })
  }
  const handleEditRowSelection = (rowData) => {
    setSelectedRowData(rowData)
    setOpenEditModal(true)
    setEditFormType({
      formId: 2,
      formType: 'terminalDetails'
    })
  }

  const deleteConfirmation = (rowData) => {
    setErrorModal({
      ...errorModal,
      showModal: true,
      modalType: 'info',
      message: t('DELETE_USDA_COFFEE_REPORT'),
      onConfirmation: () => deleteReportData(rowData),
      onHide: handleDismiss
    })
  }

  const actionTemplate = (rowData, { rowIndex }) => {
    if (rowIndex === 0) {
      return null
    }

    return (
      <div className="action-button-wrapper">
        <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditClick(rowData)} data-testid="edit-button" />
        <Button type="button" icon="pi pi-trash" className="deleted-button" tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }} onClick={() => deleteConfirmation(rowData)} data-testid="deleted-button" />
      </div>
    )
  }

  const editActionTemplate = (rowData, { rowIndex }) => {
    if (rowIndex === 0) {
      return (
        <div className="action-button-wrapper">
          <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditRowSelection(rowData)} />
        </div>
      )
    }

    return (
      <div className="action-button-wrapper">
        <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditRowSelection(rowData)} data-testid="edit-button" />
        <Button type="button" icon="pi pi-trash" className="deleted-button" tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }} onClick={() => deleteConfirmation(rowData)} data-testid="deleted-button" />
      </div>
    )
  }

  const handleDismiss = () => {
    setErrorModal({ ...errorModal, showModal: false })
  }

  const handleAddButtonClick = () => {
    setShowDialog(true)
  }

  const handleTabChange = (index) => {
    const reportName = index === 0 ? robustaLotWiseComparison : arabicaLotWiseComparison

    setLeftTableData([])
    setLotWiseReportData([])
    setActiveTab(index)
    fetchData(reportName)
    setPage(0)
  }

  const leftTableColumn = [
    {
      dataField: 'date',
      text: t('DATE'),
      className: 'date-column'
    },
    {
      dataField: 'terminalLevel',
      text: t('TERMINAL_LEVEL'),
      className: 'terminal-level-column'
    },
    {
      dataField: 'openInterest',
      text: t('OPEN_INTEREST'),
      className: 'open-interest-column'
    },
    {
      dataField: 'netChange',
      text: t('NET_CHANGE'),
      className: 'net-change-column'
    },
    {
      text: t('ACTIONS'),
      body: actionTemplate,
      className: 'action-column'
    }
  ]

  const rightTableColumn = [
    {
      dataField: 'date',
      text: t('DATE'),
      className: 'date-column'
    },
    {
      dataField: 'long',
      text: t('LONG'),
      className: 'long-column'
    },
    {
      dataField: 'short',
      text: t('SHORT'),
      className: 'short-column'
    },
    {
      dataField: 'total',
      text: t('TOTAL'),
      className: 'total-column'
    },
    {
      dataField: 'percentLong',
      text: t('PERCENTAGE_LONG'),
      className: 'percent-long-column'
    },
    {
      dataField: 'percentShort',
      text: t('PERCENTAGE_SHORT'),
      className: 'percent-short-column'
    },
    {
      dataField: 'net',
      text: t('NET'),
      className: 'net-column'
    }
  ]

  const renderTableData = () => (
    <div className="lot-wise-report-container">
      <div className="table-container">
        <div className="left-table">
          <DataTable value={leftTableData} paginator={leftTableData?.length} rows={rowCount} pageLinkSize={paginationButtons} first={page} onPage={handlePageChange}>
            {leftTableColumn.map((item, index) => (
              <Column key={index} field={item.dataField} header={item.text} className={item.className} body={item.body} />
            ))}
          </DataTable>
        </div>
        <div className="right-table">
          <div className="category-dropdown">
            <Dropdown value={selectedData} options={labelCategories} optionLabel="categoryLabel" onChange={handleSelectionChange} placeholder={t('SELECT_REPORT')} className="category-dropdown-select" data-testid="category-dropdown" />
          </div>
          <DataTable value={lotWiseReportData} paginator={lotWiseReportData?.length} rows={rowCount} pageLinkSize={paginationButtons} first={page} onPage={handlePageChange}>
            {rightTableColumn.map((item, index) => (
              <Column key={index} field={item.dataField} header={item.text} className={item.className} />
            ))}

            {(selectedData?.categoryLabel === 'FUND BUYERS' || selectedData?.categoryLabel === 'SWAP DEALERS' || selectedData?.categoryLabel === 'OTHER REPORTABLE') && <Column key="spreading" field="spreading" header={t('SPREADING')} className="spreading-column" />}
            {(selectedData?.categoryLabel === 'FUND BUYERS' || selectedData?.categoryLabel === 'SWAP DEALERS' || selectedData?.categoryLabel === 'OTHER REPORTABLE') && <Column key="compareToPreviousDay" field="compareToPreviousDay" header={t('COMPARE_TO_PRE_DAY')} className="spreading-column" />}
            <Column key="action" header={t('ACTIONS')} body={editActionTemplate} className="action-column" />
          </DataTable>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className="coffee-reports-container">
        <LayoutContainer title={`${t('LOT_REPORTS')} - ${activeTab === 0 ? t('ROBUSTA_TITLE') : t('ARABICA_TITLE')}`} breadCrumbsTitle={'cotReport'} showAddButton={true} handleAddButtonClick={handleAddButtonClick}>
          <TabView activeIndex={activeTab} onTabChange={(e) => handleTabChange(e.index)}>
            <TabPanel header={t('ROBUSTA_TITLE')}>{renderTableData()}</TabPanel>

            <TabPanel header={t('ARABICA_TITLE')}>{renderTableData()}</TabPanel>
          </TabView>
        </LayoutContainer>
      </div>

      <AddLotWiseReport showDialog={showDialog} setShowDialog={setShowDialog} labelCategories={labelCategories} activeTab={activeTab} setCotReportData={setCotReportData} setSelectedData={setSelectedData} setLabelCategories={setLabelCategories} setLeftTableData={setLeftTableData} setLotWiseReportData={setLotWiseReportData} />
      <EditLotWiseReport openEditModal={openEditModal} setOpenEditModal={setOpenEditModal} activeTab={activeTab} selectedRowData={selectedRowData} editFormType={editFormType} selectedData={selectedData} setLotWiseReportData={setLotWiseReportData} setLeftTableData={setLeftTableData} setCotReportData={setCotReportData} />

      <DialogModal {...errorModal} onDismiss={handleDismiss} />
    </>
  )
}

export default Loader(LotWiseReport)
