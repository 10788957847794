import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { InputText } from 'primereact/inputtext'
import { Image } from 'primereact/image'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { MultiSelect } from 'primereact/multiselect'
import { useTranslation } from 'react-i18next'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { FileUpload } from 'primereact/fileupload'
import Loader from 'Components/Loader'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { ROUTE_STRINGS } from 'Utils/Constants'

const userTypesConfig = [
  {
    id: 1,
    userType: 'For Logged-in Users'
  },
  {
    id: 2,
    userType: 'For Guest Users'
  },
  {
    id: 3,
    userType: 'For Both Users'
  }
]

const EditMobileMenu = ({ setLoading, countryList, suscriptionTypes, mobileMenus, setMobileMenus, mockMenuUrl }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const toast = useRef(null)
  const searchParams = new URLSearchParams(location.search)
  const menuId = searchParams.get('edit-menu')

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  const [menuDetails, setMenuDetails] = useState({})
  const [selectedUserTypeId, setSelectedUserTypeId] = useState(0)
  const [selectedCountries, setSelectedCountries] = useState([])
  const [isCountryBased, setIsCountryBased] = useState(null)
  const [errorMessage, setErrorMessage] = useState({
    menuName: false,
    menuPath: false,
    menuUrl: false,
    isActiveForLoggedInUser: false,
    isActiveForGuestUser: false,
    subscriptionIds: false,
    countryIds: false
  })

  useEffect(() => {
    if (mobileMenus?.length) {
      const selectedMenu = mobileMenus.find((ele) => ele.id === Number(menuId))
      const filteredCountried = countryList?.filter((ele) => selectedMenu?.countryIds?.includes(ele.id))

      if (selectedMenu.isActiveForLoggedInUser && selectedMenu.isActiveForGuestUser) {
        setSelectedUserTypeId(3)
      } else if (selectedMenu.isActiveForLoggedInUser) {
        setSelectedUserTypeId(1)
      } else {
        setSelectedUserTypeId(2)
      }

      setMenuDetails(selectedMenu)
      setSelectedCountries(filteredCountried)
      setIsCountryBased(selectedMenu.countryIds.length > 0)
    }
  }, [mobileMenus])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockMenuUrl) {
      setMenuDetails({ ...menuDetails, menuUrl: mockMenuUrl })
    }
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setMenuDetails({ ...menuDetails, [name]: value })
    setErrorMessage({ ...errorMessage, [name]: !value })
  }

  const handleSelectUserType = (id) => {
    setMenuDetails({
      ...menuDetails,
      isActiveForLoggedInUser: id !== 2,
      isActiveForGuestUser: id !== 1
    })

    setSelectedUserTypeId(id)
  }

  const handleSelectSubscriptionType = (id) => {
    setMenuDetails((prev) => ({
      ...prev,
      subscriptionIds: prev?.subscriptionIds?.includes(id) ? prev.subscriptionIds.filter((typeId) => typeId !== id) : [...prev.subscriptionIds, id]
    }))
  }

  const handleSelectedCountries = (e) => {
    const selectedCountryIds = e.value?.map((ele) => ele.id)

    setSelectedCountries(e.value)
    setMenuDetails({ ...menuDetails, countryIds: selectedCountryIds })
  }

  const handleClickNoCountryBased = () => {
    setIsCountryBased(false)
    setMenuDetails({ ...menuDetails, countryIds: [] })
    setSelectedCountries([])
  }

  const submitFileToS3Bucket = async (event) => {
    setLoading(true)

    const selectedFile = event?.files[0]
    const fileData = new FormData()

    fileData.append('file', selectedFile)

    try {
      const response = await apiAdapterCoffeeWeb.uploadFileToS3Bucket(fileData)

      setMenuDetails({ ...menuDetails, menuUrl: response.data })
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_UPLOAD'),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const countryFlag = (option) => (
    <div className="country-flag" style={{ alignItems: 'center' }}>
      <Image alt={option.iso} src={option.flagsUrl} />
      <div className="">{option.nicename}</div>
    </div>
  )

  const handleBlur = (e) => {
    const name = e.target?.name
    const value = e.target?.value

    setErrorMessage({ ...errorMessage, [name]: !value })
  }

  const isSubmitButtonDisabled = !menuDetails.menuName || !menuDetails.menuPath || menuDetails.subscriptionIds.length === 0 || (isCountryBased && menuDetails.countryIds.length === 0) || isCountryBased === null || !(menuDetails.isActiveForGuestUser || menuDetails.isActiveForLoggedInUser)

  const handleClickSaveButton = async () => {
    setLoading(true)
    try {
      const res = await apiAdapterCoffeeWeb.editMobileMenu(menuDetails)

      if (res.data?.isSuccess) {
        setMobileMenus(res.data?.returnLst)
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('MENU_UPDATED_SUCCESSFULLY'),
          onHide: handleSuccess,
          onSuccess: handleSuccess
        })
      }
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: error.response.data.message,
        onHide: handleDismiss,
        onRetry: handleClickSaveButton
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleSuccess = () => {
    setShowDialog({ ...showDialog, showModal: false })
    searchParams.delete('edit-menu')
    history.push({ pathname: ROUTE_STRINGS.listmenu, search: 'tab=mobile_menu' })
  }

  return (
    <div className="edit-mobile-menu-component">
      <div className="menu-type-title">{t('EDIT_MENU')}</div>
      <div className="edit-mobile-menu-form">
        <div>
          <div className={`p-float-label ${errorMessage.menuName && 'validation-error'}`}>
            <InputText id="menuName" className="p-inputtext" value={menuDetails.menuName} name="menuName" onChange={(e) => handleInputChange(e)} onBlur={(e) => handleBlur(e)} />
            <label htmlFor="menuName">
              <span>{t('MENU_NAME')}</span> <span className="mandate">*</span>
            </label>
          </div>
          {errorMessage.menuName && <div className="error-message-labeling">{t('MENU_NAME_IS_REQUIRED')}</div>}
        </div>

        <div>
          <div className={`p-float-label ${errorMessage.menuPath && 'validation-error'}`}>
            <InputText id="menuPath" className="p-inputtext" value={menuDetails.menuPath} name="menuPath" onChange={(e) => handleInputChange(e)} onBlur={(e) => handleBlur(e)} />
            <label htmlFor="menuPath">
              <span>{t('MENU_PATH')}</span> <span className="mandate">*</span>
            </label>
          </div>
          {errorMessage.menuPath && <div className="error-message-labeling">{t('MENU_PATH_IS_REQUIRED')}</div>}
        </div>

        <div className="radio-button-wrapper">
          <div>
            {t('USER_TYPE')} <span className="mandate">*</span>
          </div>
          {userTypesConfig.map((ele) => (
            <div className={`each-radio-button ${ele.id === selectedUserTypeId && 'active-radio-button'}`} key={ele.id} onClick={() => handleSelectUserType(ele.id)}>
              <input type="radio" checked={ele.id === selectedUserTypeId} onChange={() => {}} />
              {ele.userType}
            </div>
          ))}
        </div>

        <div className="radio-button-wrapper">
          <div>
            {t('SUBSCRIPTION_TYPE')} <span className="mandate">*</span>
          </div>
          {suscriptionTypes.map((ele) => (
            <div className={`each-radio-button ${menuDetails?.subscriptionIds?.includes(ele.id) && 'active-radio-button'}`} key={ele.id} onClick={() => handleSelectSubscriptionType(ele.id)}>
              <input type="checkbox" checked={menuDetails?.subscriptionIds?.includes(ele.id)} onChange={() => {}} />
              {ele.name}
            </div>
          ))}
        </div>

        <div className="radio-button-wrapper">
          <div>
            {t('COUNTRY_BASED')} <span className="mandate">*</span>
          </div>
          <div className={`each-radio-button ${isCountryBased && 'active-radio-button'}`} onClick={() => setIsCountryBased(true)}>
            <input type="radio" checked={isCountryBased} />
            {t('YES')}
          </div>
          <div className={`each-radio-button ${isCountryBased === false && 'active-radio-button'}`} onClick={() => handleClickNoCountryBased()}>
            <input type="radio" checked={isCountryBased === false} />
            {t('NO')}
          </div>

          {isCountryBased && (
            <div className="multiselect-country-dropdown">
              <MultiSelect value={selectedCountries} name="country-multi-select" onChange={(e) => handleSelectedCountries(e)} options={countryList} itemTemplate={countryFlag} optionLabel="nicename" filter placeholder={t('COUNTRY')} maxSelectedLabels={3} data-testId="countryDropdown" />
            </div>
          )}
        </div>

        <div className="file-upload-wrapper">
          <label htmlFor="*" className="choose-menu-logo-label">
            {t('CHOOSE_MENU_ICON')}
          </label>
          <FileUpload
            chooseLabel="Choose File"
            accept="image/*,.pdf"
            auto={true}
            onSelect={(event) => submitFileToS3Bucket(event)}
            data-testid="file-upload"
            emptyTemplate={
              <div>
                {menuDetails.menuUrl ? (
                  <div className="add-file-upload">
                    <div className="image-file">
                      <div className="add-image-container">
                        <img src={menuDetails.menuUrl} alt="new-component" title="fileUpload-image" />
                      </div>

                      <div className="cancel-action">
                        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => setMenuDetails({ ...menuDetails, menuUrl: '' })} data-testid="cancel-button" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="choose-title">{t('IMAGE_UPLOAD')}</p>
                )}
              </div>
            }
          />
        </div>
        <div className="save-button-wrapper">
          <ButtonComponent type={'submit'} onClick={handleClickSaveButton} disabled={isSubmitButtonDisabled} />
        </div>
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
      <Toast ref={toast} position="top-right" />
    </div>
  )
}

export default Loader(EditMobileMenu)
