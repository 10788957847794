import './styles.scss'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'

const marketTypes = [
  { name: 'Robusta', idMarket: 1 },
  { name: 'Arabica', idMarket: 2 }
]

const AddHoliday = ({ setLoading, robustaHolidayList, arabicaHolidayList, setRobustaHolidayList, setArabicaHolidayList }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const currentTab = searchParams.get('tab')
  const { utcToLocalTime, formatDateToISOWithoutTimezone } = commonMethods
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  const [selectedMarketType, setSelectedMarketType] = useState(null)
  const [marketStartDateAndTime, setMarketStartDateAndTime] = useState(null)
  const [marketEndDateAndTime, setMarketEndDateAndTime] = useState(null)
  const [addHolidayDetails, setAddHolidayDetails] = useState({
    idHoliday: 0,
    idMarket: 0,
    market: '',
    trnDate: '2025-01-08T04:57:34.744Z',
    isFullDayHoliday: true,
    isActive: true,
    marketStartDateTime: '',
    marketEndDateTime: '',
    formattedMarketStartDateTime: '',
    formattedMarketEndDateTime: ''
  })

  const handleMarketTypeDropdownChange = (e) => {
    setAddHolidayDetails({ ...addHolidayDetails, idMarket: e.value.idMarket, market: e.value.name })
    setSelectedMarketType(e.value)
  }

  const handleChangeMarketStartDateAndTime = (e) => {
    const isoTime = formatDateToISOWithoutTimezone(e.value)
    const formattedDate = utcToLocalTime(isoTime)

    setAddHolidayDetails({ ...addHolidayDetails, marketStartDateTime: isoTime, formattedMarketStartDateTime: formattedDate })
    setMarketStartDateAndTime(e.value)
  }

  const handleChangeMarketEndDateAndTime = (e) => {
    const isoTime = formatDateToISOWithoutTimezone(e.value)
    const formattedDate = utcToLocalTime(isoTime)

    setAddHolidayDetails({ ...addHolidayDetails, marketEndDateTime: isoTime, formattedMarketEndDateTime: formattedDate })
    setMarketEndDateAndTime(e.value)
  }

  const handleClickSaveButton = async () => {
    const { formattedMarketStartDateTime, formattedMarketEndDateTime, ...postData } = addHolidayDetails

    setLoading(true)
    try {
      const res = await apiAdapterCoffeeWeb.addNewHolidayData([postData])

      if (res?.data?.isSuccess) {
        if (currentTab === 'robusta') {
          setRobustaHolidayList([...robustaHolidayList, addHolidayDetails])
        } else {
          setArabicaHolidayList([...arabicaHolidayList, addHolidayDetails])
        }
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('HOLIDAY_DATA_ADDED_SUCCESSFULLY'),
          onHide: handleSuccess,
          onSuccess: handleSuccess
        })
      }
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: error.response.data.message,
        onHide: handleDismiss,
        onRetry: handleClickSaveButton
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSuccess = () => {
    setShowDialog({ ...showDialog, showModal: false })
    searchParams.delete('add-holiday')
    history.push({ pathname: ROUTE_STRINGS.listHoliday, search: 'tab=robusta' })
  }

  const handleDismiss = () => setShowDialog({ ...showDialog, showModal: false })
  const isSaveButtonEnabled = selectedMarketType && marketStartDateAndTime && marketEndDateAndTime

  return (
    <div className="add-holiday-component-wrapper">
      <div className="add-holiday-form">
        <div className="p-float-label">
          <Dropdown value={selectedMarketType} onChange={(e) => handleMarketTypeDropdownChange(e)} options={marketTypes} optionLabel="name" className="w-full md:w-14rem" />
          <label htmlFor="menuPath">
            <span>{t('MARKET_TYPE')}</span> <span className="mandate">*</span>
          </label>
        </div>
        <div className="p-float-label">
          <Calendar id="calendar-24h" value={marketStartDateAndTime} onChange={(e) => handleChangeMarketStartDateAndTime(e)} showTime hourFormat="24" />
          <label htmlFor="menuPath">
            <span>{t('STARTS_FROM')}</span> <span className="mandate">*</span>
          </label>
        </div>
        <div className="p-float-label">
          <Calendar id="calendar-24h" value={marketEndDateAndTime} onChange={(e) => handleChangeMarketEndDateAndTime(e)} showTime hourFormat="24" />
          <label htmlFor="menuPath">
            <span>{t('ENDS_AT')}</span> <span className="mandate">*</span>
          </label>
        </div>
        <div className="add-holiday-form-footer">
          <ButtonComponent type={'submit'} onClick={handleClickSaveButton} disabled={!isSaveButtonEnabled} x />
        </div>
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(AddHoliday)
