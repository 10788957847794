import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useErrorBoundary } from 'react-error-boundary'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Form, Field } from 'react-final-form'
import Loader from 'Components/Loader'
import Validation from 'Utils/Validation'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { getLocalUserDetails, getLocalAuthToken, setLocalWithUserDetails, setLocalWithAuthToken, setLocalWithLoggedInStatus } from 'Utils/LocalStorageHandler'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const LoginComponent = ({ setLoading, generatedToken, setModalState, resetError }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { showBoundary } = useErrorBoundary()
  const mobileNumberInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const userDetails = getLocalUserDetails()
  const accessToken = getLocalAuthToken()
  const tabId = sessionStorage.tabId ? sessionStorage.tabId : (sessionStorage.tabId = Math.random())

  window.sessionStorage.setItem('tabId', tabId)

  const [showPassword, setShowPassword] = useState(false)
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const [userInputValues, setUserInputValue] = useState({
    email: '',
    password: '',
    phoneNo: ''
  })

  const [validationState, setValidationState] = useState({
    email: false,
    password: false,
    phoneNo: false
  })

  const params = new URLSearchParams(history.location.search)
  const authModParam = params.get('authMod')

  useEffect(() => {
    setValidationState((previousState) => ({ ...previousState, email: false, password: false, phoneNo: false }))
  }, [resetError])

  if (userDetails && accessToken && !authModParam) {
    history.push(ROUTE_STRINGS.coffeequotes)
  } else if (authModParam) {
    localStorage.clear()
  }

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.code === 'Enter' && !Validation.isInputFieldEmpty(mobileNumberInputRef.current.value)) {
        passwordInputRef.current.focus()
      }
    }

    const mobileNumberInput = mobileNumberInputRef.current

    mobileNumberInput.addEventListener('keyup', handleKeyUp)

    return () => {
      mobileNumberInput.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const authenticateAndProceedToDashboard = async () => {
    setLoading(true)
    const { email, password } = userInputValues
    const postData = {
      username: email,
      password: password,
      includeMenu: true
    }

    try {
      const {
        data,
        data: { isError, userType }
      } = await apiAdapterCoffeeWeb.authenticateAdminUser(postData)

      if (!isError) {
        if (userType === 0) {
          setLocalWithUserDetails(data)
          setLocalWithAuthToken(generatedToken)
          setLocalWithLoggedInStatus(true)
          history.push(ROUTE_STRINGS.coffeequotes)
          setLoading(true)
        } else {
          setInvalidCredentials(true)
        }
      } else {
        setLoading(false)
        setInvalidCredentials(true)
      }
    } catch (error) {
      setModalState({
        showModal: true,
        modalType: 'error',
        message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER')
      })
      showBoundary(error)
    } finally {
      setLoading(false)
    }
  }

  const handleLoginButton = () => {
    const { email, password } = userInputValues

    if (email && password) {
      authenticateAndProceedToDashboard()
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setInvalidCredentials(false)

    if (name === 'email') {
      setValidationState({ ...validationState, email: false })
      const formattedValue = value.toString().toLowerCase()

      setUserInputValue((previousValue) => ({
        ...previousValue,
        email: formattedValue
      }))
    }
    if (name === 'password') {
      setValidationState({ ...validationState, password: false })

      setUserInputValue((previousValue) => ({
        ...previousValue,
        password: value
      }))
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if ((name === 'email' || name === 'password') && (validationState[name] === '' || value === '')) {
      setValidationState((prevValidationState) => ({ ...prevValidationState, [name]: true }))
    }
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const onSubmit = (data) => {
    setUserInputValue(data)
    handleLoginButton()
  }
  const { email, password } = userInputValues
  const isButtonDisabled = !email || !password

  return (
    <>
      <div className="login-form" data-testid="login-form">
        {invalidCredentials && <p className="invalid-credential-error">{t('INVALID_CREDENTIALS')}</p>}

        <Form
          onSubmit={onSubmit}
          initialValues={userInputValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field
                name="email"
                render={({ input, meta }) => (
                  <div className="field">
                    <div className="p-inputgroup flex-1">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-user" />
                      </span>
                      <span className="p-float-label">
                        <InputText placeholder={t('EMAIL_OR_PHONE')} {...input} id="email" onBlur={handleBlur} value={userInputValues.email} onChange={handleChange} ref={mobileNumberInputRef} className={validationState.email ? 'p-invalid' : ''} />

                        <label htmlFor="email" className={validationState.email ? 'p-error label-text' : 'label-text'}>
                          {t('EMAIL_OR_PHONE')} <span className="required-star"> *</span>
                        </label>
                      </span>
                    </div>

                    {validationState.email && (
                      <div>
                        <small className="p-error">{t('EMAIL_OR_PHONE_NUMBER_IS_REQUIRED')}</small>{' '}
                      </div>
                    )}
                  </div>
                )}
              />

              <Field
                name="password"
                render={({ input, meta }) => (
                  <div className="field">
                    <div className="p-inputgroup flex-1">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-key" />
                      </span>
                      <span className="p-input-icon-right">
                        {showPassword ? <i className="pi pi-eye" onClick={handleTogglePassword} /> : <i className="pi pi-eye-slash" onClick={handleTogglePassword} />}
                        <span className="p-float-label">
                          <InputText type={!showPassword ? 'password' : 'text'} id="password" placeholder={t('PASSWORD')} {...input} onBlur={handleBlur} value={userInputValues.password} onChange={handleChange} ref={passwordInputRef} onKeyDown={(e) => e.code === 'Enter' && handleSubmit()} feedback={false} className={validationState.password ? 'p-invalid' : ''} />
                          <label htmlFor="password" className={validationState.password ? 'p-error label-text password-label-text' : 'label-text password-label-text'}>
                            {t('PASSWORD')} <span className="required-star"> *</span>
                          </label>
                        </span>
                      </span>
                    </div>
                    {validationState.password && (
                      <div>
                        <small className="p-error">{t('PASSWORD_IS_REQUIRED')}</small>{' '}
                      </div>
                    )}
                  </div>
                )}
              />
            </form>
          )}
        />

        <div>
          <Button label={t('LOGIN')} onClick={handleLoginButton} className="login-button" disabled={isButtonDisabled} />
        </div>
      </div>
    </>
  )
}

export default Loader(LoginComponent)
