import './styles.scss'
import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { ButtonComponent } from 'Components/UIComponents'
import { useTranslation } from 'react-i18next'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'

const EditCoffeeQuotesData = ({ setLoading, ...props }) => {
  const { visible, setVisible, filteredRowData, handleSetResponse } = props

  const { t } = useTranslation()
  const [initialValues, setInitialValues] = useState(filteredRowData)

  const [fieldsConfig, setFieldsConfig] = useState([
    { id: 'contractName', label: 'Contract Name', type: 'textField', format: 'string' },
    { id: 'lastChng', label: 'Last', type: 'textField', format: 'number' },
    { id: 'chng', label: 'Chg', type: 'textField', format: 'number' },
    { id: 'percentageVal', label: '%', type: 'textField', format: 'number' },
    { id: 'volume', label: 'Volume', type: 'textField', format: 'number' },
    { id: 'highRate', label: 'High', type: 'textField', format: 'number' },
    { id: 'netHigh', label: 'Net-High', type: 'textField', format: 'number' },
    { id: 'lowRate', label: 'Low', type: 'textField', format: 'number' },
    { id: 'netLow', label: 'Net-Low', type: 'textField', format: 'number' },
    { id: 'openRate', label: 'Open Rate', type: 'textField', format: 'number' },
    { id: 'prevRate', label: 'Prev Rate', type: 'textField', format: 'number' },
    { id: 'openInterest', label: 'Open Interest', type: 'textField', format: 'number' },
    { id: 'bid', label: 'Bid', type: 'textField', format: 'number' },
    { id: 'bsize', label: 'B-size', type: 'textField', format: 'number' },
    { id: 'ask', label: 'Ask', type: 'textField', format: 'number' },
    { id: 'asize', label: 'A-size', type: 'textField', format: 'number' },
    { id: 'optionExpiry', label: 'Option Expiry', type: 'datePicker', format: 'string' },
    { id: 'firstNoticeDate', label: 'First Notice Date', type: 'datePicker', format: 'string' },
    { id: '_52weeksHighRate', label: '52 Weeks High Rate', type: 'textField', format: 'number' },
    { id: '_52weeksLowRate', label: '52 Weeks Low Rate', type: 'textField', format: 'number' },
    { id: '_52weeksHighDate', label: '52 Weeks High Date', type: 'datePicker', format: 'string' },
    { id: '_52weeksLowDate', label: '52 Weeks Low Date', type: 'datePicker', format: 'string' },
    { id: 'settlePrice418', label: 'Settle Price', type: 'textField', format: 'string' }
  ])

  const handleInputChange = (e, type) => {
    const { name, value } = e.target

    if (type === 'textField') {
      setInitialValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }))
    } else {
      const selectedDate = new Date(value)
      const year = selectedDate.getFullYear()
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0')
      const day = String(selectedDate.getDate()).padStart(2, '0')
      const hours = String(selectedDate.getHours()).padStart(2, '0')
      const minutes = String(selectedDate.getMinutes()).padStart(2, '0')
      const seconds = String(selectedDate.getSeconds()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

      setInitialValues((prevValues) => ({
        ...prevValues,
        [name]: formattedDate
      }))
    }

    const modifiedConfig = fieldsConfig.map((ele) => (ele.id === name ? { ...ele, error: value === '' } : ele))

    setFieldsConfig(modifiedConfig)
  }

  const validateFields = () => {
    const modifiedConfig = fieldsConfig.map((ele) => ({ ...ele, error: initialValues[ele.id] === '' }))
    const hasEmptyFields = fieldsConfig.some((ele) => ele?.error)

    setFieldsConfig(modifiedConfig)

    return hasEmptyFields
  }

  const handleClickSaveButton = () => {
    const error = validateFields()

    if (error) {
      return
    }

    handleSaveTerminal()
  }

  const handleSaveTerminal = async () => {
    const { lastChng, chng, percentageVal, volume, highRate, netHigh, lowRate, netLow, openRate, prevRate, openInterest, bid, bsize, ask, asize, _52weeksHighRate, _52weeksLowRate } = initialValues

    setLoading(true)
    const updatedValues = {
      ...initialValues,
      lastChng: parseFloat(lastChng),
      chng: parseFloat(chng),
      percentageVal: parseFloat(percentageVal),
      volume: parseFloat(volume),
      highRate: parseFloat(highRate),
      netHigh: parseFloat(netHigh),
      lowRate: parseFloat(lowRate),
      netLow: parseFloat(netLow),
      openRate: parseFloat(openRate),
      prevRate: parseFloat(prevRate),
      openInterest: parseFloat(openInterest),
      bid: parseFloat(bid),
      bsize: parseFloat(bsize),
      ask: parseFloat(ask),
      asize: parseFloat(asize),
      _52weeksHighRate: parseFloat(_52weeksHighRate),
      _52weeksLowRate: parseFloat(_52weeksLowRate)
    }

    try {
      const response = await apiAdapterCoffeeWeb.putICEMarketTerminalData(updatedValues)

      handleSetResponse(response.data.returnLst)
      setLoading(true)
      setVisible(false)
    } catch {
      console.log('error')
    }
  }

  const footerContent = () => (
    <div className="button-container">
      <ButtonComponent type={'cancel'} onClick={() => setVisible(false)} />
      <ButtonComponent type={'Custom'} label="Save" variant="action" onClick={handleClickSaveButton} />
    </div>
  )

  return (
    <div data-testid="edit-coffeequotes-container">
      <Dialog
        header={`${t('EDIT')} ${'-'} ${initialValues?.contractName}`}
        visible={visible}
        footer={footerContent}
        className="edit-coffeequotes-dailog-modal"
        onHide={() => {
          if (!visible) return
          setVisible(false)
        }}
      >
        <div className="edit-coffeequotes-form">
          {fieldsConfig.map((ele, index) => (
            <div className="p-float-label" key={index}>
              {ele.type === 'textField' ? <InputText disabled={ele.id === 'contractName'} type={ele.format} name={ele.id} id={ele.id} className={`${ele?.error && 'mandidate'} p-inputtext-sm`} value={initialValues[ele.id]} onChange={(e) => handleInputChange(e, 'textField')} /> : <Calendar id={ele.id} name={ele.id} inputClassName="p-inputtext-sm" value={new Date(initialValues[ele.id])} onChange={(e) => handleInputChange(e, 'datePicker')} />}
              <label htmlFor={ele.id}>{ele.label}</label>
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  )
}

export default Loader(EditCoffeeQuotesData)
