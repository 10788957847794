import './styles.scss'
import React, { useState } from 'react'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import Validation from 'Utils/Validation'

const MissingPaymentReport = (props) => {
  const { t } = useTranslation()
  const { setLoading, visible, setVisible, subscriptionOptions } = props

  const subscriptionList = subscriptionOptions.map((option) => ({
    label: option.name,
    value: option.id
  }))

  const [formState, setFormState] = useState({
    email: '',
    phoneNumber: '',
    invoiceAmount: '',
    paymentId: '',
    paymentDate: '',
    paymentSource: '',
    tallyDate: null,
    utrNo: '',
    transactionDate: null,
    bankDepositDateAndTime: null,
    settlementNo: '',
    subscriptionId: '',
    grossAmount: 0,
    deductions: 0,
    netAmount: 0,
    fee: '',
    tax: ''
  })

  const [missingReportDialog, setMissingReportDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onConfirmation: () => {} })
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  const { email, phoneNumber, invoiceAmount, paymentId, paymentDate, paymentSource, tallyDate, utrNo, transactionDate, settlementNo, subscriptionId, grossAmount, deductions, bankDepositDateAndTime, tax, fee, netAmount } = formState

  const formatDate = (dateStr) => {
    const dateObj = moment(dateStr, 'MMMM D, YYYY [at] h:mm A')

    return dateObj.toISOString()
  }

  const handleFieldChange = (name, value) => {
    setFormState((prevState) => {
      const updatedState = { ...prevState, [name]: value }

      if (name === 'tax' || name === 'fee') {
        const tax = parseFloat(updatedState.tax || 0)
        const fee = parseFloat(updatedState.fee || 0)

        updatedState.deductions = tax + fee
      }

      const invoiceAmount = parseFloat(updatedState.invoiceAmount || 0)
      const deductionsAmount = parseFloat(updatedState.deductions || 0)

      const grossAmountCalculation = invoiceAmount - deductionsAmount
      const netAmountCalculation = grossAmountCalculation + deductionsAmount

      updatedState.grossAmount = grossAmountCalculation
      updatedState.netAmount = netAmountCalculation

      return updatedState
    })
  }

  const handleFormSubmission = async () => {
    setLoading(true)
    setMissingReportDialog({ ...missingReportDialog, showModal: false })

    const postData = {
      email: email || '',
      phnNo: phoneNumber || '',
      amount: invoiceAmount,
      paymentId,
      paymentDate: formatDate(paymentDate),
      paymentSource,
      tallyDate: formatDate(tallyDate),
      utrNo,
      settlementNo,
      subscriptionName: subscriptionId.toString(),
      transactionDate: formatDate(transactionDate),
      grossAmount: grossAmount,
      deductions: deductions,
      netAmount: netAmount,
      taxAmount: tax,
      feeAmount: fee,
      bankDepositDateAndTime: formatDate(bankDepositDateAndTime)
    }

    try {
      const response = await apiAdapterCoffeeWeb.postMissingPaymentReport(postData)

      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'success',
        message: response?.data?.statusMessage,
        onDismiss: handleDismiss,
        onSuccess: handleDismiss
      })
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: error?.response?.data.statusCode === 400 ? error?.response?.data?.statusMessage : t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER'),
        onRetry: handleRetry
      })
    } finally {
      setLoading(false)
      setVisible(false)
      setFormState({
        tallyDate: null,
        utrNo: '',
        transactionDate: null,
        settlementNo: '',
        grossAmount: '',
        deductions: '',
        netAmount: '',
        tax: '',
        fee: ''
      })
    }
  }

  const handleDismissOnSubmit = () => setMissingReportDialog((prevState) => ({ ...prevState, showModal: false }))

  const handleDismiss = () => setShowDialog({ ...showDialog, showModal: false })

  const handleRetry = () => {
    setShowDialog({ ...showDialog, showModal: false })
    handleFormSubmission()
  }

  const handleReconcileSubmission = () => {
    setMissingReportDialog({
      ...missingReportDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onDismiss: handleDismissOnSubmit,
      onHide: handleDismissOnSubmit,
      onConfirmation: handleFormSubmission
    })
  }

  const handleCancel = () => {
    setFormState({
      tallyDate: null,
      utrNo: '',
      transactionDate: null,
      settlementNo: '',
      grossAmount: '',
      deductions: '',
      netAmount: '',
      bankDepositDateAndTime: null,
      tax: '',
      fee: '',
      subscriptionId: ''
    })
    setVisible(false)
  }

  const onSubmit = (data, form) => {
    setFormState(data)
    form.restart()
  }

  const validate = (values) => {
    const errors = {}

    if (values.email && !Validation.checkEmailValidity(values.email)) {
      errors.email = t('ENTER_VALID_EMAIL')
    }

    const requiredFields = ['invoiceAmount', 'paymentId', 'paymentDate', 'paymentSource', 'tallyDate', 'utrNo', 'settlementNo', 'transactionDate', 'grossAmount', 'bankDepositDateAndTime', 'tax', 'fee', 'deductions', 'netAmount', 'subscriptionId']

    requiredFields.forEach((field) => {
      if (!values[field]) {
        const formattedField = field.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase()

        errors[field] = t(`${formattedField}_IS_REQUIRED`)
      }
    })

    return errors
  }
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const isButtonDisabled = !Object.entries(formState).every(([key, value]) => {
    if (key === 'phoneNumber') {
      return true
    }

    if (key === 'email') {
      return !value || Validation.checkEmailValidity(value)
    }

    return Boolean(value)
  })

  const renderInputField = (name, label, type = 'text') => (
    <Field
      name={name}
      render={({ input, meta }) => (
        <div className="field">
          <span className="p-float-label">
            <InputText id={name} {...input} value={formState[name]} keyfilter={(name === 'phoneNumber' && 'int') || (name === 'invoiceAmount' && 'num')} disabled={name === 'deductions' || name === 'netAmount' || name === 'grossAmount'} onChange={(e) => handleFieldChange(name, e.target.value)} type={type} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
            <label
              htmlFor={name}
              className={classNames({
                'p-error': isFormFieldValid(meta),
                'email-and-phoneNo-label': name === 'email' || name === 'phoneNumber'
              })}
            >
              {t(label)} {name !== 'email' && name !== 'phoneNumber' && <span className="required-field">*</span>}
            </label>
          </span>
          {getFormErrorMessage(meta)}
        </div>
      )}
    />
  )

  const renderCalendarField = (name, label) => (
    <Field
      name={name}
      render={({ input, meta }) => (
        <div className="field">
          <span className="p-float-label">
            <Calendar id={name} {...input} value={formState[name]} onChange={(e) => handleFieldChange(name, e.value)} showIcon hideOnDateTimeSelect showTime={name !== 'tallyDate'} hourFormat="12" className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
            <label htmlFor={name} className={classNames({ 'p-error': isFormFieldValid(meta) })}>
              {t(label)} <span className="required-field">*</span>
            </label>
          </span>
          {getFormErrorMessage(meta)}
        </div>
      )}
    />
  )

  const renderDropdownField = (name, label) => (
    <Field
      name={name}
      render={({ input, meta }) => (
        <div className="field">
          <span className="p-float-label">
            <Dropdown value={formState[name]} {...input} onChange={(e) => handleFieldChange(name, e.value)} options={subscriptionList} placeholder={t('SUBSCRIPTION_NAME')} highlightOnSelect={false} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'missing-payment-dropdown')} missing-payment-dropdown panelClassName="missing-payment-report-panel-style" />
            <label htmlFor={name} className={classNames({ 'p-error': isFormFieldValid(meta) })}>
              {t(label)} <span className="required-field">*</span>
            </label>
          </span>
          {getFormErrorMessage(meta)}
        </div>
      )}
    />
  )

  const footerContent = (
    <div className="button-container">
      <ButtonComponent type={'Submit'} variant="action" onClick={handleReconcileSubmission} disabled={isButtonDisabled} />
      <ButtonComponent type={'cancel'} onClick={handleCancel} />
    </div>
  )

  return (
    <>
      <Dialog header={t('ADD_MISSING_REPORT_FORM')} visible={visible} footer={footerContent} className="missing-payment-report-dialog-container" onHide={handleCancel}>
        <div className="flex justify-content-center">
          <div className="missing-payment-report-form">
            <Form
              onSubmit={onSubmit}
              initialValues={formState}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="p-fluid">
                  <div className="form-input">
                    {renderInputField('email', 'EMAIL')}
                    {renderInputField('phoneNumber', 'PHONE_NUMBER')}
                  </div>
                  <div className="form-input">
                    {renderInputField('invoiceAmount', 'INVOICE_AMOUNT')}
                    {renderInputField('paymentId', 'PAYMENT_ID')}
                  </div>
                  <div className="form-input">
                    {renderInputField('utrNo', 'UTR_NO')}
                    {renderInputField('paymentSource', 'PAYMENT_SOURCE')}
                  </div>
                  <div className="form-input">
                    {renderCalendarField('paymentDate', 'PAYMENT_DATE')}

                    {renderCalendarField('tallyDate', 'TALLY_DATE')}
                  </div>
                  <div className="form-input">
                    {renderCalendarField('transactionDate', 'TRANSACTION_DATE')}
                    {renderCalendarField('bankDepositDateAndTime', 'DATE_AND_TIME')}
                  </div>
                  <div className="form-input">
                    {renderInputField('settlementNo', 'SETTLEMENT_NO')}
                    {renderDropdownField('subscriptionId', 'SUBSCRIPTION_NAME')}
                  </div>
                  <div className="form-input">
                    {renderInputField('tax', 'TAX', 'number')}
                    {renderInputField('fee', 'FEE', 'number')}
                  </div>
                  <div className="form-input">
                    {renderInputField('grossAmount', 'GROSS_AMOUNT', 'number')}
                    {renderInputField('deductions', 'DEDUCTIONS', 'number')}
                  </div>
                  <div className="form-input">{renderInputField('netAmount', 'NET_AMOUNT', 'number')}</div>
                </form>
              )}
            />
          </div>
        </div>
      </Dialog>
      <DialogModal {...missingReportDialog} onDismiss={handleDismissOnSubmit} onHide={handleDismissOnSubmit} />
      <DialogModal {...showDialog} onDismiss={handleDismiss} onHide={handleDismiss} />
    </>
  )
}

export default Loader(MissingPaymentReport)
