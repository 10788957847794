import './styles.scss'
import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'

const AddWeeklyWiseReport = ({ setLoading, selectedMarket, setVisible, visible, labelReport, reportName, setWeeklyWiseReportData, setLabelReport, setLabelSelected, setDateList, setDateSelected, setLeftTableHeader, setLeftTableData, setGetApiData, mockFormData, mockValidatedInputs, mockWeeklyWiseFormData, mockWeeklyWiseReportAPI }) => {
  const { t } = useTranslation()

  const [addButtonEnabled, setAddButtonEnabled] = useState(false)
  const [isSubmitDisable, setIsSubmitDisable] = useState(false)
  const [formData, setFormData] = useState({
    formFields: [
      {
        date: null,
        contract: '',
        openInterest: '',
        netChange: '',
        percentageOpenInterest: '',
        totalTraders: '',
        dropdownValue: null,
        data: Array(4).fill({
          long: '',
          short: '',
          total: '',
          percentLong: '',
          percentShort: '',
          net: '',
          spreading: '',
          saved: false,
          isEditing: false
        }),
        visibleFields: 4,
        isSaved: false
      }
    ]
  })
  const [validatedInputs, setValidatedInputs] = useState({
    date: false,
    contractDate: false,
    openInterest: false,
    netChange: false,
    percentageOpenInterest: false,
    totalTraders: false,
    labelReport: false,
    long: false,
    short: false,
    total: false,
    percentLong: false,
    percentShort: false,
    net: false,
    spreading: false,
    dropdownValue: false
  })
  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onSuccess: () => {}, onHide: () => {}, onRetry: () => {} })

  useEffect(() => {
    const isValid = validateFields()
    const allDropdownSelected = areAllDropdownOptionsSelected()

    setAddButtonEnabled(isValid)
    setIsSubmitDisable(!(isValid && allDropdownSelected))
  }, [formData])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockWeeklyWiseFormData) {
      setValidatedInputs(mockValidatedInputs)
      setFormData(mockFormData)
    }

    if (mockWeeklyWiseReportAPI) {
      handleSubmit()
    }
  }, [])

  const handleDropdownChange = (cardIndex, newDropdownValue) => {
    const updatedFormFields = [...formData.formFields]
    const currentCard = updatedFormFields[cardIndex]

    currentCard.dropdownValue = newDropdownValue

    const rowsToAdd = newDropdownValue === 'NON REPORTABLE POSITIONS' || newDropdownValue === 'NON REPORTABLE' ? 3 : 4

    currentCard.visibleFields = rowsToAdd

    if (currentCard.data.length < rowsToAdd) {
      const rowsToAddMore = Array(rowsToAdd - currentCard.data.length).fill({
        long: '',
        short: '',
        total: '',
        percentLong: '',
        percentShort: '',
        net: '',
        spreading: '',
        saved: false,
        isEditing: false
      })

      currentCard.data.push(...rowsToAddMore)
    }

    setFormData({ ...formData, formFields: updatedFormFields })
  }

  const handleInputChange = (index, rowIndex, field, value) => {
    const updatedFields = [...formData.formFields]
    const updatedRow = { ...updatedFields[index].data[rowIndex] }

    updatedRow[field] = value
    const updatedData = [...updatedFields[index].data]

    updatedData[rowIndex] = updatedRow
    updatedFields[index] = {
      ...updatedFields[index],
      data: updatedData
    }
    setFormData({ ...formData, formFields: updatedFields })

    const isValid = validateFields()

    setAddButtonEnabled(isValid)
  }

  const handleSaveField = (cardIndex) => {
    const updatedFormFields = [...formData.formFields]

    updatedFormFields[cardIndex].data = updatedFormFields[cardIndex].data.map((row) => ({ ...row, saved: true }))
    updatedFormFields[cardIndex].isSaved = true
    setFormData({ ...formData, formFields: updatedFormFields })

    addNewCard()
    setValidatedInputs({
      date: false,
      contractDate: false,
      openInterest: false,
      netChange: false,
      percentageOpenInterest: false,
      totalTraders: false,
      labelReport: false,
      long: false,
      short: false,
      total: false,
      percentLong: false,
      percentShort: false,
      net: false,
      spreading: false,
      dropdownValue: false
    })

    setIsSubmitDisable(isSubmitButtonDisabled())
  }

  const addNewCard = () => {
    const newCard = {
      date: null,
      contract: '',
      openInterest: '',
      netChange: '',
      percentageOpenInterest: '',
      totalTraders: '',
      dropdownValue: null,
      data: Array(4).fill({
        long: '',
        short: '',
        total: '',
        percentLong: '',
        percentShort: '',
        net: '',
        spreading: '',
        saved: false,
        isEditing: false
      }),
      visibleFields: 4,
      isSaved: false
    }

    setFormData((prevState) => ({
      ...prevState,
      formFields: [...prevState.formFields, newCard]
    }))
  }

  const handleEditField = (index) => {
    const updatedFormData = [...formData.formFields]

    updatedFormData.forEach((field, i) => {
      if (i !== index) {
        field.data.forEach((row) => {
          // eslint-disable-next-line
          row.isEditing = false
        })
      }
    })

    updatedFormData[index].data = updatedFormData[index].data.map((row) => ({
      ...row,
      isEditing: true
    }))

    setFormData({ ...formData, formFields: updatedFormData })
  }

  const handleSubmit = async () => {
    const { date, contract, openInterest, netChange, percentageOpenInterest, totalTraders } = formData.formFields[0]

    /* eslint-disable indent */
    const formattedDate = date
      ? (() => {
          const options = { month: 'short' }
          const day = new Date(date).getDate().toString().padStart(2, '0')
          const month = new Intl.DateTimeFormat('en-US', options).format(new Date(date))
          const year = new Date(date).getFullYear()

          return `${day}-${month}-${year}`
        })()
      : ''
    /* eslint-enable indent */
    const formattedData = {
      fileName: reportName,
      date: formattedDate,
      contract,
      openInterest: Number(openInterest) || 0,
      netChange: Number(netChange) || 0,
      percentageOpenInterest: Number(percentageOpenInterest) || 0,
      totalTraders: Number(totalTraders) || 0,
      details: formData.formFields.map((field) => ({
        label: field.dropdownValue,
        colorLabel: '',
        data: field.data.slice(0, field.visibleFields).map((row) => ({
          long: Number(row.long) || 0,
          short: Number(row.short) || 0,
          total: Number(row.total) || 0,
          percentLong: Number(row.percentLong) || 0,
          percentShort: Number(row.percentShort) || 0,
          net: Number(row.net) || 0,
          spreading: Number(row.spreading) || 0
        }))
      }))
    }

    try {
      const response = await apiAdapterCoffeeWeb.postDataForWeeklyWiseReport(formattedData)

      const updatedData = response.data

      setGetApiData(updatedData)

      const { contract, date, details, netChange, openInterest, percentageOpenInterest, totalTraders } = updatedData[0]
      const labelData = details.map((item) => item.label)
      const dateData = updatedData.map((item) => item.date)

      setWeeklyWiseReportData(details[0])
      setLabelReport(labelData)
      setLabelSelected(labelData[0])
      setDateList(dateData)
      setDateSelected(dateData[0])

      setLeftTableHeader({ contract, date })
      setLeftTableData([
        { label: t('OPEN_INTEREST'), value: openInterest },
        { label: t('NET_CHANGE'), value: netChange },
        { label: t('PERCENTAGE_OPEN_INTEREST'), value: percentageOpenInterest },
        { label: t('TOTAL_TRADERS'), value: totalTraders }
      ])

      if (response.status === 200) {
        setErrorModal({
          ...errorModal,
          showModal: true,
          modalType: 'success',
          message: t('WEEKLY_WISE_REPORT_HAS_BEEN_SUBMITTED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }

      handleCloseModal()
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: async () => {
          await handleSubmit()
        },
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    setVisible(false)
    setFormData({
      formFields: [
        {
          date: null,
          contract: '',
          openInterest: '',
          netChange: '',
          percentageOpenInterest: '',
          totalTraders: '',
          dropdownValue: null,
          data: Array(4).fill({
            long: '',
            short: '',
            total: '',
            percentLong: '',
            percentShort: '',
            net: '',
            spreading: '',
            saved: false,
            isEditing: false
          }),
          visibleFields: 4,
          isSaved: false
        }
      ]
    })

    setValidatedInputs({
      date: false,
      contractDate: false,
      openInterest: false,
      netChange: false,
      percentageOpenInterest: false,
      totalTraders: false,
      labelReport: false,
      long: false,
      short: false,
      total: false,
      percentLong: false,
      percentShort: false,
      net: false,
      spreading: false,
      dropdownValue: false
    })
  }

  const handleBlur = (cardIndex, rowIndex, fieldName) => {
    setValidatedInputs((prev) => ({
      ...prev,
      [`${cardIndex}-${rowIndex}-${fieldName}`]: true
    }))
  }

  const handleDeleteField = (index) => {
    const updatedFormFields = [...formData.formFields]
    const deletedDropdownValue = updatedFormFields[index].dropdownValue

    updatedFormFields.splice(index, 1)

    setFormData({ ...formData, formFields: updatedFormFields })

    if (!labelReport.includes(deletedDropdownValue)) {
      labelReport.push(deletedDropdownValue)
    }

    setValidatedInputs((prev) => ({
      ...prev,
      dropdownValue: false
    }))
  }

  const validateFields = () => {
    let isValid = true

    const { date, contract, openInterest, netChange, percentageOpenInterest, totalTraders, dropdownValue } = formData.formFields[0]

    if (!date || !contract || !openInterest || !netChange || !percentageOpenInterest || !totalTraders || !dropdownValue) {
      isValid = false
    }

    formData.formFields.forEach((field) => {
      const rowsToValidate = field.data.slice(0, field.visibleFields)

      if (!field.dropdownValue) {
        isValid = false
      }

      if (((field.dropdownValue === 'NON REPORTABLE POSITIONS' || field.dropdownValue === 'NON REPORTABLE') && rowsToValidate.length !== 3) || (!['NON REPORTABLE POSITIONS', 'NON REPORTABLE'].includes(field.dropdownValue) && rowsToValidate.length !== 4)) {
        isValid = false
      }

      rowsToValidate.forEach((row) => {
        if (!row.long || !row.short || !row.total || !row.percentLong || !row.percentShort || !row.net || (['FUND BUYERS', 'SWAP DEALERS', 'OTHER REPORTABLE'].includes(field.dropdownValue) && !row.spreading)) {
          isValid = false
        }
      })
    })

    return isValid
  }

  const isSubmitButtonDisabled = () => {
    const isValid = validateFields()
    const allDropdownSelected = areAllDropdownOptionsSelected()

    return !(isValid && allDropdownSelected)
  }

  const handleDismiss = () => {
    setErrorModal({ ...errorModal, showModal: false })
  }

  const handleFieldChange = (index, fieldName, value) => {
    const updatedFormFields = [...formData.formFields]

    updatedFormFields[index][fieldName] = value
    setFormData({ ...formData, formFields: updatedFormFields })
  }

  const areAllDropdownOptionsSelected = () => {
    const selectedOptions = formData.formFields.map((field) => field.dropdownValue).filter(Boolean)

    return labelReport.every((option) => selectedOptions.includes(option))
  }

  const handleBlurForConstant = (fieldName) => {
    setValidatedInputs((prev) => ({
      ...prev,
      [fieldName]: true
    }))
  }

  const footerContent = (
    <div className="button-container">
      <div className="button-container">
        <ButtonComponent type="Cancel" onClick={handleCloseModal} />
        <ButtonComponent type="Submit" label="Submit" onClick={handleSubmit} disabled={isSubmitDisable} />
      </div>
    </div>
  )

  const categories = [t('OPEN_INTEREST'), t('NET_CHANGE'), t('PERCENTAGE_OPEN_INTEREST'), t('TOTAL_TRADERS')]

  return (
    <div>
      <Dialog visible={visible} header={`${t('ADD_WEEKLY_WISE_REPORTS')} - ${selectedMarket?.marketName}`} onHide={handleCloseModal} footer={footerContent} className="weekly-wise-dialog-modal">
        <h3>{t('OPEN_INTEREST')}</h3>
        <div className="constant-inputs-container">
          <div>
            <span className="p-float-label">
              <Calendar
                id="date"
                name="date"
                value={formData.formFields[0].date}
                onBlur={() => {
                  handleBlurForConstant('date')
                }}
                onChange={(e) => handleFieldChange(0, 'date', e.target.value)}
                maxDate={new Date()}
                showIcon
                className={`calendar-field ${validatedInputs.date && !formData.formFields[0].date ? 'error-field' : ''}`}
                data-testid="select-date"
              />
              <label htmlFor="date" className={`field-label ${validatedInputs.date && !formData.formFields[0].date ? 'error-label' : ''}`}>
                {t('SELECT_DATE')} <span className="required-text-field">*</span>
              </label>
            </span>
            {validatedInputs.date && !formData.formFields[0].date && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
          </div>
          <div>
            <span className="p-float-label">
              <InputText
                id="contractDate"
                name="contractDate"
                value={formData.formFields[0].contract}
                onBlur={() => {
                  handleBlurForConstant('contractDate')
                }}
                onChange={(e) => handleFieldChange(0, 'contract', e.target.value)}
                className={`text-field ${validatedInputs.contractDate && !formData.formFields[0].contract ? 'error-field' : ''}`}
                data-testid="contract-date"
              />
              <label htmlFor="contractDate" className={`field-label ${validatedInputs.contractDate && !formData.formFields[0].contract ? 'error-label' : ''}`}>
                {t('CONTRACT_DATE')} <span className="required-text-field">*</span>
              </label>
            </span>
            {validatedInputs.contractDate && !formData.formFields[0].contract && <small className="error-message">{t('CONTRACT_DATE_IS_REQUIRED')}</small>}
          </div>
          <div>
            <span className="p-float-label">
              <InputText
                id="openInterest"
                name="openInterest"
                onBlur={() => {
                  handleBlurForConstant('openInterest')
                }}
                value={formData.formFields[0].openInterest}
                keyfilter="num"
                onChange={(e) => handleFieldChange(0, 'openInterest', e.target.value)}
                className={`text-field ${validatedInputs.openInterest && !formData.formFields[0].openInterest ? 'error-field' : ''}`}
                data-testid="open-interest"
              />
              <label htmlFor="openInterest" className={`field-label ${validatedInputs.openInterest && !formData.formFields[0].openInterest ? 'error-label' : ''}`}>
                {t('OPEN_INTEREST')} <span className="required-text-field">*</span>
              </label>
            </span>
            {validatedInputs.openInterest && !formData.formFields[0].openInterest && <small className="error-message">{t('OPEN_INTEREST_DATE_IS_REQUIRED')}</small>}
          </div>
          <div>
            <span className="p-float-label">
              <InputText
                id="netChange"
                name="netChange"
                value={formData.formFields[0].netChange}
                onBlur={() => {
                  handleBlurForConstant('netChange')
                }}
                keyfilter="num"
                onChange={(e) => handleFieldChange(0, 'netChange', e.target.value)}
                className={`text-field ${validatedInputs.netChange && !formData.formFields[0].netChange ? 'error-field' : ''}`}
                data-testid="net-change"
              />
              <label htmlFor="netChange" className={`field-label ${validatedInputs.netChange && !formData.formFields[0].netChange ? 'error-label' : ''}`}>
                {t('NET_CHANGE')} <span className="required-text-field">*</span>.
              </label>
            </span>
            {validatedInputs.netChange && !formData.formFields[0].netChange && <small className="error-message">{t('NET_CHANGE_DATE_IS_REQUIRED')}</small>}
          </div>
          <div>
            <span className="p-float-label">
              <InputText
                id="percentageOpenInterest"
                name="percentageOpenInterest"
                value={formData.formFields[0].percentageOpenInterest}
                onChange={(e) => handleFieldChange(0, 'percentageOpenInterest', e.target.value)}
                onBlur={() => {
                  handleBlurForConstant('percentageOpenInterest')
                }}
                keyfilter="num"
                className={`text-field ${validatedInputs.percentageOpenInterest && !formData.formFields[0].percentageOpenInterest ? 'error-field' : ''}`}
                data-testid="percentage-open-interest"
              />
              <label htmlFor="percentageOpenInterest" className={`field-label ${validatedInputs.percentageOpenInterest && !formData.formFields[0].percentageOpenInterest ? 'error-label' : ''}`}>
                {t('PERCENTAGE_OPEN_INTEREST')} <span className="required-text-field">*</span>
              </label>
            </span>
            {validatedInputs.percentageOpenInterest && !formData.formFields[0].percentageOpenInterest && <small className="error-message">{t('PERCENTAGE_OPEN_INTEREST_IS_REQUIRED')}</small>}
          </div>
          <div>
            <span className="p-float-label">
              <InputText
                id="totalTraders"
                name="totalTraders"
                value={formData.formFields[0].totalTraders}
                onBlur={() => {
                  handleBlurForConstant('totalTraders')
                }}
                keyfilter="num"
                onChange={(e) => handleFieldChange(0, 'totalTraders', e.target.value)}
                className={`text-field ${validatedInputs.totalTraders && !formData.formFields[0].totalTraders ? 'error-field' : ''}`}
                data-testid="total-traders"
              />
              <label htmlFor="totalTraders" className={`field-label ${validatedInputs.totalTraders && !formData.formFields[0].totalTraders ? 'error-label' : ''}`}>
                {t('TOTAL_TRADERS')} <span className="required-text-field">*</span>
              </label>
            </span>
            {validatedInputs.totalTraders && !formData.formFields[0].totalTraders && <small className="error-message">{t('Total Traders Is Required')}</small>}
          </div>
        </div>
        {formData.formFields.map((field, index) => (
          <div key={index} className="dynamic-rows-container">
            <div className="card">
              <Card title={field.dropdownValue}>
                <div className="dropdown-category">
                  <span className="p-float-label">
                    <Dropdown value={field.dropdownValue} id="dropdownValue" name="dropdownValue" onBlur={() => handleBlur(index, null, 'dropdownValue')} onChange={(e) => handleDropdownChange(index, e.value)} options={labelReport.filter((label) => !formData.formFields.some((f) => f.dropdownValue === label && f !== field)).map((label) => ({ label, value: label }))} placeholder={t('SELECT_LABEL')} disabled={field.isSaved} className={`dropdown-field ${validatedInputs[`${index}-null-dropdownValue`] && !field.dropdownValue ? 'error-field' : ''}`} data-testid="dropdown" />
                    <label className={`field-label ${validatedInputs[`${index}-null-dropdownValue`] && !field.dropdownValue ? 'error-label' : ''}`}>
                      {t('SELECT_LABEL')} <span className="required-text-field">*</span>
                    </label>
                  </span>
                  {validatedInputs[`${index}-null-dropdownValue`] && !field.dropdownValue && <small className="error-message">{t('LABEL_IS_REQUIRED')}</small>}
                </div>
                {field.data.slice(0, field.visibleFields).map((row, rowIndex) => (
                  <div key={rowIndex} className="input-field-container">
                    <div className="category-name">
                      <strong>{categories[rowIndex]}</strong>
                    </div>

                    <div className="input-group">
                      {['long', 'short', 'total', 'percentLong', 'percentShort', 'net'].map((fieldName) => (
                        <div key={fieldName}>
                          <span className="p-float-label">
                            <InputText
                              value={row[fieldName]}
                              keyfilter="num"
                              onBlur={() => handleBlur(index, rowIndex, fieldName)}
                              onChange={(e) => handleInputChange(index, rowIndex, fieldName, e.target.value)}
                              // eslint-disable-next-line no-nested-ternary
                              placeholder={fieldName === 'percentLong' ? '% Long' : fieldName === 'percentShort' ? '% Short' : fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                              disabled={row.saved && !row.isEditing}
                              className={`text-field ${validatedInputs[`${index}-${rowIndex}-${fieldName}`] && !row[fieldName] ? 'error-field' : ''}`}
                              data-testid="field-name"
                            />
                            <label className={`field-label ${validatedInputs[`${index}-${rowIndex}-${fieldName}`] && !row[fieldName] ? 'error-label' : ''}`}>
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {fieldName === 'percentLong' ? '% Long' : fieldName === 'percentShort' ? '% Short' : fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                              <span className="required-text-field"> *</span>
                            </label>
                          </span>
                          {validatedInputs[`${index}-${rowIndex}-${fieldName}`] && !row[fieldName] && <small className="error-message">{`${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} Is Required`}</small>}
                        </div>
                      ))}

                      {['FUND BUYERS', 'SWAP DEALERS', 'OTHER REPORTABLE'].includes(field.dropdownValue) && (
                        <div>
                          <span className="p-float-label">
                            <InputText value={row.spreading} keyfilter="num" onBlur={() => handleBlur(index, rowIndex, 'spreading')} onChange={(e) => handleInputChange(index, rowIndex, 'spreading', e.target.value)} placeholder="Spreading" disabled={row.saved && !row.isEditing} className={`text-field ${validatedInputs[`${index}-${rowIndex}-spreading`] && !row.spreading ? 'error-field' : ''}`} data-testid="spreading" />
                            <label className={`field-label ${validatedInputs[`${index}-${rowIndex}-spreading`] && !row.spreading ? 'error-label' : ''}`}>
                              {t('SPREADING')} <span className="required-text-field"> *</span>
                            </label>
                          </span>
                          {validatedInputs[`${index}-${rowIndex}-spreading`] && !row.spreading && <small className="error-message">{t('Spreading Is Required')}</small>}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="add-button-container">
                  {field.isSaved ? (
                    <>
                      <Button icon="pi pi-pencil" className="edit-button" onClick={() => handleEditField(index)} data-testid="edit-button" />
                      <Button icon="pi pi-trash" className="delete-button" onClick={() => handleDeleteField(index)} data-testid="delete-button" />
                    </>
                  ) : (
                    <>{!areAllDropdownOptionsSelected() && <ButtonComponent type="custom" label="ADD" variant="action" onClick={() => handleSaveField(index)} disabled={!addButtonEnabled} />}</>
                  )}
                </div>
              </Card>
            </div>
          </div>
        ))}
      </Dialog>
      <DialogModal {...errorModal} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(AddWeeklyWiseReport)
