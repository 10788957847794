import './styles.scss'
import { React, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Messages } from 'primereact/messages'
import { TabView, TabPanel } from 'primereact/tabview'
import { commonMethods } from 'Utils/commonMethods'
import { TABLE_CONST } from 'Utils/Constants'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import CountrySelector from 'Components/UIComponents/CountrySelector'
import { ButtonComponent } from 'Components/UIComponents'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { Dropdown } from 'primereact/dropdown'

const ApproveGlobalRawCoffeePrice = ({ setLoading, mockApproveGlobalRawCoffeePrice, mockSetCoffeePrice, mockSelectedCountry, mockHandleCurrencyDropdown }) => {
  const { t } = useTranslation()
  const showErrorMessage = useRef(null)
  const { id: idUser } = getLocalUserDetails()

  const [coffeePrice, setCoffeePrice] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', modalCancel: '', onHide: () => {} })
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)

  let rowCount = 6
  let paginationButtons = TABLE_CONST.paginationButtonCount

  useEffect(() => {
    getAllCountryData()
    // fetchCoffeeNewsDataByCurrencyId(countryId)

    // Testing Purpose
    if (mockApproveGlobalRawCoffeePrice) {
      setCoffeePrice(mockSetCoffeePrice)
      setSelectedCountry(mockSelectedCountry)
      if (mockHandleCurrencyDropdown) {
        handleCurrencyDropdown(mockSelectedCountry)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (showErrorMessage.current) {
      showErrorMessage.current.clear()
      showErrorMessage.current.show([{ sticky: true, severity: 'info', summary: 'Error', detail: t('NO_DATA_FOUND_FOR_THIS_SELECTED_COUNTRY'), closable: false }])
    }
  })

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const getAllCountryData = async (id) => {
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getAllCountries()
      const sellerCountries = data.filter((ele) => ele.isCoffeeProducingCountry)

      setCountryList(sellerCountries)

      setLoading(false)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchCoffeeNewsDataByCurrencyId = async (idCountry) => {
    setLoading(true)
    setShowTableMessage(true)
    try {
      let apiMethod

      if (idUser === 2) {
        apiMethod = apiAdapterCoffeeWeb.getGlobalCoffeePriceForAdmin
      } else if (idUser === 1) {
        apiMethod = apiAdapterCoffeeWeb.getGlobalCoffeePriceForSuperAdmin
      }

      const coffeePriceApiResponse = await apiMethod({ idCountry })

      if (coffeePriceApiResponse.data.returnLst.length > 0) {
        const coffeeData = coffeePriceApiResponse.data.returnLst

        setCoffeePrice(coffeeData)
        setErrorMessage(false)
      } else {
        setCoffeePrice([])
        setErrorMessage(true)
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        modalCancel: false,
        onHide: commonMethods.handleRetry
      })
      setCoffeePrice([])
      setErrorMessage(true)
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const approveRawCoffeePrice = async () => {
    setLoading(true)

    try {
      const postData = {
        idUser: idUser,
        idCountry: selectedCountry.id
      }

      let apiMethod

      if (idUser === 2) {
        apiMethod = apiAdapterCoffeeWeb.postApproveCoffeePriceAdmin
      } else if (idUser === 1) {
        apiMethod = apiAdapterCoffeeWeb.postSuperAdminApproveRawCoffeePrice
      }

      const response = await apiMethod(postData)

      if (response.status === 200 || response.status === 201) {
        if (response.data.statusCode === 404) {
          setShowDialog({
            showModal: true,
            modalType: 'error',
            message: 'COULD_NOT_APPROVE_THE_GLOBAL_RAW_COFFEE_DATA',
            onHide: commonMethods.handleRetry
          })

          return
        }

        setShowDialog({
          showModal: true,
          modalType: 'success',
          message: 'DATA_APPROVED_SUCCESSFULLY',
          onHide: handleSuccess
        })
        setCoffeePrice([])
        setErrorMessage(true)
      }
    } catch {
      setShowDialog({
        showModal: true,
        modalType: 'error',
        message: 'COULD_NOT_APPROVE_THE_GLOBAL_RAW_COFFEE_DATA',
        onHide: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const rejectRawCoffeePrice = () => {
    setShowDialog({
      showModal: true,
      message: t('ARE_YOU_SURE_YOU_WANT_TO_REJECT_GLOBAL_RAW_COFFEE_PRICE'),
      modalType: 'info',
      confirmAction: confirmRejectRawCoffeePrice
    })
  }

  const confirmRejectRawCoffeePrice = async () => {
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.rejectGlobalCOffeePrice(selectedCountry.id)

      if (data) {
        setShowDialog({
          showModal: true,
          modalType: 'success',
          message: 'GLOBAL_RAW_COFFEE_PRICE_REJECTED_SUCCESSFULLY',
          onHide: handleSuccess
        })
        setCoffeePrice([])
        setErrorMessage(true)
      } else {
        setShowDialog({
          showModal: true,
          modalType: 'error',
          message: 'COULD_NOT_REJECT_THE_GLOBAL_RAW_COFFEE_DATA',
          onHide: commonMethods.handleRetry
        })
      }
    } catch {
      setShowDialog({
        showModal: true,
        modalType: 'error',
        message: 'COULD_NOT_REJECT_THE_GLOBAL_RAW_COFFEE_DATA',
        onHide: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCurrencyDropdown = (item) => {
    setSelectedCountry(item)
    fetchCoffeeNewsDataByCurrencyId(item.id)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleSuccess = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const headerGroup = (qualityGroupName, terminal, terminalLevel, forexType, forexValue, upDatedDate) => (
    <ColumnGroup>
      <Row>
        <Column header={t('QUALITY')} className="column-row-data" />
        <Column header={`${terminal} : ${terminalLevel}`} className="column-row-data" />
        <Column header={`${forexType} : ${forexValue}`} className="column-row-data" />
        <Column header={upDatedDate} className="column-row-data" />
        <Column header="" className="column-row-data" />
        <Column header="" className="column-row-data" />
      </Row>

      <Row>
        <Column header={qualityGroupName} />
        <Column header={t('RANGE_START')} />
        <Column header={t('RANGE_END')} />
        <Column header={t('CONVERTED_RANGE_START')} />
        <Column header={t('CONVERTED_RANGE_END')} />
        <Column header={t('REMARKS')} />
      </Row>
    </ColumnGroup>
  )

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.name} src={option.flagsUrl} className={`mr-2 flag flag-${option.iso3.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.name}</div>
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const countryOptionTemplate = (option) => (
    <div className="flex align-items-center">
      <img alt={option.name} src={option.flagsUrl} className={`mr-2 flag flag-${option.iso3.toLowerCase()}`} style={{ width: '18px' }} />
      <div>{option.name}</div>
    </div>
  )

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="global-raw-data-container">
      <div className="country-select-component">
        <Dropdown size="small" value={selectedCountry} onChange={(e) => handleCurrencyDropdown(e.value)} options={countryList} optionLabel="name" placeholder="Select a Country" filter valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="" data-testid="country-dropdown" />
        <div className="action-buttons">
          <ButtonComponent type="custom" label="APPROVE" icon="pi pi-check-circle" variant="action" onClick={approveRawCoffeePrice} disabled={coffeePrice.length === 0} />
          <ButtonComponent type="custom" label="REJECT" icon="pi pi-times-circle" variant="action" onClick={rejectRawCoffeePrice} disabled={coffeePrice.length === 0} />
        </div>
      </div>
      {coffeePrice.length > 0 && (
        <TabView className="global-data-table">
          {coffeePrice.map((data, index) => (
            <TabPanel className="global-raw-data-tab-panel" key={index} header={data.country}>
              <TabView>
                {data.cropyear.map((ele, cropYearIndex) => (
                  <TabPanel header={ele.cropyear} key={cropYearIndex}>
                    <TabView>
                      {ele.globalCoffeePriceDTOs.map((item, index2) => (
                        <TabPanel header={item.qualityGroupName} key={index2}>
                          <DataTable value={item.globalCoffeePriceData} headerColumnGroup={headerGroup(item.qualityGroupName, item.terminal, item.terminalLevel, item.forexType, item.forexValue, item?.upDatedDate)} paginator={ele.globalCoffeePriceDTOs.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage}>
                            <Column field="qualityName"></Column>
                            <Column field="rangeStart"></Column>
                            <Column field="rangeEnd"></Column>
                            <Column field="convertedRangeStart"></Column>
                            <Column field="convertedRangeEnd"></Column>
                            <Column field="remarks"></Column>
                          </DataTable>
                        </TabPanel>
                      ))}
                    </TabView>
                  </TabPanel>
                ))}
              </TabView>
            </TabPanel>
          ))}
        </TabView>
      )}

      {errorMessage && <Messages ref={showErrorMessage} />}
      <DialogModal {...showDialog} onDismiss={handleDismiss} onSuccess={handleSuccess} onRetry={commonMethods.handleRetry} onConfirmation={showDialog?.confirmAction} onHide={handleDismiss} />
    </div>
  )
}

export default Loader(ApproveGlobalRawCoffeePrice)
