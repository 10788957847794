import './styles.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import Validation from 'Utils/Validation'
import coffeeReportJsonLinks from 'Utils/Constants/coffeeReportsJsonLink'

const AddLotWiseReport = (props) => {
  const { showDialog, setShowDialog, labelCategories, setLoading, activeTab, setCotReportData, setSelectedData, setLabelCategories, setLeftTableData, setLotWiseReportData, mockFormFields, mockAddLotWiseReport, mockSelectedCategory } = props
  const { t } = useTranslation()
  const { robustaLotWiseComparison, arabicaLotWiseComparison } = coffeeReportJsonLinks
  const reportName = activeTab === 0 ? robustaLotWiseComparison : arabicaLotWiseComparison

  let lengthOfButton

  const [editingIndex, setEditingIndex] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [dateValue, setDateValue] = useState([])
  const [selectedCategory, setSelectedCategory] = useState({})
  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })

  const [inputForm, setInputForm] = useState({
    date: '',
    netChange: '',
    openInterest: '',
    terminalLevel: ''
  })

  const [formFields, setFormFields] = useState([
    {
      label: '',
      difflong: '',
      diffnet: '',
      diffpercentLong: '',
      diffpercentShort: '',
      diffshort: '',
      diffspreading: '',
      difftotal: '',
      date: '',
      long: '',
      net: '',
      percentLong: '',
      percentShort: '',
      short: '',
      spreading: '',
      total: '',
      compareToPreviousDay: ''
    }
  ])

  const [touchedFields, setTouchedFields] = useState({
    long: false,
    net: false,
    percentLong: false,
    percentShort: false,
    short: false,
    spreading: false,
    total: false,
    label: false,
    date: false,
    netChange: false,
    openInterest: false,
    terminalLevel: false,
    difflong: false,
    diffnet: false,
    diffpercentLong: false,
    diffpercentShort: false,
    diffshort: false,
    diffspreading: false,
    difftotal: false,
    diffDate: false,
    compareToPreviousDay: false
  })

  useEffect(() => {
    const filled = formFields.every((item) => item.long && item.short && item.percentLong && item.percentShort && item.net && item.total && item.label && item.difflong && item.diffnet && item.diffshort && item.diffpercentLong && item.diffpercentShort && item.difftotal && item.date)

    setIsFormFilled(filled)
  }, [formFields])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockAddLotWiseReport) {
      setFormFields(mockFormFields)
      setSelectedCategory(mockSelectedCategory)
      insertLotWiseReport()
    }
  }, [])

  const insertLotWiseReport = async () => {
    setLoading(true)
    handleDismiss()

    try {
      const data = {
        fileName: reportName,
        date: inputForm.date,
        terminalLevel: inputForm.terminalLevel,
        netChange: inputForm.netChange,
        openInterest: inputForm.openInterest,
        details: formFields
      }

      const response = await apiAdapterCoffeeWeb.postLotWiseReport(data)

      if (response.status === 200) {
        const reportDetails = response?.data?.details
        const categories = reportDetails.map((ele) => ({ categoryLabel: ele.label }))

        const emptyRow = {
          date: '-',
          terminalLevel: '-',
          openInterest: '-',
          netChange: '-'
        }

        const { long, short, total, percentLong, percentShort, net, spreading, compareToPreviousDay } = response.data.details[0]

        const rowData = {
          long: long,
          short: short,
          total: total,
          percentLong: percentLong,
          percentShort: percentShort,
          net: net,
          spreading: spreading,
          compareToPreviousDay: compareToPreviousDay
        }

        setCotReportData(reportDetails)
        setSelectedData(categories[0])
        setLabelCategories(categories)

        const leftTableDataWithEmptyRow = [{ ...emptyRow }, ...response.data.terminalDetails]

        setLeftTableData(leftTableDataWithEmptyRow)

        const rightTableDataWithEmptyRow = [{ ...rowData }, ...response.data.details[0].data]

        setLotWiseReportData(rightTableDataWithEmptyRow)
        setShowDialog(false)
        resetData()

        setErrorModal({
          ...errorModal,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_ADDED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }
    } catch {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: insertLotWiseReport,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDateChange = (event) => {
    const chosenDate = event.value
    const formattedDate = chosenDate ? moment(chosenDate).format('DD-MMM-YYYY') : ''

    setSelectedDate(chosenDate)

    setInputForm((prevState) => ({
      ...prevState,
      date: formattedDate
    }))
  }

  const handleDateChangeValue = (event, index) => {
    const chosenDate = event.value
    const formattedDate = chosenDate ? moment(chosenDate).format('DD-MMM-YYYY') : ''

    const updatedFields = [...formFields]

    updatedFields[index] = {
      ...updatedFields[index],
      date: formattedDate
    }
    setFormFields(updatedFields)

    const updatedDateValue = [...dateValue]

    updatedDateValue[index] = chosenDate
    setDateValue(updatedDateValue)
  }

  const handleDropdownChange = (index, event) => {
    const value = event.value

    const updatedFields = [...formFields]

    updatedFields[index] = {
      ...updatedFields[index],
      label: value.categoryLabel
    }

    setSelectedCategory((prevSelectedData) => ({
      ...prevSelectedData,
      [index]: value
    }))

    setFormFields(updatedFields)
  }

  const handleInputChange = (index, field, value) => {
    const isValid = Validation.phoneCharValidation(value)

    const updatedFields = [...formFields]

    if (isValid) {
      updatedFields[index][field] = value
    } else {
      updatedFields[index][field] = ''
    }
    if (selectedCategory[index]?.categoryLabel !== 'FUND BUYERS' && selectedCategory[index]?.categoryLabel !== 'SWAP DEALERS' && selectedCategory[index]?.categoryLabel !== 'OTHER REPORTABLE') {
      updatedFields[index].spreading = '0'
      updatedFields[index].diffspreading = '0'
      updatedFields[index].compareToPreviousDay = '0'
    }

    setFormFields(updatedFields)
  }

  const handleAddFields = () => {
    setEditingIndex(null)
    setFormFields([
      ...formFields,
      {
        date: '',
        difflong: '',
        diffnet: '',
        diffpercentLong: '',
        diffpercentShort: '',
        diffshort: '',
        diffspreading: '',
        difftotal: '',
        label: '',
        long: '',
        net: '',
        percentLong: '',
        percentShort: '',
        short: '',
        spreading: '',
        total: '',
        compareToPreviousDay: ''
      }
    ])

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      long: false,
      net: false,
      percentLong: false,
      percentShort: false,
      short: false,
      spreading: false,
      total: false,
      label: false,
      date: false,
      netChange: false,
      openInterest: false,
      terminalLevel: false,
      difflong: false,
      diffnet: false,
      diffpercentLong: false,
      diffpercentShort: false,
      diffshort: false,
      diffspreading: false,
      difftotal: false,
      diffDate: false,
      compareToPreviousDay: false
    }))
  }

  const resetData = () => {
    setFormFields([
      {
        date: '',
        difflong: '',
        diffnet: '',
        diffpercentLong: '',
        diffpercentShort: '',
        diffshort: '',
        diffspreading: '',
        difftotal: '',
        label: '',
        long: '',
        net: '',
        percentLong: '',
        percentShort: '',
        short: '',
        spreading: '',
        total: '',
        compareToPreviousDay: ''
      }
    ])

    setInputForm({
      date: '',
      netChange: '',
      openInterest: '',
      terminalLevel: ''
    })

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      long: false,
      net: false,
      percentLong: false,
      percentShort: false,
      short: false,
      spreading: false,
      total: false,
      label: false,
      date: false,
      netChange: false,
      openInterest: false,
      terminalLevel: false,
      difflong: false,
      diffnet: false,
      diffpercentLong: false,
      diffpercentShort: false,
      diffshort: false,
      diffspreading: false,
      difftotal: false,
      diffDate: false,
      compareToPreviousDay: false
    }))

    setSelectedDate(null)
    setDateValue([])
    setSelectedCategory({})
  }

  const handleBlur = (fieldName) => {
    setTouchedFields((prev) => ({
      ...prev,
      [fieldName]: true
    }))
  }

  const handleClearRow = (index) => {
    const updatedFields = [...formFields]
    const updatedSelectedData = { ...selectedCategory }

    updatedFields.splice(index, 1)

    delete updatedSelectedData[index]

    const newSelectedData = Object.keys(updatedSelectedData).reduce((acc, key) => {
      const currentIndex = parseInt(key, 10)

      if (currentIndex > index) {
        acc[currentIndex - 1] = updatedSelectedData[currentIndex]
      } else {
        acc[currentIndex] = updatedSelectedData[currentIndex]
      }

      return acc
    }, {})

    const updatedDateValue = [...dateValue]

    updatedDateValue.splice(index, 1)

    setFormFields(updatedFields)
    setSelectedCategory(newSelectedData)
    setDateValue(updatedDateValue)
  }

  const isFieldValid = () => {
    const disable = formFields.every((item) => item.long && item.short && item.percentLong && item.percentShort && item.net && item.total && item.label && item.difflong && item.diffnet && item.diffshort && item.diffpercentLong && item.diffpercentShort && item.difftotal && item.date)

    return disable
  }

  const isFieldDisabled = (index) => index < formFields.length - 1 && editingIndex !== index

  const getFilteredOptions = (index) => {
    const categoryReport = formFields.map((field) => field.label)

    const filteredOptions = labelCategories.filter((option) => !categoryReport.includes(option.categoryLabel) || selectedCategory[index]?.categoryLabel === option.categoryLabel)

    lengthOfButton = filteredOptions.length

    return filteredOptions
  }

  const handleEditClick = (index) => {
    setEditingIndex(index)
  }

  const closeDialog = () => {
    setShowDialog(false)
    resetData()
  }

  const disable = formFields.every((item) => item.long && item.short && item.percentLong && item.percentShort && item.net && item.total && item.label && item.difflong && item.diffnet && item.diffshort && item.diffpercentLong && item.diffpercentShort && item.difftotal)

  const emptyFields = !inputForm.date || !inputForm.openInterest || !inputForm.netChange || !inputForm.terminalLevel

  const handleChange = (fieldName, newValue) => {
    const updateStockData = (data) => ({
      ...data,
      [fieldName]: newValue
    })

    setInputForm((prevState) => updateStockData(prevState))
  }

  const handleDismiss = () => {
    setErrorModal({ ...errorModal, showModal: false })
  }

  const footerContent = () => (
    <div className="footer-wrapper">
      <ButtonComponent type={'cancel'} onClick={closeDialog} />

      <ButtonComponent type={'submit'} onClick={insertLotWiseReport} disabled={lengthOfButton !== 1 || !disable || emptyFields} />
    </div>
  )

  return (
    <>
      <Dialog visible={showDialog} onHide={closeDialog} header={`${t('ADD_LOT_WISE_REPORT')} - ${activeTab === 0 ? t('ROBUSTA_TITLE') : t('ARABICA_TITLE')}`} footer={footerContent} className="lot-wise-report-dialog-wrapper">
        <div className="open-interest-container">
          <h3 className="open-interest-title">{t('OPEN_INTEREST')}</h3>
        </div>

        <div className="form-wrapper">
          <div className="form-fields">
            <span className="p-float-label">
              <Calendar
                id="date"
                name="date"
                value={selectedDate}
                onChange={handleDateChange}
                onBlur={() => {
                  handleBlur('date')
                }}
                maxDate={new Date()}
                showIcon
                className={`calendar-field ${touchedFields.date && !selectedDate ? 'error' : ''}`}
                data-testid="date"
              />
              <label htmlFor="date" className={`field-label ${touchedFields.date && !selectedDate ? 'error' : ''}`}>
                {t('DATE')} <span className="required-text-field">*</span>
              </label>
            </span>
            {touchedFields.date && !selectedDate && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
          </div>

          <div className="form-fields">
            <span className="p-float-label">
              <InputText
                id="terminalLevel"
                name="terminalLevel"
                value={inputForm.terminalLevel}
                className={`input-field ${touchedFields.terminalLevel && !inputForm.terminalLevel ? 'error' : ''}`}
                onChange={(event) => {
                  handleChange('terminalLevel', event.target.value)
                }}
                onBlur={() => {
                  handleBlur('terminalLevel')
                }}
                data-testid="terminalLevel"
              />
              <label htmlFor="terminalLevel" className={`field-label ${touchedFields.terminalLevel && !inputForm.terminalLevel ? 'error' : ''}`}>
                {t('TERMINAL_LEVEL')} <span className="required-text-field">*</span>
              </label>
            </span>
            {touchedFields.terminalLevel && !inputForm.terminalLevel && <small className="error-message">{t('TERMINAL_LEVEL_ERROR_MESSAGE')}</small>}
          </div>

          <div className="form-fields">
            <span className="p-float-label">
              <InputText
                id="openInterest"
                name="openInterest"
                value={inputForm.openInterest}
                className={`input-field ${touchedFields.openInterest && !inputForm.openInterest ? 'error' : ''}`}
                onChange={(event) => {
                  handleChange('openInterest', event.target.value)
                }}
                onBlur={() => {
                  handleBlur('openInterest')
                }}
                data-testid="openInterest"
              />
              <label htmlFor="openInterest" className={`field-label ${touchedFields.openInterest && !inputForm.openInterest ? 'error' : ''}`}>
                {t('OPEN_INTEREST')} <span className="required-text-field">*</span>
              </label>
            </span>
            {touchedFields.openInterest && !inputForm.openInterest && <small className="error-message">{t('OPEN_INTEREST_ERROR_MESSAGE')}</small>}
          </div>

          <div className="form-fields">
            <span className="p-float-label">
              <InputText
                id="netChange"
                name="netChange"
                value={inputForm.netChange}
                className={`input-field ${touchedFields.netChange && !inputForm.netChange ? 'error' : ''}`}
                onChange={(event) => {
                  handleChange('netChange', event.target.value)
                }}
                onBlur={() => {
                  handleBlur('netChange')
                }}
                data-testid="netChange"
              />
              <label htmlFor="netChange" className={`field-label ${touchedFields.netChange && !inputForm.netChange ? 'error' : ''}`}>
                {t('NET_CHANGE')} <span className="required-text-field">*</span>
              </label>
            </span>
            {touchedFields.netChange && !inputForm.netChange && <small className="error-message">{t('NET_CHANGE_ERROR_MESSAGE')}</small>}
          </div>
        </div>

        <div className="input-container">
          <h3 className="form-title">{t('CATEGORY_TITLE')}</h3>
          {formFields.map((item, index) => (
            <>
              <Card className="form-card" key={index}>
                <div className="header-field">
                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <Dropdown id="category-label" value={selectedCategory[index] || null} options={getFilteredOptions(index)} onChange={(event) => handleDropdownChange(index, event)} placeholder={t('SELECT_CATEGORY')} name="label" optionLabel="categoryLabel" className={`input-field ${touchedFields.label && !selectedCategory[index] ? 'error' : ''}`} onBlur={() => handleBlur('label')} disabled={isFieldDisabled(index)} data-testid="category-label" />
                      <label htmlFor="category-label" className={`field-label ${touchedFields.label && !selectedCategory[index] ? 'error' : ''}`}>
                        {t('SELECT_CATEGORY')} <span className="required-text-field">*</span>{' '}
                      </label>
                    </span>
                    {touchedFields.label && !selectedCategory[index] && <small className="error-message">{t('CATEGORY_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <Calendar
                        id={`date-${index}`}
                        name="diffDate"
                        value={dateValue[index] || null}
                        onChange={(event) => {
                          handleDateChangeValue(event, index)
                        }}
                        onBlur={() => {
                          handleBlur('diffDate')
                        }}
                        maxDate={new Date()}
                        showIcon
                        className={`calendar-field ${touchedFields.diffDate && !dateValue[index] ? 'error' : ''}`}
                        data-testid="diffDate"
                      />
                      <label htmlFor={`date-${index}`} className={`field-label ${touchedFields.diffDate && !dateValue[index] ? 'error' : ''}`}>
                        {t('DATE')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.diffDate && !dateValue[index] && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
                  </div>
                </div>

                <div className="field-wrapper" key={index}>
                  <p className="category-title">{t('CATEGORY_TITLE')}</p>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="long"
                        name="long"
                        value={item.long}
                        className={`input-field ${touchedFields.long && !item.long ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'long', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('long')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="long"
                      />
                      <label htmlFor="long" className={`field-label ${touchedFields.long && !item.long ? 'error' : ''}`}>
                        {t('LONG')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.long && !item.long && <small className="error-message">{t('LONG_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="short"
                        name="short"
                        value={item.short}
                        className={`input-field ${touchedFields.short && !item.short ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'short', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('short')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="short"
                      />
                      <label htmlFor="short" className={`field-label ${touchedFields.short && !item.short ? 'error' : ''}`}>
                        {t('SHORT')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.short && !item.short && <small className="error-message">{t('SHORT_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="total"
                        name="total"
                        value={item.total}
                        className={`input-field ${touchedFields.total && !item.total ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'total', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('total')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="total"
                      />
                      <label htmlFor="total" className={`field-label ${touchedFields.total && !item.total ? 'error' : ''}`}>
                        {t('TOTAL')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.total && !item.total && <small className="error-message">{t('TOTAL_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="percentLong"
                        name="percentLong"
                        value={item.percentLong}
                        className={`input-field ${touchedFields.percentLong && !item.percentLong ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'percentLong', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('percentLong')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="percentLong"
                      />
                      <label htmlFor="percentLong" className={`field-label ${touchedFields.percentLong && !item.percentLong ? 'error' : ''}`}>
                        {t('PERCENTAGE_LONG')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.percentLong && !item.percentLong && <small className="error-message">{t('PERCENTAGE_LONG_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="percentShort"
                        name="percentShort"
                        value={item.percentShort}
                        className={`input-field ${touchedFields.percentShort && !item.percentShort ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'percentShort', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('percentShort')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="percentShort"
                      />
                      <label htmlFor="percentShort" className={`field-label ${touchedFields.percentShort && !item.percentShort ? 'error' : ''}`}>
                        {t('PERCENTAGE_SHORT')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.percentShort && !item.percentShort && <small className="error-message">{t('PERCENTAGE_SHORT_ERROR_MESSAGE')}</small>}
                  </div>
                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="net"
                        name="net"
                        value={item.net}
                        className={`input-field ${touchedFields.net && !item.net ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'net', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('net')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="net"
                      />
                      <label htmlFor="net" className={`field-label ${touchedFields.net && !item.net ? 'error' : ''}`}>
                        {t('NET')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.net && !item.net && <small className="error-message">{t('NET_ERROR_MESSAGE')}</small>}
                  </div>

                  {(selectedCategory[index]?.categoryLabel === 'FUND BUYERS' || selectedCategory[index]?.categoryLabel === 'SWAP DEALERS' || selectedCategory[index]?.categoryLabel === 'OTHER REPORTABLE') && (
                    <>
                      <div className="input-field-wrapper">
                        <span className="p-float-label">
                          <InputText
                            id="spreading"
                            name="spreading"
                            value={item.spreading}
                            className={`input-field ${touchedFields.spreading && !item.spreading ? 'error' : ''}`}
                            onChange={(event) => {
                              handleInputChange(index, 'spreading', event.target.value)
                            }}
                            onBlur={() => {
                              handleBlur('spreading')
                            }}
                            disabled={isFieldDisabled(index)}
                            data-testid="spreading"
                          />
                          <label htmlFor="spreading" className={`field-label ${touchedFields.spreading && !item.spreading ? 'error' : ''}`}>
                            {t('SPREADING')} <span className="required-text-field">*</span>
                          </label>
                        </span>
                        {touchedFields.spreading && !item.spreading && <small className="error-message">{t('SPREADING_ERROR_MESSAGE')}</small>}
                      </div>
                      <div className="input-field-wrapper">
                        <span className="p-float-label">
                          <InputText
                            id="compareToPreviousDay"
                            name="compareToPreviousDay"
                            value={item.compareToPreviousDay}
                            className={`input-field ${touchedFields.compareToPreviousDay && !item.compareToPreviousDay ? 'error' : ''}`}
                            onChange={(event) => {
                              handleInputChange(index, 'compareToPreviousDay', event.target.value)
                            }}
                            onBlur={() => {
                              handleBlur('compareToPreviousDay')
                            }}
                            disabled={isFieldDisabled(index)}
                          />
                          <label htmlFor="compareToPreviousDay" className={`field-label ${touchedFields.compareToPreviousDay && !item.compareToPreviousDay ? 'error' : ''}`}>
                            {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                          </label>
                        </span>
                        {touchedFields.compareToPreviousDay && !item.compareToPreviousDay && <small className="error-message">{t('COMPARE_PRE_DAY_ERROR_MESSAGE')}</small>}
                      </div>
                    </>
                  )}
                </div>

                <div className="field-wrapper" key={index}>
                  <p className="difference-title">{t('DIFFERENCE')}</p>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="difference-long"
                        name="difflong"
                        value={item.difflong}
                        className={`input-field ${touchedFields.difflong && !item.difflong ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'difflong', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('difflong')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="difference-long"
                      />
                      <label htmlFor="difference-long" className={`field-label ${touchedFields.difflong && !item.difflong ? 'error' : ''}`}>
                        {t('LONG')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.difflong && !item.difflong && <small className="error-message">{t('LONG_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="difference-short"
                        name="diffshort"
                        value={item.diffshort}
                        className={`input-field ${touchedFields.diffshort && !item.diffshort ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'diffshort', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('diffshort')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="difference-short"
                      />
                      <label htmlFor="difference-short" className={`field-label ${touchedFields.diffshort && !item.diffshort ? 'error' : ''}`}>
                        {t('SHORT')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.diffshort && !item.diffshort && <small className="error-message">{t('SHORT_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="difference-total"
                        name="difftotal"
                        value={item.difftotal}
                        className={`input-field ${touchedFields.difftotal && !item.difftotal ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'difftotal', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('difftotal')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="difference-total"
                      />
                      <label htmlFor="difference-total" className={`field-label ${touchedFields.difftotal && !item.difftotal ? 'error' : ''}`}>
                        {t('TOTAL')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.difftotal && !item.difftotal && <small className="error-message">{t('TOTAL_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="difference-percentage-long"
                        name="diffpercentLong"
                        value={item.diffpercentLong}
                        className={`input-field ${touchedFields.diffpercentLong && !item.diffpercentLong ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'diffpercentLong', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('diffpercentLong')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="difference-percentage-long"
                      />
                      <label htmlFor="difference-percentage-long" className={`field-label ${touchedFields.diffpercentLong && !item.diffpercentLong ? 'error' : ''}`}>
                        {t('PERCENTAGE_LONG')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.diffpercentLong && !item.diffpercentLong && <small className="error-message">{t('PERCENTAGE_LONG_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="difference-percentage-short"
                        name="diffpercentShort"
                        value={item.diffpercentShort}
                        className={`input-field ${touchedFields.diffpercentShort && !item.diffpercentShort ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'diffpercentShort', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('diffpercentShort')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="difference-percentage-short"
                      />
                      <label htmlFor="difference-percentage-short" className={`field-label ${touchedFields.diffpercentShort && !item.diffpercentShort ? 'error' : ''}`}>
                        {t('PERCENTAGE_SHORT')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.diffpercentShort && !item.diffpercentShort && <small className="error-message">{t('PERCENTAGE_SHORT_ERROR_MESSAGE')}</small>}
                  </div>

                  <div className="input-field-wrapper">
                    <span className="p-float-label">
                      <InputText
                        id="difference-net"
                        name="diffnet"
                        value={item.diffnet}
                        className={`input-field ${touchedFields.diffnet && !item.diffnet ? 'error' : ''}`}
                        onChange={(event) => {
                          handleInputChange(index, 'diffnet', event.target.value)
                        }}
                        onBlur={() => {
                          handleBlur('diffnet')
                        }}
                        disabled={isFieldDisabled(index)}
                        data-testid="difference-net"
                      />
                      <label htmlFor="difference-net" className={`field-label ${touchedFields.diffnet && !item.diffnet ? 'error' : ''}`}>
                        {t('NET')} <span className="required-text-field">*</span>
                      </label>
                    </span>
                    {touchedFields.diffnet && !item.diffnet && <small className="error-message">{t('NET_ERROR_MESSAGE')}</small>}
                  </div>
                  {(selectedCategory[index]?.categoryLabel === 'FUND BUYERS' || selectedCategory[index]?.categoryLabel === 'SWAP DEALERS' || selectedCategory[index]?.categoryLabel === 'OTHER REPORTABLE') && (
                    <div className="input-field-wrapper">
                      <span className="p-float-label">
                        <InputText
                          id="difference-spreading"
                          name="diffspreading"
                          value={item.diffspreading}
                          className={`input-field ${touchedFields.diffspreading && !item.diffspreading ? 'error' : ''}`}
                          onChange={(event) => {
                            handleInputChange(index, 'diffspreading', event.target.value)
                          }}
                          onBlur={() => {
                            handleBlur('diffspreading')
                          }}
                          disabled={isFieldDisabled(index)}
                          data-testid="difference-spreading"
                        />
                        <label htmlFor="difference-spreading" className={`field-label ${touchedFields.diffspreading && !item.diffspreading ? 'error' : ''}`}>
                          {t('SPREADING')} <span className="required-text-field">*</span>
                        </label>
                      </span>
                      {touchedFields.diffspreading && !item.diffspreading && <small className="error-message">{t('SPREADING_ERROR_MESSAGE')}</small>}
                    </div>
                  )}
                </div>

                {formFields.length === index + 1 && lengthOfButton !== 1 && (
                  <div className="button-content">
                    <ButtonComponent type="custom" label={isFormFilled ? t('SAVE') : t('ADD')} icon={isFormFilled ? '' : 'pi pi-plus'} variant="action" onClick={handleAddFields} disabled={!isFieldValid()} />
                  </div>
                )}

                {formFields.length !== index + 1 && (
                  <div className="button-content">
                    <Button icon="pi pi-pencil" className="edit-button" onClick={() => handleEditClick(index)} data-testid="edit-button" />
                    <Button icon="pi pi-trash" className="delete-button" onClick={() => handleClearRow(index)} data-testid="delete-button" />
                  </div>
                )}
              </Card>
            </>
          ))}
        </div>
      </Dialog>
      <DialogModal {...errorModal} onDismiss={handleDismiss} />
    </>
  )
}

export default Loader(AddLotWiseReport)
