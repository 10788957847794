import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const EditWeeklyWiseReport = ({ editDataSelected, setOpenModal, openModal, selectedMarket, labelSelected, setLoading, reportName, setWeeklyWiseReportData, setLabelReport, setLabelSelected, setDateList, setDateSelected, setLeftTableHeader, setLeftTableData, setGetApiData, mockApiCall }) => {
  const { t } = useTranslation()
  const { rowData, categoryData } = editDataSelected
  const [categories, setCategories] = useState(categoryData?.data || [])
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })

  const [isFormEnabled, setIsFormEnabled] = useState({
    editMode: false,
    submitButton: false
  })

  const [errors, setErrors] = useState({
    date: false,
    openInterest: false,
    netChange: false,
    percentageOpenInterest: false,
    totalTraders: false,
    categories: []
  })

  const [editDetails, setEditDetails] = useState({
    date: '',
    openInterest: '',
    netChange: '',
    percentageOpenInterest: '',
    totalTraders: '',
    contract: ''
  })

  useEffect(() => {
    if (editDataSelected) {
      setEditDetails({
        date: rowData?.date,
        openInterest: rowData?.openInterest,
        netChange: rowData?.netChange,
        percentageOpenInterest: rowData?.percentageOpenInterest,
        totalTraders: rowData?.totalTraders,
        contract: rowData?.contract
      })
      setCategories(categoryData?.data || [])
    }
  }, [setOpenModal, editDataSelected])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockApiCall) {
      updateLotWiseData()
    }
  }, [])

  const updateLotWiseData = async () => {
    handleDismiss()
    setLoading(true)

    try {
      const data = {
        fileName: reportName,
        date: editDetails.date,
        contract: editDetails.contract,
        openInterest: editDetails.openInterest,
        netChange: editDetails.netChange,
        percentageOpenInterest: editDetails.percentageOpenInterest,
        totalTraders: editDetails.totalTraders,
        details: {
          label: categoryData?.label,
          colorLabel: categoryData?.colorLabel,
          data: categories
        }
      }

      const response = await apiAdapterCoffeeWeb.updateWeeklyWiseReportData(data)

      if (response.status === 200) {
        setButtonDisabled(true)

        const updatedData = response.data

        setGetApiData(updatedData)

        const { contract, date, details, netChange, openInterest, percentageOpenInterest, totalTraders } = updatedData[0]

        const labelData = details.map((item) => item.label)
        const dateData = updatedData.map((item) => item.date)

        const labelFilterData = labelData.filter((label) => label === labelSelected)

        setWeeklyWiseReportData(details[0])
        setLabelReport(labelData)
        setLabelSelected(labelFilterData[0])
        setDateList(dateData)
        setDateSelected(dateData[0])

        setLeftTableHeader({ contract, date })
        setLeftTableData([
          { label: t('OPEN_INTEREST'), value: openInterest },
          { label: t('NET_CHANGE'), value: netChange },
          { label: t('PERCENTAGE_OPEN_INTEREST'), value: percentageOpenInterest },
          { label: t('TOTAL_TRADERS'), value: totalTraders }
        ])

        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('REPORT_DATA_UPDATED_SUCCESSFULLY'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })

        setOpenModal(false)
        setIsFormEnabled({
          editMode: false,
          submitButton: false
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_UPDATE_REPORT_DATA_PLEASE_TRY_AGAIN_LATER'),
        onRetry: updateLotWiseData,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (value, index, field) => {
    setButtonDisabled(false)
    setCategories((prevCategories) => prevCategories.map((item, rowIndex) => (rowIndex === index ? { ...item, [field]: value } : item)))

    const updatedErrors = [...errors.categories]

    updatedErrors[index] = {
      ...updatedErrors[index],
      [field]: value.trim() === ''
    }

    setErrors({
      ...errors,
      categories: updatedErrors
    })
  }

  const handleInputChange = (fieldName, value) => {
    setButtonDisabled(false)

    setEditDetails((previousData) => ({
      ...previousData,
      [fieldName]: value
    }))

    const validationErrors = validateForm(fieldName, value)

    setErrors({ ...errors, ...validationErrors })
  }

  const validateForm = (fieldName, value) => {
    const newErrors = { ...errors }

    if (fieldName === 'date' || fieldName === 'openInterest' || fieldName === 'netChange' || fieldName === 'percentageOpenInterest' || fieldName === 'totalTraders') {
      newErrors[fieldName] = value.trim() === ''
    }

    return newErrors
  }

  const closeDialogModal = () => {
    setOpenModal(false)
    setButtonDisabled(true)
    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })
    setErrors({
      date: false,
      openInterest: false,
      netChange: false,
      percentageOpenInterest: false,
      totalTraders: false,
      categories: []
    })
  }

  const enableFormEditing = () => {
    setIsFormEnabled({
      editMode: true,
      submitButton: true
    })
  }

  const confirmResetForm = () => {
    setEditDetails({
      date: rowData?.date,
      openInterest: rowData?.openInterest,
      netChange: rowData?.netChange,
      percentageOpenInterest: rowData?.percentageOpenInterest,
      totalTraders: rowData?.totalTraders,
      contract: rowData?.contract
    })
    setCategories(categoryData?.data || [])

    setIsFormEnabled({
      editMode: false,
      submitButton: false
    })

    setErrors({
      date: false,
      openInterest: false,
      netChange: false,
      percentageOpenInterest: false,
      totalTraders: false,
      categories: []
    })
    setButtonDisabled(true)

    handleDismiss()
  }

  const showResetConfirmationDialog = () => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onConfirmation: confirmResetForm,
      onHide: handleDismiss
    })
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const fieldsEmpty = !editDetails.openInterest || !editDetails.netChange || !editDetails.percentageOpenInterest || !editDetails.totalTraders

  const isDisable = () => {
    const fieldsCheck = categories.some((item) => item.long === null || item.long === '' || item.short === null || item.short === '' || item.net === null || item.net === '' || item.percentLong === null || item.percentLong === '' || item.percentShort === null || item.percentShort === '' || item.total === null || item.total === '' || ((labelSelected === 'FUND BUYERS' || labelSelected === 'SWAP DEALERS' || labelSelected === 'OTHER REPORTABLE') && (item.spreading === null || item.spreading === '')))

    return fieldsCheck
  }

  const footerButton = () => (
    <div className="edit-weekly-wise-modal-footer">
      {isFormEnabled.submitButton && (
        <>
          <ButtonComponent type={'cancel'} onClick={closeDialogModal} />
          <ButtonComponent type={'submit'} disabled={buttonDisabled || fieldsEmpty || isDisable()} onClick={updateLotWiseData} />
        </>
      )}
    </div>
  )

  return (
    <>
      <Dialog visible={openModal} header={`${t('EDIT_WEEKLY_WISE_REPORT')} - ${selectedMarket?.marketName}`} onHide={closeDialogModal} footer={footerButton} className="weekly-wise-edit-modal">
        <div className="open-interest-container" data-testid="open-interest-container">
          <h3 className="open-interest-title">{t('OPEN_INTEREST')}</h3>
          <div className="action-button">{isFormEnabled.editMode ? <ButtonComponent type={'reset'} onClick={showResetConfirmationDialog} /> : <ButtonComponent type={'edit'} onClick={enableFormEditing} />}</div>
        </div>

        <div className="edit-form-container" data-testid="open-interest-edit-form">
          <div className="form-fields">
            <span className="p-float-label">
              <InputText
                id="date-field"
                value={editDetails.date}
                onChange={(event) => {
                  handleInputChange('date', event.target.value)
                }}
                className="input-fields"
                disabled
              />
              <label htmlFor="date-field" className="input-label">
                {t('DATE')}
                <span className="required-text-field">*</span>
              </label>
            </span>
          </div>
          <div className="form-fields">
            <span className="p-float-label">
              <InputText
                id="open-interest"
                keyfilter="num"
                value={editDetails.openInterest}
                onChange={(event) => {
                  handleInputChange('openInterest', event.target.value)
                }}
                className={`input-fields ${errors.openInterest ? 'error-fields' : ''}`}
                disabled={!isFormEnabled.editMode}
              />
              <label htmlFor="open-interest" className={`input-label ${errors.openInterest ? 'error-label' : ''}`}>
                {t('OPEN_INTEREST')}
                <span className="required-text-field">*</span>
              </label>
            </span>
            <div className="error-message"> {errors.openInterest && <small>{t('OPEN_INTEREST_ERROR_MESSAGE')}</small>}</div>
          </div>

          <div className="form-fields">
            <span className="p-float-label">
              <InputText
                id="net-change"
                keyfilter="num"
                value={editDetails.netChange}
                onChange={(event) => {
                  handleInputChange('netChange', event.target.value)
                }}
                className={`input-fields ${errors.netChange ? 'error-fields' : ''}`}
                disabled={!isFormEnabled.editMode}
              />
              <label htmlFor="net-change" className={`input-label ${errors.netChange ? 'error-label' : ''}`}>
                {t('NET_CHANGE')}
                <span className="required-text-field">*</span>
              </label>
            </span>
            <div className="error-message"> {errors.netChange && <small>{t('NET_CHANGE_ERROR_MESSAGE')}</small>}</div>
          </div>

          <div className="form-fields">
            <span className="p-float-label">
              <InputText
                id="percentage-open-interest"
                keyfilter="num"
                value={editDetails.percentageOpenInterest}
                onChange={(event) => {
                  handleInputChange('percentageOpenInterest', event.target.value)
                }}
                className={`input-fields ${errors.percentageOpenInterest ? 'error-fields' : ''}`}
                disabled={!isFormEnabled.editMode}
              />
              <label htmlFor="percentage-open-interest" className={`input-label ${errors.percentageOpenInterest ? 'error-label' : ''}`}>
                {t('PERCENTAGE_OPEN_INTEREST')}
                <span className="required-text-field">*</span>
              </label>
            </span>
            <div className="error-message"> {errors.percentageOpenInterest && <small>{t('PERCENTAGE_OPEN_INTEREST_IS_REQUIRED')}</small>}</div>
          </div>

          <div className="form-fields">
            <span className="p-float-label">
              <InputText
                id="total-traders"
                keyfilter="num"
                value={editDetails.totalTraders}
                onChange={(event) => {
                  handleInputChange('totalTraders', event.target.value)
                }}
                className={`input-fields ${errors.totalTraders ? 'error-fields' : ''}`}
                disabled={!isFormEnabled.editMode}
              />
              <label htmlFor="total-traders" className={`input-label ${errors.totalTraders ? 'error-label' : ''}`}>
                {t('TOTAL_TRADERS')}
                <span className="required-text-field">*</span>
              </label>
            </span>
            <div className="error-message"> {errors.totalTraders && <small>{t('TOTAL_TRADERS_IS_REQUIRED')}</small>}</div>
          </div>
        </div>

        <div className="category-form-container">
          <h3 className="form-title">{`${t('CATEGORY_TITLE')} - ${labelSelected}`}</h3>

          <>
            <Card className="form-card">
              {categories?.map((item, index) => (
                <div className="form-wrapper" key={index}>
                  <div className="form-fields">
                    <span className="p-float-label">
                      <InputText id={`long-${index}`} keyfilter="num" name="long" value={item.long} onChange={(event) => handleChange(event.target.value, index, 'long')} className={`input-fields ${errors.categories[index]?.long ? 'error-fields' : ''}`} disabled={!isFormEnabled.editMode} />
                      <label htmlFor={`long-${index}`} className={`input-label ${errors.categories[index]?.long ? 'error-label' : ''}`}>
                        {t('LONG')}
                        <span className="required-text-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{errors.categories[index]?.long && <small>{t('LONG_ERROR_MESSAGE')}</small>}</div>
                  </div>

                  <div className="form-fields">
                    <span className="p-float-label">
                      <InputText id={`short-${index}`} keyfilter="num" name="short" value={item.short} onChange={(event) => handleChange(event.target.value, index, 'short')} className={`input-fields ${errors.categories[index]?.short ? 'error-fields' : ''}`} disabled={!isFormEnabled.editMode} />
                      <label htmlFor={`short-${index}`} className={`input-label ${errors.categories[index]?.short ? 'error-label' : ''}`}>
                        {t('SHORT')}
                        <span className="required-text-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{errors.categories[index]?.short && <small>{t('SHORT_ERROR_MESSAGE')}</small>}</div>
                  </div>

                  <div className="form-fields">
                    <span className="p-float-label">
                      <InputText id={`percentLong-${index}`} keyfilter="num" name="percentLong" value={item.percentLong} onChange={(event) => handleChange(event.target.value, index, 'percentLong')} className={`input-fields ${errors.categories[index]?.percentLong ? 'error-fields' : ''}`} disabled={!isFormEnabled.editMode} />
                      <label htmlFor={`percentLong-${index}`} className={`input-label ${errors.categories[index]?.percentLong ? 'error-label' : ''}`}>
                        {t('PERCENT_LONG')}
                        <span className="required-text-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{errors.categories[index]?.percentLong && <small>{t('PERCENTAGE_LONG_ERROR_MESSAGE')}</small>}</div>
                  </div>

                  <div className="form-fields">
                    <span className="p-float-label">
                      <InputText id={`percentShort-${index}`} keyfilter="num" name="percentShort" value={item.percentShort} onChange={(event) => handleChange(event.target.value, index, 'percentShort')} className={`input-fields ${errors.categories[index]?.percentShort ? 'error-fields' : ''}`} disabled={!isFormEnabled.editMode} />
                      <label htmlFor={`percentShort-${index}`} className={`input-label ${errors.categories[index]?.percentShort ? 'error-label' : ''}`}>
                        {t('PERCENT_SHORT')}
                        <span className="required-text-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{errors.categories[index]?.percentShort && <small>{t('PERCENTAGE_SHORT_ERROR_MESSAGE')}</small>}</div>
                  </div>

                  <div className="form-fields">
                    <span className="p-float-label">
                      <InputText id={`net-${index}`} name="net" keyfilter="num" value={item.net} onChange={(event) => handleChange(event.target.value, index, 'net')} className={`input-fields ${errors.categories[index]?.net ? 'error-fields' : ''}`} disabled={!isFormEnabled.editMode} />
                      <label htmlFor={`net-${index}`} className={`input-label ${errors.categories[index]?.net ? 'error-label' : ''}`}>
                        {t('NET')}
                        <span className="required-text-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{errors.categories[index]?.net && <small>{t('NET_ERROR_MESSAGE')}</small>}</div>
                  </div>

                  <div className="form-fields">
                    <span className="p-float-label">
                      <InputText id={`total-${index}`} name="total" keyfilter="num" value={item.total} onChange={(event) => handleChange(event.target.value, index, 'total')} className={`input-fields ${errors.categories[index]?.total ? 'error-fields' : ''}`} disabled={!isFormEnabled.editMode} />
                      <label htmlFor={`total-${index}`} className={`input-label ${errors.categories[index]?.total ? 'error-label' : ''}`}>
                        {t('TOTAL')}
                        <span className="required-text-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{errors.categories[index]?.total && <small>{t('TOTAL_ERROR_MESSAGE')}</small>}</div>
                  </div>

                  {(labelSelected === 'FUND BUYERS' || labelSelected === 'SWAP DEALERS' || labelSelected === 'OTHER REPORTABLE') && (
                    <div className="form-fields">
                      <span className="p-float-label">
                        <InputText id={`spreading-${index}`} keyfilter="num" name="spreading" value={item.spreading} onChange={(event) => handleChange(event.target.value, index, 'spreading')} className={`input-fields ${errors.categories[index]?.spreading ? 'error-fields' : ''}`} disabled={!isFormEnabled.editMode} />
                        <label htmlFor={`spreading-${index}`} className={`input-label ${errors.categories[index]?.spreading ? 'error-label' : ''}`}>
                          {t('SPREADING')}
                          <span className="required-text-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{errors.categories[index]?.spreading && <small>{t('SPREADING_ERROR_MESSAGE')}</small>}</div>
                    </div>
                  )}
                </div>
              ))}
            </Card>
          </>
        </div>
      </Dialog>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </>
  )
}

export default Loader(EditWeeklyWiseReport)
