import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import { Dialog } from 'primereact/dialog'
import { MultiSelect } from 'primereact/multiselect'
import { Image } from 'primereact/image'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import Loader from 'Components/Loader'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ClientMenu = ({ setLoading, handleAddMenu, MockClientMenuList }) => {
  const history = useHistory()
  const { t } = useTranslation()
  let rowCount = 8
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const location = useLocation()

  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const [clientMenuList, setClientMenuList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })
  const [showMultiSelectDialog, setShowMultiSelectDialog] = useState(false)
  const [country, setCountry] = useState([])
  const [selectedCountries, setSelectedCountries] = useState(null)
  const [mappedCountries, setMappedCountries] = useState(null)
  const [menuDetails, setMenuDetails] = useState({
    menuName: '',
    menuId: 0,
    countryBasedEnabled: true
  })

  const { menuName, menuId, countryBasedEnabled } = menuDetails

  useEffect(() => {
    init()
    // For testing purposes, use a mock Client menu list if available
    if (MockClientMenuList) {
      setClientMenuList(MockClientMenuList)
      setShowMultiSelectDialog(true)
    }
  }, [])

  const init = () => {
    getMenuGroups()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const getMenuGroups = async () => {
    setShowDialog({ ...showDialog, showModal: false })
    setLoading(true)
    setShowTableMessage(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAllMenu()
      const filteredMenuList = response.data.filter((menu) => menu.isadminConsoleMenu === false)

      setClientMenuList(filteredMenuList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => init(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const toggleGuestMenuDisplay = async (item, field) => {
    const payload = {
      id: item?.id,
      isFreeMenu: true,
      isFreeMenuWebDisplayEnable: item?.isFreeMenuWebDisplayEnable,
      isFreeMenuMobDisplayEnable: item?.isFreeMenuMobDisplayEnable,
      [field]: !item[field]
    }

    setLoading(true)
    try {
      await apiAdapterCoffeeWeb.activateDeactivateFreeMenu(payload)

      updateClientList(item, field)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('GUEST_MENU_TOGGLE_FAILED'),
        onRetry: () => toggleGuestMenuDisplay(item, field),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const putWebMaster = async (item, field) => {
    handleDismiss()
    setLoading(true)

    try {
      if (!item.isWebDisplayEnable) {
        await apiAdapterCoffeeWeb.activateMenuForWeb(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateMenuForWeb(item.id)
      }

      updateClientList(item, field)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => putWebMaster(item, field),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const putMobileMaster = async (item, field) => {
    handleDismiss()
    setLoading(true)
    try {
      if (!item.isMobDisplayEnable) {
        await apiAdapterCoffeeWeb.activateMenuForMobile(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateMenuForMobile(item.id)
      }

      updateClientList(item, field)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => putMobileMaster(item, field),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const putIsLaunch = async (item, field) => {
    handleDismiss()
    setLoading(true)
    try {
      if (!item.isLaunch) {
        await apiAdapterCoffeeWeb.activateMenuLaunch(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateMenuLaunch(item.id)
      }

      updateClientList(item, field)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => putIsLaunch(item, field),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const putActiveMaster = async (item, field) => {
    handleDismiss()
    setLoading(true)
    try {
      if (!item.isActive) {
        await apiAdapterCoffeeWeb.activeMenu(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateMenu(item.id)
      }

      updateClientList(item, field)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => putActiveMaster(item, field),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const toggleCountryBasedStatus = async (item, field) => {
    setLoading(true)
    try {
      await apiAdapterCoffeeWeb.activateDeactivateCountryBasedMenu(item?.id, !item?.isCountryBase)

      updateClientList(item, field)
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => toggleCountryBasedStatus(item, field),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const postCountriesMenuMappedData = async (selectedCountries) => {
    setLoading(true)

    try {
      const payload = selectedCountries.map((ele) => ({
        countryId: ele?.countryId,
        isActive: true,
        menuId
      }))

      const inactiveCountries = mappedCountries.filter((mapped) => !selectedCountries.find((selected) => selected.countryId === mapped.countryId))

      const updatedPayload = [
        ...payload,
        ...inactiveCountries.map((inactive) => ({
          countryId: inactive.countryId,
          isActive: false,
          menuId
        }))
      ]

      await apiAdapterCoffeeWeb.mapCountriesToMenu(updatedPayload)
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('MENU_COUNTRY_MAPPED_DATA_UPDATE_FAILED'),
        onRetry: () => postCountriesMenuMappedData(selectedCountries),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setShowMultiSelectDialog(false)
    }
  }

  const updateClientList = (item, field) => {
    const updatedList = clientMenuList.map((element) => {
      if (element.id === item.id) {
        return {
          ...element,
          [field]: !item[field]
        }
      }

      return element
    })

    setMenuDetails((prev) => ({ ...prev, countryBasedEnabled: !item?.isCountryBase }))
    setClientMenuList(updatedList)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleEditMenuGroup = (item) => {
    const newTab = tab || 'admin_menu'

    params.set('tab', newTab)

    history.push({ pathname: `${ROUTE_STRINGS.editmenugroup}/${item.id}`, search: params.toString() })
  }

  const handleToggle = async (item, field) => {
    handleDismiss()
    const toggleFunction = toggleFunctions[field]

    if (toggleFunction) {
      await toggleFunction(item, field)
    }
  }

  const handleMultiSelectDismiss = () => {
    setShowMultiSelectDialog(false)
  }

  const handleMultiSelectSubmit = () => {
    postCountriesMenuMappedData(selectedCountries)
  }

  const handleSelectedCountries = (e) => {
    setSelectedCountries(e.value)
  }

  const configureActions = (rowData) => (
    <div className="action-container">
      <div>
        <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditMenuGroup(rowData)} data-testId="edit-button" />
      </div>
      <div>
        <Button type="button" icon="pi pi-cog" className="config-button" tooltip={t('CONFIG')} tooltipOptions={{ position: 'top' }} onClick={() => handleConfig(rowData)} data-testId="config-button" />
      </div>
    </div>
  )

  const handleConfig = (rowData) => {
    setLoading(true)
    fetchCountryMappedMenu(rowData?.id)
    setMenuDetails((prev) => ({ ...prev, menuId: rowData?.id, menuName: rowData?.menuName, countryBasedEnabled: rowData?.isCountryBase }))
  }

  const fetchCountryMappedMenu = async (menuId) => {
    try {
      const {
        data: { returnLst: countriesMappedMenuData }
      } = await apiAdapterCoffeeWeb.getAllCountryMenuMapped(menuId)

      const mappedCountriesResponse = countriesMappedMenuData.filter((el) => el.isMapped)

      setCountry(countriesMappedMenuData)
      setMappedCountries(mappedCountriesResponse)
      setSelectedCountries(mappedCountriesResponse)
      setShowMultiSelectDialog(true)
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('MENU_COUNTRY_MAPPED_DATA_LOAD_FAILED'),
        onRetry: () => fetchCountryMappedMenu(menuId),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const toggleFunctions = {
    isFreeMenuWebDisplayEnable: toggleGuestMenuDisplay,
    isFreeMenuMobDisplayEnable: toggleGuestMenuDisplay,
    isWebDisplayEnable: putWebMaster,
    isMobDisplayEnable: putMobileMaster,
    isLaunch: putIsLaunch,
    isActive: putActiveMaster,
    isCountryBase: toggleCountryBasedStatus
  }

  const columns = [
    {
      field: 'menuName',
      header: t('MENU_NAME'),
      className: 'menu-name'
    },
    {
      body: (item) => renderToggleSwitch(item, 'isFreeMenuWebDisplayEnable'),
      header: t('Guest'),
      className: 'guest-display'
    },
    {
      body: (item) => renderToggleSwitch(item, 'isWebDisplayEnable'),
      header: t('User'),
      className: 'web-display'
    },
    {
      body: (item) => renderToggleSwitch(item, 'isCountryBase'),
      header: t('COUNTRY_BASED_MENU'),
      className: 'country-based'
    },
    {
      body: (item) => renderToggleSwitch(item, 'isLaunch'),
      header: t('IS_LAUNCH'),
      className: 'is-launch'
    },
    {
      body: (item) => renderToggleSwitch(item, 'isActive'),
      header: t('IS_ACTIVE'),
      className: 'is-active'
    },
    {
      body: configureActions,
      header: t('ACTIONS'),
      className: 'action-column'
    }
  ]

  const filteredClientMenuList = clientMenuList.filter((menu) => menu.menuName.toLowerCase().includes(searchValue.toLowerCase()))

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }

  const countryFlag = (option) => (
    <div className="country-flag">
      <Image alt={option.iso} src={option.flagsUrl} />
      <div className="add-news-country-name">{option.countryName}</div>
    </div>
  )

  const renderToggleSwitch = (item, field) => (
    <div className="toggle-switch-menu">
      <InputSwitch checked={item?.[field]} onChange={() => handleToggle(item, field)} />
    </div>
  )

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="client-menu-wrapper">
      <div className="client-menu-table-container">
        <div className="search-container">
          <div className="search-field-wrapper">
            <span className="p-input-icon-left">
              <i className="pi pi-search" data-testId="search-icon" />
              <InputText placeholder={t('SEARCH_MENU')} className="search-field" value={searchValue} onChange={handleSearch} />
            </span>
          </div>

          <div className="add-button">
            <ButtonComponent type="add" onClick={handleAddMenu} />
          </div>
        </div>

        <DataTable value={filteredClientMenuList} paginator={filteredClientMenuList.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="client-menu-table">
          {columns.map((column, index) => (
            <Column key={index} field={column.field} header={column.header} body={column.body} className={column.className} />
          ))}
        </DataTable>
      </div>
      <Dialog visible={showMultiSelectDialog} header="Map Countries" onHide={() => handleMultiSelectDismiss()} className="dialog-component multi-select">
        <div className="dialog-message">{countryBasedEnabled ? t('SELECT_COUNTRIES_TO_BE_MAPPED_TO_MENUS', { menuName }) : t('ENABLE_COUNTRY_BASED_TO_MAP_FOR', { menuName })}</div>
        <MultiSelect value={selectedCountries} name="country-multi-select" onChange={(e) => handleSelectedCountries(e)} options={country} itemTemplate={countryFlag} optionLabel="countryName" filter placeholder={t('COUNTRY')} maxSelectedLabels={3} disabled={!countryBasedEnabled} data-testId="countryDropdown" />
        <div className="dialog-component-footer">
          <ButtonComponent type="cancel" onClick={() => handleMultiSelectDismiss()} />
          <ButtonComponent type="submit" onClick={() => handleMultiSelectSubmit()} disabled={!countryBasedEnabled} />
        </div>
      </Dialog>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(ClientMenu)
