const REG_EX = {
  ONLY_NUMBER_REG_EX: /^[0-9]/,
  ALPHANUMERIC_WITHOUT_SPACES: /^[a-zA-Z0-9\-_]{0,40}$/,
  NAME_REG_EX: /^[a-zA-Z ]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$/,
  USER_NAME_REG_EX: /^[A-Za-z\s]+$/,
  PASSWORD_REG_EX: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
  PHONE_REG_EX: /^[0-9][0-9]*$/,
  EMAIL_REG_EX: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  LEADING_SPACES: /^\s*/,
  NUMBER_DOTS_REG_EX: /^[0-9.]+$/,
  LANGUAGE_REG_EX: /^[^0-9]*$/,
  URL_REG_EX: /^[\p{L}\s\p{P}]+$/u,
  PLAIN_DATA_REG_EX: /<(?!\/?(a|\/a))[^>]*>|&nbsp;/gi,
  TINY_REG: /<(?!\/?(a|\/a))[^>]*>|&nbsp;/gi,
  COLOR_PATTERN: /^#(?:[A-Fa-f0-9]{6}(?:,\s*#?[A-Fa-f0-9]{6})*,?\s*)+$/,
  PHONE_SPECIAL_CHAR: /^[0-9!@#$%^&*()_+{}[\]:;<>,.?~/\\-]+$/,
  NUMBER_WITH_REGEX: /^[\d]{7,15}$/,
  TEXT_WITHOUT_BOLD_TAG: /<b>(.*?)<\/b>/,
  REMOVE_HTML_TAG: /<[^>]*>?/gm,
  CHECK_ONLY_NUMBER: /^\d+$/,
  NUMBER_DOT_PLUS_AND_MINUS_REG_EX: /^[-+]?([0-9]*\.?[0-9]*)$/
}

export default REG_EX
