import './styles.scss'
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import * as XLSX from 'xlsx'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { Column } from 'primereact/column'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { TABLE_CONST } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { CoffeeWebLogoPNG, WaterMarkImageForInvoice } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import ReconcileForm from './ReconcileForm'
import MissingPaymentReport from './MissingPaymentReport'

const PaymentReports = ({ setLoading, mockFilterValue, mockShowModal }) => {
  const { t } = useTranslation()

  let rowCount = 5
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const isMobileScreen = () => window.innerWidth < TABLE_CONST.maxWidthTab

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [allPaymentReports, setAllPaymentReports] = useState([])
  const [filteredPaymentData, setFilteredPaymentData] = useState([])
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([])
  const [filterValue, setFilterValue] = useState([])
  const [invoiceNumberSearch, setInvoiceNumberSearch] = useState('')
  const [amountSearch, setAmountSearch] = useState('')
  const [selectedPaymentSource, setSelectedPaymentSource] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [searchedValue, setSearchedValue] = useState('')
  const [selectedValue, setSelectedValue] = useState('')
  const [invoiceContent, setInvoiceContent] = useState('')
  const [dates, setDates] = useState(null)
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [downloadReportsModal, setDownloadReportsModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onConfirmation: () => {} })
  const [isMissingReportModalOpen, setIsMissingReportModalOpen] = useState(false)
  const [paymentSourceOptions, setPaymentSourceOptions] = useState([])
  const [paymentStatusOptions, setPaymentStatusOptions] = useState([])
  const [subscriptionOptions, setSubscriptionOptions] = useState([])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  useEffect(() => {
    getAllPaymentReportDetails()
    getSubscriptionName()
    // Testing Purpose
    if (mockFilterValue) {
      setFilterValue(mockFilterValue)
      setShowModal(mockShowModal)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    applyFilters()
  }, [filterValue, searchedValue, selectedSubscriptions, invoiceNumberSearch, amountSearch, selectedValue, selectedPaymentSource, paymentId, dates])

  const getAllPaymentReportDetails = async () => {
    setShowDialog({ ...showDialog, showModal: false })

    setLoading(true)
    setShowTableMessage(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAllPaymentReport()

      const paymentDateResponse = response?.data?.returnLst
      const modifiedData = paymentDateResponse.map((item) => {
        const paymentDate = commonMethods.utcToLocalTime(item.paymentDate)

        return {
          ...item,
          paymentDate
        }
      })

      setPaymentStatusOptions([...new Set(modifiedData.map((paymentStatus) => paymentStatus.paymentStatus))])
      setPaymentSourceOptions([...new Set(modifiedData.map((paymentSource) => paymentSource.paymentSource))])
      setAllPaymentReports(modifiedData)
      setFilteredPaymentData(modifiedData)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => getAllPaymentReportDetails(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const getInvoiceNumberContent = async (invoiceNumber) => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getInvoiceNumberContent(invoiceNumber)
      const content = response?.data?.returnLst

      if (content) {
        const tempDiv = document.createElement('div')

        tempDiv.innerHTML = content

        const bannerImage = tempDiv.querySelector('.banner-image')

        if (bannerImage) {
          bannerImage.style.transform = 'rotate(-7deg)'
          bannerImage.style.opacity = '0.1'
        }

        const container = tempDiv.querySelector('.container')

        container.style.width = '90%'
        container.style.margin = '10 auto'
        container.style.backgroundImage = `url(${WaterMarkImageForInvoice})`
        container.style.backgroundSize = '255px'

        const updatedContent = tempDiv.innerHTML.replace(/<img[^>]+src="([^">]+)"/g, (match) => match.replace(/src="[^">]+"/, `src="${CoffeeWebLogoPNG}"`)).replace(/rotate:\s*-?\d+deg;/g, '')

        if (isMobileScreen()) {
          downloadInvoice(updatedContent)
        } else {
          setInvoiceContent(updatedContent)
          setShowModal(true)
        }
      }
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('INVOICE_GENERATION_FAILED'),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const getSubscriptionName = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getSubscriptionNameList()
      const { returnLst } = response.data

      setSubscriptionOptions(returnLst)
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER'),
        onHide: handleDismiss
      })
    }
  }

  const downloadInvoice = async (content) => {
    const clonedElement = document.createElement('div')
    const isMobile = window.innerWidth <= 768

    clonedElement.style.position = 'absolute'
    clonedElement.style.top = '-9999px'
    clonedElement.style.left = '0'

    if (isMobile) {
      clonedElement.style.width = '100vw'
      clonedElement.style.padding = '0'
      clonedElement.style.margin = '0'
      clonedElement.style.boxSizing = 'border-box'
      clonedElement.style.maxWidth = '100%'
      clonedElement.style.transformOrigin = 'top left'
    }
    clonedElement.innerHTML = content
    document.body.appendChild(clonedElement)

    const images = clonedElement.getElementsByTagName('img')
    const imageLoadPromises = Array.from(images).map(
      (img) =>
        new Promise((resolve) => {
          // eslint-disable-next-line no-param-reassign
          img.onload = resolve
          // eslint-disable-next-line no-param-reassign
          img.onerror = resolve
          if (img.complete) resolve()
        })
    )

    await Promise.all(imageLoadPromises)

    try {
      const scale = isMobile ? 2 : 2.5

      const canvas = await html2canvas(clonedElement, {
        scale,
        width: clonedElement.scrollWidth,
        height: clonedElement.scrollHeight,
        windowWidth: clonedElement.scrollWidth,
        windowHeight: clonedElement.scrollHeight
      })

      const imgData = canvas.toDataURL('image/png')

      const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' })

      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)

      pdf.save('invoice.pdf')
    } catch (err) {
      console.error('Error generating PDF:', err)
    } finally {
      document.body.removeChild(clonedElement)
    }
  }

  const exportToExcel = () => {
    const dataToExport = isFilterApplied ? allPaymentReports : filteredPaymentData

    if (dataToExport?.length > 0) {
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(dataToExport)

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Payment Reports')

      const fileName = 'PaymentReports.xlsx'

      XLSX.writeFile(workbook, fileName)
    }

    setDownloadReportsModal({ ...downloadReportsModal, showModal: false })
  }

  const applyFilters = () => {
    let filteredData = [...filteredPaymentData]
    let isAnyFilterApplied = false

    const filterFunctions = [
      {
        condition: () => searchedValue && filterValue.includes('Order Id'),
        filter: (item) => item.idOrder?.toString().toLowerCase().includes(searchedValue.toLowerCase())
      },
      {
        condition: () => invoiceNumberSearch && filterValue.includes('Invoice Number'),
        filter: (item) => (item.invoiceNumber?.toLowerCase() || '').includes(invoiceNumberSearch.toLowerCase())
      },
      {
        condition: () => paymentId && filterValue.includes('Payment ID'),
        filter: (item) => (item.transactionId?.toLowerCase() || '').includes(paymentId.toLowerCase())
      },
      {
        condition: () => filterValue.includes('Subscription Name') && selectedSubscriptions.length,
        filter: ({ subName }) => selectedSubscriptions.includes(subName)
      },
      {
        condition: () => amountSearch && filterValue.includes('Invoice Amount'),
        filter: (item) => (item.invoiceAmount?.toString() || '').includes(amountSearch)
      },
      {
        condition: () => selectedValue,
        filter: ({ paymentStatus }) => paymentStatus.toLowerCase() === selectedValue.toLowerCase()
      },
      {
        condition: () => selectedPaymentSource,
        filter: ({ paymentSource }) => paymentSource.toLowerCase() === selectedPaymentSource.toLowerCase()
      },

      {
        condition: () => dates && dates.length === 2,
        filter: (item) => {
          const paymentDate = commonMethods.parseCustomDateFormat(item.paymentDate)
          const [startDate, endDate] = dates
          const adjustedEndDate = new Date(endDate)

          adjustedEndDate.setHours(23, 59, 59, 999)

          return paymentDate >= startDate && paymentDate <= adjustedEndDate
        }
      }
    ]

    filterFunctions.forEach(({ condition, filter }) => {
      if (condition()) {
        filteredData = filteredData.filter(filter)
        setIsFilterApplied(true)
        isAnyFilterApplied = true
      }
    })

    setAllPaymentReports(filteredData)
    setIsFilterApplied(isAnyFilterApplied)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleSearch = (event) => {
    const searchTerm = event?.target?.value

    setSearchedValue(searchTerm)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleDismissForDownloadReports = () => {
    setDownloadReportsModal({ ...downloadReportsModal, showModal: false })
  }

  const handleChange = (e) => {
    setFilterValue(e.value)
  }

  const handleDownloadReport = () => {
    if (isFilterApplied) setDownloadReportsModal({ showModal: true, modalType: 'info', message: t('DO_YOU_WANT_TO_DOWNLOAD_CURRENT_REPORTS'), onHide: handleDismissForDownloadReports, onConfirmation: exportToExcel })
    else exportToExcel()
  }

  const handleAction = (rowData) => <Button type="button" icon="pi pi-book" className="pi-edit-icon" onClick={() => handleClick(rowData)} />

  const handleClick = (item) => {
    setSelectedRowData(item)
    setVisible(true)
  }

  const handleReconcileSuccess = (reconciledRowId) => {
    setAllPaymentReports((prevData) => prevData.filter((item) => item.idOrder !== reconciledRowId))
    setFilteredPaymentData((prevData) => prevData.filter((item) => item.idOrder !== reconciledRowId))
  }

  const clearFilter = (filter) => {
    setFilterValue((prev) => prev.filter((item) => item !== filter))

    const actions = {
      'Order Id': () => setSearchedValue(''),
      'Invoice Number': () => setInvoiceNumberSearch(''),
      'Subscription Name': () => setSelectedSubscriptions([]),
      'Invoice Amount': () => setAmountSearch(''),
      'Payment Status': () => setSelectedValue(null),
      'Payment Source': () => setSelectedPaymentSource(null),
      'Payment ID': () => setPaymentId(''),
      'Payment Date': () => setDates(null)
    }

    actions[filter]?.()
  }

  const invoiceNumberBody = (rowData) => (
    <span onClick={() => getInvoiceNumberContent(rowData.invoiceNumber)} className="invoice-number-clickable">
      {rowData.invoiceNumber}
    </span>
  )

  const columns = [
    { dataField: 'idOrder', text: t('ORDER_ID'), className: 'order-id-column' },
    { dataField: 'invoiceNumber', text: t('INVOICE_NUMBER'), className: 'invoice-number-column', body: invoiceNumberBody },
    { dataField: 'subName', text: t('SUBSCRIPTION_ID'), className: 'subscription-name-column' },
    { dataField: 'invoiceAmount', text: t('INVOICE_AMOUNT'), className: 'total-amount-column' },
    { dataField: 'paymentStatus', text: t('PAYMENT_STATUS'), className: 'payment-status-column' },
    { dataField: 'paymentSource', text: t('PAYMENT_SOURCE'), className: 'payment-source-column' },
    { dataField: 'paymentDate', text: t('PAYMENT_DATE'), className: 'payment-date-column' },
    { dataField: 'transactionId', text: t('PAYMENT_ID'), className: 'payment-id-column' },
    { body: handleAction, text: t('RECONCILE'), className: 'reconcile-column' }
  ]

  const dropdownOptions = columns
    .filter((column) => column.text !== t('RECONCILE'))
    .map((column) => ({
      value: column.text,
      label: column.text
    }))

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  const headerElement = (
    <div className="invoice-header-container">
      <span className="font-bold white-space-nowrap">{t('INVOICE')}</span>
      <div className="invoice-button">
        <ButtonComponent type="Custom" label={t('DOWNLOAD_INVOICE')} variant="action" icon="pi pi-arrow-down" onClick={() => downloadInvoice(invoiceContent)} />
      </div>
    </div>
  )

  return (
    <div className="payment-report-container">
      <LayoutContainer title={'PAYMENT_REPORTS'} breadCrumbsTitle={'paymentReport'} showPaymentDownloadButton={true} setDisplayButton={handleDownloadReport} handleModalOpen={() => setIsMissingReportModalOpen(true)}>
        <div className="payment-report-table-wrapper">
          <div className="search-input-container">
            <div className="filter-container">
              <div className="multiselect-container">
                <MultiSelect value={filterValue} onChange={handleChange} options={dropdownOptions} placeholder={t('FILTER')} maxSelectedLabels={1} className="drop-down-field" panelClassName="payment-dropdown-panel" showSelectAll={false} />
              </div>

              {filterValue.includes('Order Id') ? (
                <div className="drop-down-wrapper">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t('ORDER_ID')} className="search-input-field" value={searchedValue} onChange={handleSearch} />
                  </span>
                  <div className="field-icon" onClick={() => clearFilter('Order Id')}>
                    <span className="pi pi-times"></span>
                  </div>
                </div>
              ) : null}
              {filterValue.includes('Invoice Number') ? (
                <div className="drop-down-wrapper">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t('INVOICE_NUMBER')} className="search-input-field" value={invoiceNumberSearch} onChange={(e) => setInvoiceNumberSearch(e.target.value)} />
                  </span>
                  <div className="field-icon" onClick={() => clearFilter('Invoice Number')}>
                    <span className="pi pi-times"></span>
                  </div>
                </div>
              ) : null}

              {filterValue.includes('Subscription Name') ? (
                <div className="drop-down-wrapper">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <MultiSelect placeholder={t('SUBSCRIPTION_NAME')} value={selectedSubscriptions} maxSelectedLabels={1} options={subscriptionOptions} className="subscription-multi-select" panelClassName="payment-dropdown-panel" onChange={(e) => setSelectedSubscriptions(e.value)} showSelectAll={false} />
                  </span>
                  <div className="field-icon" onClick={() => clearFilter('Subscription Name')}>
                    <span className="pi pi-times"></span>
                  </div>
                </div>
              ) : null}

              {filterValue.includes('Invoice Amount') ? (
                <div className="drop-down-wrapper">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t('INVOICE_AMOUNT')} className="search-input-field" value={amountSearch} onChange={(e) => setAmountSearch(e.target.value)} />
                  </span>
                  <div className="field-icon" onClick={() => clearFilter('Invoice Amount')}>
                    <span className="pi pi-times"></span>
                  </div>
                </div>
              ) : null}

              {filterValue.includes('Payment Status') && (
                <div className="drop-down-wrapper">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <Dropdown value={selectedValue} options={paymentStatusOptions} className="payment-status-dropdown" onChange={(e) => setSelectedValue(e.value)} placeholder={t('FILTER_STATUS')} />
                  </span>
                  <div className="field-icon" onClick={() => clearFilter('Payment Status')}>
                    <span className="pi pi-times"></span>
                  </div>
                </div>
              )}

              {filterValue.includes('Payment Source') && (
                <div className="drop-down-wrapper">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <Dropdown value={selectedPaymentSource} options={paymentSourceOptions} className="payment-status-dropdown" onChange={(e) => setSelectedPaymentSource(e.value)} placeholder={t('FILTER_BY_PAYMENT_SOURCE')} />
                  </span>
                  <div className="field-icon" onClick={() => clearFilter('Payment Source')}>
                    <span className="pi pi-times"></span>
                  </div>
                </div>
              )}

              {filterValue.includes('Payment ID') ? (
                <div className="drop-down-wrapper">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText placeholder={t('PAYMENT_ID')} className="search-input-field" value={paymentId} onChange={(e) => setPaymentId(e.target.value)} />
                  </span>
                  <div className="field-icon" onClick={() => clearFilter('Payment ID')}>
                    <span className="pi pi-times"></span>
                  </div>
                </div>
              ) : null}

              {filterValue.includes('Payment Date') && (
                <>
                  <div className="drop-down-wrapper">
                    <span>
                      <Calendar value={dates} selectionMode="range" readOnlyInput hideOnRangeSelection placeholder={t('PAYMENT_DATE')} onChange={(e) => setDates(e.value)} />
                    </span>
                    <div className="field-icon" onClick={() => clearFilter('Payment Date')}>
                      <span className="pi pi-times"></span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <DataTable value={allPaymentReports} paginator={allPaymentReports.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="payment-report-table">
            {columns.map((column, index) => (
              <Column key={index} field={column.dataField} header={column.text} className={column.className} body={column.body} sortable={column.sortable} />
            ))}
          </DataTable>
        </div>
      </LayoutContainer>
      {selectedRowData && <ReconcileForm visible={visible} setVisible={setVisible} selectedRowData={selectedRowData} onReconcileSuccess={handleReconcileSuccess} />}
      <MissingPaymentReport setVisible={setIsMissingReportModalOpen} visible={isMissingReportModalOpen} subscriptionOptions={subscriptionOptions} />
      <Dialog header={headerElement} visible={showModal} className="invoice-dialog-modal" onHide={() => setShowModal(false)}>
        <div data-testid="invoice-content">{ReactHtmlParser(invoiceContent)}</div>
      </Dialog>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
      <DialogModal {...downloadReportsModal} onDismiss={handleDismissForDownloadReports} />
    </div>
  )
}

export default Loader(PaymentReports)
