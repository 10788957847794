import './styles.scss'
import React, { useState, useEffect, useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useTranslation } from 'react-i18next'
import { Messages } from 'primereact/messages'
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { Checkbox } from 'primereact/checkbox'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { TABLE_CONST } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import { CoffeeWebLogoPNG, WaterMarkImageForInvoice } from 'Assets/Icons'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { getLocalReconcileTableColumns, setLocalWithReconcileTableColumns } from 'Utils/LocalStorageHandler'

const ReconciledReports = ({ setLoading, mockFilterValue, mockShowModal }) => {
  const { t } = useTranslation()
  const msgs = useRef(null)
  const isMobileScreen = () => window.innerWidth < TABLE_CONST.maxWidthTab

  const invoiceNumberBody = (rowData) => (
    <span onClick={() => getInvoiceNumberContent(rowData.invoiceNumber)} className="invoice-number-clickable">
      {rowData.invoiceNumber}
    </span>
  )

  const columns = [
    { dataField: 'idOrder', text: t('ORDER_ID'), className: 'order-id-column' },
    { dataField: 'invoiceNumber', text: t('INVOICE_NUMBER'), className: 'invoice-number-column', body: invoiceNumberBody },
    { dataField: 'subscriptionName', text: t('SUBSCRIPTION_ID'), className: 'subscription-name-column' },
    { dataField: 'invoiceAmount', text: t('INVOICE_AMOUNT'), className: 'total-amount-column' },
    { dataField: 'paymentStatus', text: t('PAYMENT_STATUS'), className: 'payment-status-column' },
    { dataField: 'paymentSource', text: t('PAYMENT_SOURCE'), className: 'payment-source-column' },
    { dataField: 'paymentId', text: t('PAYMENT_ID'), className: 'payment-id-column' },
    { dataField: 'paymentDate', text: t('PAYMENT_DATE'), className: 'payment-date-column' },
    { dataField: 'tallyDate', text: t('TALLY_DATE'), className: 'tally-date-column' },
    { dataField: 'utrNo', text: t('UTR_NO'), className: 'utr-no-column' },
    { dataField: 'settlementNo', text: t('SETTLEMENT_NO'), className: 'settlement-no-column' },
    { dataField: 'transactionDate', text: t('TRANSACTION_DATE'), className: 'transaction-date-column' },
    { dataField: 'grossAmount', text: t('GROSS_AMOUNT'), className: 'gross-amount-column' },
    { dataField: 'deductions', text: t('DEDUCTIONS'), className: 'deductions-column' },
    { dataField: 'netAmount', text: t('NET_AMOUNT'), className: 'net-amount-column' }
  ]

  const defaultColumnsVisibility = {
    idOrder: true,
    invoiceNumber: true,
    subscriptionName: true,
    invoiceAmount: true,
    paymentStatus: true,
    paymentSource: true,
    paymentId: true,
    paymentDate: true,
    tallyDate: true,
    utrNo: true,
    settlementNo: true,
    transactionDate: true,
    grossAmount: true,
    deductions: true,
    netAmount: true
  }

  let activeCheckboxCount = 0

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [getReconciledReports, setGetReconciledReports] = useState([])
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [visible, setVisible] = useState(false)
  const [activeCheckBox, setActiveCheckBox] = useState(getLocalReconcileTableColumns() || defaultColumnsVisibility)
  const [disableButton, setDisableButton] = useState(false)
  const [filterValue, setFilterValue] = useState([])
  const [invoiceContent, setInvoiceContent] = useState('')
  const [filters, setFilters] = useState({
    invoiceNumber: '',
    paymentId: '',
    utrNo: '',
    settlementNo: '',
    transactionDate: null
  })
  const [dialogState, setDialogState] = useState({ showModal: false, modalType: 'error', message: '', onHide: () => handleDismiss(), onRetry: () => handleRetry(), onDismiss: () => handleDismiss() })
  const [errorModal, setErrorModal] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [showModal, setShowModal] = useState(false)

  const availableColumns = getLocalReconcileTableColumns() || activeCheckBox
  const rowCount = windowSize < TABLE_CONST.maxWidthTab ? TABLE_CONST.mobileRowCount : 5
  const paginationButtons = windowSize < TABLE_CONST.maxWidthTab ? TABLE_CONST.mobilePaginationButtonCount : TABLE_CONST.paginationButtonCount

  useEffect(() => {
    fetchReconciledReportData()

    // Testing Purpose
    if (mockFilterValue) {
      setFilterValue(mockFilterValue)
      setShowModal(mockShowModal)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    const sidebar = document.querySelector('.layout-sidebar')
    const tableWrapper = document.querySelector('.reconciled-report-table-wrapper')

    if (sidebar && tableWrapper) {
      // Observer to monitor changes in className of sidebar
      const observer = new MutationObserver(() => {
        const inactiveClass = sidebar.classList.contains('inactive')

        tableWrapper.style.width = inactiveClass ? ' calc(100vw - 145px)' : 'calc(100vw - 320px)'
      })

      // Observe the class attribute changes on the sidebar
      observer.observe(sidebar, { attributes: true, attributeFilter: ['class'] })

      return () => observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (msgs.current) {
      msgs.current.clear()
      msgs.current.show([{ sticky: true, severity: 'info', summary: 'Info : ', detail: t('YOU_NEED_TO_SELECT_MINIMUM_OF_4_COLUMNS'), closable: false }])
    }
  }, [disableButton])

  const fetchReconciledReportData = async () => {
    setLoading(true)
    setShowTableMessage(true)

    if (!getLocalReconcileTableColumns()) {
      setLocalWithReconcileTableColumns(availableColumns)
    }

    try {
      const response = await apiAdapterCoffeeWeb.getReconciledReport()

      const paymentDateResponse = response?.data?.returnLst

      if (paymentDateResponse?.length) {
        const modifiedData = paymentDateResponse.map((item) => {
          const paymentDate = commonMethods.utcToLocalTime(item.paymentDate)
          const transactionDate = commonMethods.utcToLocalTime(item.transactionDate)
          const tallyDate = commonMethods.utcToLocalTime(item.tallyDate)

          return {
            ...item,
            paymentDate,
            transactionDate,
            tallyDate
          }
        })

        setGetReconciledReports(modifiedData)
      } else {
        setDialogState({ ...dialogState, showModal: true, message: t('RECONCILED_REPORT_NO_DATA') })
      }
    } catch (error) {
      setDialogState({ ...dialogState, showModal: true, message: t('RECONCILED_REPORT_API_FAIL') })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const getInvoiceNumberContent = async (invoiceNumber) => {
    setErrorModal({ ...errorModal, showModal: false })

    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getInvoiceNumberContent(invoiceNumber)
      const content = response?.data?.returnLst

      if (content) {
        const tempDiv = document.createElement('div')

        tempDiv.innerHTML = content

        const bannerImage = tempDiv.querySelector('.banner-image')

        if (bannerImage) {
          bannerImage.style.transform = 'rotate(-7deg)'
          bannerImage.style.opacity = '0.1'
        }

        const container = tempDiv.querySelector('.container')

        container.style.width = '90%'
        container.style.margin = '10 auto'
        container.style.backgroundImage = `url(${WaterMarkImageForInvoice})`
        container.style.backgroundSize = '255px'

        const updatedContent = tempDiv.innerHTML.replace(/<img[^>]+src="([^">]+)"/g, (match) => match.replace(/src="[^">]+"/, `src="${CoffeeWebLogoPNG}"`)).replace(/rotate:\s*-?\d+deg;/g, '')

        if (isMobileScreen()) {
          downloadInvoice(updatedContent)
        } else {
          setInvoiceContent(updatedContent)
          setShowModal(true)
        }
      }
    } catch (error) {
      setErrorModal({
        ...errorModal,
        showModal: true,
        modalType: 'error',
        message: t('INVOICE_GENERATION_FAILED'),
        onHide: handleDismiss,
        onRetry: getInvoiceNumberContent
      })
    } finally {
      setLoading(false)
    }
  }

  const downloadInvoice = async (content) => {
    const clonedElement = document.createElement('div')
    const isMobile = window.innerWidth <= 768

    clonedElement.style.position = 'absolute'
    clonedElement.style.top = '-9999px'
    clonedElement.style.left = '0'

    if (isMobile) {
      clonedElement.style.width = '100vw'
      clonedElement.style.padding = '0'
      clonedElement.style.margin = '0'
      clonedElement.style.boxSizing = 'border-box'
      clonedElement.style.maxWidth = '100%'
      clonedElement.style.transformOrigin = 'top left'
    }
    clonedElement.innerHTML = content
    document.body.appendChild(clonedElement)

    const images = clonedElement.getElementsByTagName('img')
    const imageLoadPromises = Array.from(images).map(
      (img) =>
        new Promise((resolve) => {
          // eslint-disable-next-line no-param-reassign
          img.onload = resolve
          // eslint-disable-next-line no-param-reassign
          img.onerror = resolve
          if (img.complete) resolve()
        })
    )

    await Promise.all(imageLoadPromises)

    try {
      const scale = isMobile ? 2 : 2.5

      const canvas = await html2canvas(clonedElement, {
        scale,
        width: clonedElement.scrollWidth,
        height: clonedElement.scrollHeight,
        windowWidth: clonedElement.scrollWidth,
        windowHeight: clonedElement.scrollHeight
      })

      const imgData = canvas.toDataURL('image/png')

      const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' })

      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)

      pdf.save('invoice.pdf')
    } catch (err) {
      console.error('Error generating PDF:', err)
    } finally {
      document.body.removeChild(clonedElement)
    }
  }

  const handleSettingsModalSubmit = () => {
    setVisible(false)
    setLocalWithReconcileTableColumns(activeCheckBox)
  }

  const handleSettingsModalClose = () => {
    setVisible(false)
    setActiveCheckBox(getLocalReconcileTableColumns())
  }

  const handleCheckboxChange = (column, e) => {
    const updatedActiveCheckbox = {
      ...activeCheckBox,
      [column.dataField]: e.checked
    }

    setActiveCheckBox(updatedActiveCheckbox)

    Object.keys(updatedActiveCheckbox).forEach((key) => {
      if (updatedActiveCheckbox[key]) {
        activeCheckboxCount += 1
      }
    })

    setDisableButton(activeCheckboxCount < 4)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleRetry = () => {
    setDialogState({ ...dialogState, showModal: false })
    fetchReconciledReportData()
  }

  const handleDismiss = () => setDialogState({ ...dialogState, showModal: false })

  const handleErrorModalDismiss = () => setErrorModal({ ...errorModal, showModal: false })

  const renderTableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  const handleChange = (e) => {
    setFilterValue(e.value)
  }

  const filteredReports = getReconciledReports.filter((report) => {
    const reportTransactionDate = commonMethods.parseCustomDateFormat(report.transactionDate)
    const filterTransactionDate = filters.transactionDate

    const isTransactionDateValid = filterTransactionDate && filterTransactionDate.length === 2
    const startDate = isTransactionDateValid ? filterTransactionDate[0] : null
    const endDate = isTransactionDateValid ? filterTransactionDate[1] : null

    const isInDateRange = startDate && endDate ? reportTransactionDate >= startDate && reportTransactionDate <= endDate : true

    const invoiceMatch = filters.invoiceNumber ? report.invoiceNumber.toLowerCase().includes(filters.invoiceNumber.toLowerCase()) : true

    const paymentIdMatch = filters.paymentId ? report.paymentId.toLowerCase().includes(filters.paymentId.toLowerCase()) : true

    const utrNoMatch = filters.utrNo ? report.utrNo.toLowerCase().includes(filters.utrNo.toLowerCase()) : true

    const settlementNoMatch = filters.settlementNo ? report.settlementNo.toLowerCase().includes(filters.settlementNo.toLowerCase()) : true

    return invoiceMatch && paymentIdMatch && utrNoMatch && settlementNoMatch && isInDateRange
  })

  const clearFilter = (filter) => {
    setFilters((prev) => ({
      ...prev,
      [filter]: ''
    }))

    setFilterValue((prev) => prev.filter((item) => item !== filter))
  }

  const filterOptions = [
    { label: t('INVOICE_NUMBER'), value: 'invoiceNumber' },
    { label: t('PAYMENT_ID'), value: 'paymentId' },
    { label: t('UTR_NO'), value: 'utrNo' },
    { label: t('SETTLEMENT_NO'), value: 'settlementNo' },
    { label: t('TRANSACTION_DATE'), value: 'transactionDate' }
  ]

  const headerElement = (
    <div className="invoice-header-container">
      <span className="font-bold white-space-nowrap">{t('INVOICE')}</span>
      <div className="invoice-button">
        <ButtonComponent type="Custom" label={t('DOWNLOAD_INVOICE')} variant="action" icon="pi pi-arrow-down" onClick={() => downloadInvoice(invoiceContent)} />
      </div>
    </div>
  )

  return (
    <div className="reconciled-report-container">
      <LayoutContainer title={'RECONCILED_REPORT'} breadCrumbsTitle={'reconciledReport'} showSettingsButton={true} setVisible={setVisible}>
        <div className="reconciled-report-filter">
          <div className="filter-container">
            <div className="drop-down-container">
              <MultiSelect options={filterOptions} value={filterValue} onChange={handleChange} maxSelectedLabels={1} placeholder={t('FILTER')} className="multiselect-field" panelClassName="reconciled-Report-panel-style" showSelectAll={false} />
            </div>
            {filterValue.includes('invoiceNumber') && (
              <div className="drop-down-wrapper">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText placeholder={t('INVOICE_NUMBER')} value={filters.invoiceNumber} onChange={(e) => setFilters({ ...filters, invoiceNumber: e.target.value })} className="search-input-field" />
                </span>
                <div className="field-icon" onClick={() => clearFilter('invoiceNumber')}>
                  <span className="pi pi-times"></span>
                </div>
              </div>
            )}

            {filterValue.includes('paymentId') && (
              <div className="drop-down-wrapper">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText placeholder={t('PAYMENT_ID')} value={filters.paymentId} onChange={(e) => setFilters({ ...filters, paymentId: e.target.value })} className="search-input-field" />
                </span>
                <div className="field-icon" onClick={() => clearFilter('paymentId')}>
                  <span className="pi pi-times"></span>
                </div>
              </div>
            )}

            {filterValue.includes('utrNo') && (
              <div className="drop-down-wrapper">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText placeholder={t('UTR_NO')} value={filters.utrNo} onChange={(e) => setFilters({ ...filters, utrNo: e.target.value })} className="search-input-field" />
                </span>
                <div className="field-icon" onClick={() => clearFilter('utrNo')}>
                  <span className="pi pi-times"></span>
                </div>
              </div>
            )}

            {filterValue.includes('settlementNo') && (
              <div className="drop-down-wrapper">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText placeholder={t('SETTLEMENT_NO')} value={filters.settlementNo} onChange={(e) => setFilters({ ...filters, settlementNo: e.target.value })} className="search-input-field" />
                </span>
                <div className="field-icon" onClick={() => clearFilter('settlementNo')}>
                  <span className="pi pi-times"></span>
                </div>
              </div>
            )}

            {filterValue.includes('transactionDate') && (
              <div className="drop-down-wrapper">
                <span>
                  <Calendar placeholder={t('TRANSACTION_DATE')} readOnlyInput selectionMode="range" value={filters.transactionDate} onChange={(e) => setFilters({ ...filters, transactionDate: e.value })} />
                </span>
                <div className="field-icon" onClick={() => clearFilter('transactionDate')}>
                  <span className="pi pi-times"></span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="reconciled-report-table-wrapper">
          <DataTable value={filteredReports} paginator={getReconciledReports?.length} scrollHeight="550px" scrollable rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={renderTableResponseMessage} className="reconciled-report-table">
            {columns
              .filter((column) => availableColumns[column.dataField])
              .map((column, index) => (
                <Column key={index} field={column.dataField} header={column.text} className={column.className} body={column.body} sortable={column.sortable} />
              ))}
          </DataTable>
        </div>
      </LayoutContainer>
      <DialogModal {...dialogState} />
      <Dialog header={t('SETTINGS')} visible={visible} className="setting-dialog-modal" onHide={() => handleSettingsModalClose()}>
        <div className="columns-container">
          {columns.map((column) => (
            <div key={column.dataField} className="column">
              <Checkbox inputId={column.dataField} name="column" value={column.dataField} checked={activeCheckBox[column.dataField]} onChange={(e) => handleCheckboxChange(column, e)} />
              <label htmlFor={column.dataField} className="column-name">
                {column.text}
              </label>
            </div>
          ))}
        </div>
        {disableButton && <Messages className="info-message" ref={msgs} />}
        <div className="setting-button-container">
          <div className={`submit-button-wrapper ${disableButton ? 'disable-text-present' : ''}`}>
            <ButtonComponent type="Custom" label="Submit" variant="action" disabled={disableButton} onClick={() => handleSettingsModalSubmit()} />
          </div>
        </div>
      </Dialog>
      <Dialog header={headerElement} visible={showModal} className="invoice-dialog-modal" onHide={() => setShowModal(false)}>
        <div data-testid="invoice-content">{ReactHtmlParser(invoiceContent)}</div>
      </Dialog>
      <DialogModal {...errorModal} onDismiss={handleErrorModalDismiss} onHide={handleErrorModalDismiss} />
    </div>
  )
}

export default Loader(ReconciledReports)
