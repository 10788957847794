import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { TabPanel, TabView } from 'primereact/tabview'
import LayoutContainer from 'Components/LayoutComponent'
import { DialogModal } from 'Components/UIComponents'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { commonMethods } from 'Utils/commonMethods'
import Loader from 'Components/Loader'
import AddHoliday from './AddHoliday'
import EditHoliday from './EditHoliday'

const ListHoliday = ({ setLoading }) => {
  const [activeTab, setActiveTab] = useState(0)
  let rowCount = 8
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })
  const { utcToLocalTime, utcToLocalShortDate } = commonMethods

  const [robustaHolidayList, setRobustaHolidayList] = useState([])
  const [arabicaHolidayList, setArabicaHolidayList] = useState([])
  const [filteredHolidayList, setFilteredHolidayList] = useState([])
  const [activeModule, setActiveModule] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    init()

    // window.addEventListener('resize', handleWindowResize)

    return () => {
      // window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (window.location.search.includes('edit-holiday')) {
      setActiveModule('edit-holiday')
    } else if (window.location.search.includes('add-holiday')) {
      setActiveModule('add-holiday')
    } else {
      setActiveModule('list-holiday')
    }
  }, [window.location.search])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const init = () => {
    getAllHolidayList()

    const tabIndexMap = { robusta: 0, arabica: 1 }
    const tabIndex = tabIndexMap[tab] ?? 0

    params.set('tab', tab || 'robusta')
    setActiveTab(tabIndex)
  }

  const getAllHolidayList = async () => {
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.getAllHolidayList()

      if (response?.data?.isSuccess) {
        const modifiedData = response.data.returnLst.map((ele) => {
          const formattedMarketStartDateTime = utcToLocalTime(ele.marketStartDateTime)
          const formattedMarketEndDateTime = utcToLocalTime(ele.marketEndDateTime)

          return {
            ...ele,
            formattedMarketStartDateTime,
            formattedMarketEndDateTime
          }
        })

        setRobustaHolidayList(modifiedData?.filter((ele) => ele?.idMarket === 1))
        setArabicaHolidayList(modifiedData?.filter((ele) => ele?.idMarket === 2))
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_GET_HOLIDAY_LIST'),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleTabChange = (tabIndex) => {
    const tabIndexMap = { 0: 'robusta', 1: 'arabica' }
    const newTab = tabIndexMap[tabIndex] ?? 0

    params.set('tab', newTab)
    setActiveTab(tabIndex)
    history.replace({ pathname: ROUTE_STRINGS.listHoliday, search: params.toString() })
  }

  const handleSearchHoliday = (e, holidayList) => {
    const { value } = e.target
    const filteredHolidays = holidayList?.filter((ele) => utcToLocalShortDate(ele?.marketStartDateTime)?.toLowerCase()?.includes(value.toLowerCase()))

    setSearchQuery(value)
    setFilteredHolidayList(filteredHolidays)
  }

  const handleClickAddButton = () => {
    params.set('add-holiday', true)
    history.push({ pathname: ROUTE_STRINGS.listHoliday, search: `tab=${tab}&${params.toString()}` })
  }

  const handleClickEditButton = (rowData) => {
    params.set('edit-holiday', rowData.idHoliday)
    history.push({ pathname: ROUTE_STRINGS.listHoliday, search: `tab=${tab}&${params.toString()}` })
  }

  const handleClickDeleteButton = (rowData) => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YPU_SURE_WANT_TO_DELETE_THIS_HOLIDAY'),
      onConfirmation: () => deleteConfirmation(rowData),
      onHide: handleDismiss
    })
  }

  const deleteConfirmation = async (rowData) => {
    const { formattedMarketStartDateTime, formattedMarketEndDateTime, ...selectedRowData } = rowData
    const postData = { ...selectedRowData, isActive: false }

    handleDismiss()
    setLoading(true)
    try {
      // API to delete holiday data
      const res = await apiAdapterCoffeeWeb.editHolidayData(postData)

      if (res?.data?.isSuccess) {
        if (tab === 'robusta') {
          setRobustaHolidayList(robustaHolidayList.filter((ele) => ele?.idHoliday !== rowData?.idHoliday))
        } else {
          setArabicaHolidayList(arabicaHolidayList.filter((ele) => ele?.idHoliday !== rowData?.idHoliday))
        }
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('HOLIDAY_HAS_BEEN_DELETED_SUCCESSFULLY'),
          onHide: handleSuccess,
          onSuccess: handleSuccess
        })
      }
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: error.response.data.message,
        onHide: handleDismiss,
        onRetry: deleteConfirmation
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSuccess = () => setShowDialog({ ...showDialog, showModal: false })
  const handleDismiss = () => setShowDialog({ ...showDialog, showModal: false })

  const renderDateColumn = (item) => {
    const formatedHolidayData = utcToLocalShortDate(item.marketStartDateTime)

    return <div>{formatedHolidayData}</div>
  }

  const renderActionButtons = (rowData) => (
    <div className="action-buttons-wrapper">
      <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleClickEditButton(rowData)} data-testId="edit-button" />
      <Button type="button" icon="pi pi-trash" className="config-button" tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }} onClick={() => handleClickDeleteButton(rowData)} data-testId="config-button" />
    </div>
  )

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  const RenderRobustaAndArabicaMarketTable = ({ idMarket }) => {
    const holidayList = idMarket === 1 ? robustaHolidayList : arabicaHolidayList

    return (
      <DataTable emptyMessage={tableResponseMessage} value={searchQuery ? filteredHolidayList : holidayList} paginator={searchQuery ? filteredHolidayList?.length : holidayList?.length} rows={rowCount} pageLinkSize={paginationButtons} tableStyle={{ minWidth: '50rem' }}>
        <Column field="marketStartDateTime" body={(e) => renderDateColumn(e)} header={t('DATE')}></Column>
        <Column field="formattedMarketStartDateTime" header={t('STARTS_FROM')}></Column>
        <Column field="formattedMarketEndDateTime" header={t('ENDS_AT')}></Column>
        <Column className="action-buttons-column-cell" body={(e) => renderActionButtons(e)} field="quantity" header={t('ACTIONS')}></Column>
      </DataTable>
    )
  }

  const getPageTitle = () => {
    /* eslint-disable no-else-return */
    if (activeModule === 'list-holiday') {
      return t('LIST_HOLIDAY')
    } else if (activeModule === 'add-holiday') {
      return t('ADD_NEW_HOLIDAY')
    } else {
      return t('EDIT_HOLIDAY')
    }
  }

  return (
    <LayoutContainer title={getPageTitle()} breadCrumbsTitle={'listHoliday'} showAddButton={true} handleAddButtonClick={handleClickAddButton}>
      <div className="holiday-component-module">
        {activeModule === 'list-holiday' && (
          <div className="holiday-list-tab-wrapper">
            <TabView activeIndex={activeTab} onTabChange={(e) => handleTabChange(e.index)}>
              <TabPanel header={t('ROBUSTA')}>
                <div className="holiday-list-header">
                  <InputText placeholder="Search Menu" value={searchQuery} onChange={(e) => handleSearchHoliday(e, robustaHolidayList)} />
                </div>
                {activeTab === 0 && <RenderRobustaAndArabicaMarketTable idMarket={1} />}
              </TabPanel>
              <TabPanel header={t('ARABICA')}>
                <div className="holiday-list-header">
                  <InputText placeholder="Search Menu" value={searchQuery} onChange={(e) => handleSearchHoliday(e, arabicaHolidayList)} />
                </div>
                {activeTab === 1 && <RenderRobustaAndArabicaMarketTable idMarket={2} />}
              </TabPanel>
            </TabView>
          </div>
        )}
        {activeModule === 'add-holiday' && <AddHoliday robustaHolidayList={robustaHolidayList} arabicaHolidayList={arabicaHolidayList} setRobustaHolidayList={setRobustaHolidayList} setArabicaHolidayList={setArabicaHolidayList} />}
        {activeModule === 'edit-holiday' && <EditHoliday robustaHolidayList={robustaHolidayList} arabicaHolidayList={arabicaHolidayList} setRobustaHolidayList={setRobustaHolidayList} setArabicaHolidayList={setArabicaHolidayList} />}

        <DialogModal {...showDialog} onDismiss={handleDismiss} />
      </div>
    </LayoutContainer>
  )
}

export default Loader(ListHoliday)
