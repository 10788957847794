import './styles.scss'
import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { ButtonComponent } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'

const GlobalRawCoffeePriceExcelUpload = ({ setLoading, ...props }) => {
  const { xlData, setXlData, selectedCountry, countryList, setShowRawCoffeePricePreviewDailogModal, setModalState } = props

  const { t } = useTranslation()

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)

  const columns = [
    { className: 'rowReorder', rowReorder: true },
    { field: 'CountryName', header: t('COUNTRY_NAME'), className: 'countryName' },
    { field: 'QualityGroup', header: t('QUALITY_GROUP'), className: 'qualityGroup' },
    { field: 'QualityName', header: t('QUALITY_NAME'), className: 'qualityName' },
    { field: 'RangeStart', header: t('RANGE_START'), className: 'rangeStart' },
    { field: 'RangeEnd', header: t('RANGE_END'), className: 'rangeEnd' },
    { field: 'ConvertedRangeStart', header: t('CONVERTED_RANGE_START'), className: 'convertedRangeStart' },
    { field: 'ConvertedRangeEnd', header: t('CONVERTED_RANGE_END'), className: 'convertedRangeEnd' },
    { field: 'Remarks', header: t('REMARKS'), className: 'remarks' },
    { field: 'CropYear', header: t('CROP_YEAR'), className: 'cropYear' },
    { field: 'ForexType', header: t('FOREX_TYPE'), className: 'forexType' },
    { field: 'ForexValue', header: t('FOREX_VALUE'), className: 'forexValue' },
    { field: 'Terminal', header: t('TERMINAL'), className: 'termial' },
    { field: 'TerminalLevel', header: t('TERMINAL_LEVEL'), className: 'termialLevel' },
    { field: 'UpdatedDate', header: t('UPDATED_DATE'), className: 'updatedDate' }
  ]

  const countryName = (options) => {
    const defaultCountry = countryList.find((ele) => {
      if (ele.nicename === options.rowData.CountryName) {
        return ele
      }
    })

    return <Dropdown value={defaultCountry} options={countryList} onChange={(e) => handleChangeCountry(e, options)} optionLabel="nicename" placeholder="Select a City" />
  }

  const addRowForGlobalRawCoffeePrice = () => {
    const newObject = {
      CountryName: 'India',
      QualityGroup: '',
      QualityName: '',
      RangeStart: '',
      RangeEnd: '',
      ConvertedRangeStart: '',
      ConvertedRangeEnd: '',
      Remarks: '',
      CropYear: '',
      ForexType: '',
      ForexValue: '',
      Terminal: '',
      TerminalLevel: '',
      UpdatedDate: ''
    }

    setIsSubmitButtonDisabled(true)
    setXlData([...xlData, newObject])
  }

  const deleteRow = (options) => {
    setModalState({
      showModal: true,
      message: t('ARE_YOU_SURE_WANT_TO_DELETE_THIS_ROW'),
      modalType: 'info',
      confirmAction: () => confirmDeleteRow(options.rowIndex)
    })
  }

  const handleChangeCountry = (e, options) => {
    const { id } = e.target.value
    const findCountry = countryList.find((ele) => ele.id === id)
    const modifiedData = xlData.map((ele, index) => {
      if (options.rowIndex === index) {
        return {
          ...ele,
          [options.field]: findCountry.nicename
        }
      }

      return ele
    })

    setXlData(modifiedData)
  }

  const onInputBlur = (options) => {
    const modifiedData = xlData.map((ele, index) => (options.rowIndex === index ? { ...ele, [options.field]: options.value } : ele))

    setXlData(modifiedData)
  }

  const cellEditor = (options) => {
    if (options.field === 'CountryName') return countryName(options)

    return textEditor(options)
  }

  const textEditor = (options) => {
    const { CountryName, QualityGroup, QualityName, RangeStart, RangeEnd, ConvertedRangeStart, ConvertedRangeEnd, Remarks, CropYear, ForexType, ForexValue, Terminal, TerminalLevel, UpdatedDate } = options.rowData

    if (!CountryName || !QualityGroup || !QualityName || !RangeStart || !RangeEnd || !ConvertedRangeStart || !ConvertedRangeEnd || !Remarks || !CropYear || !ForexType || !ForexValue || !Terminal || !TerminalLevel || !UpdatedDate) {
      setIsSubmitButtonDisabled(true)
    } else {
      setIsSubmitButtonDisabled(false)
    }

    if (options.rowData) return <InputText type="text" value={options.value} onBlur={() => onInputBlur(options)} onChange={(e) => options.editorCallback(e.target.value)} />
  }

  const confirmDeleteRow = (itemIndex) => {
    const updatedData = xlData.filter((_, index) => index !== itemIndex)

    setXlData(updatedData)
    setModalState({ showModal: false })
  }

  const uploadRawCoffeePriceData = async () => {
    setLoading(true)
    try {
      const postData = xlData.map((xlItem) => {
        const { CountryName, QualityGroup, QualityName, RangeStart, RangeEnd, ConvertedRangeStart, ConvertedRangeEnd, Remarks, CropYear, ForexType, ForexValue, Terminal, TerminalLevel, UpdatedDate } = xlItem

        return {
          idCountry: selectedCountry.id,
          countryName: CountryName,
          qualityGroup: QualityGroup,
          qualityName: QualityName,
          rangeStart: RangeStart?.toString(),
          rangeEnd: RangeEnd?.toString(),
          convertedRangeStart: ConvertedRangeStart?.toString(),
          convertedRangeEnd: ConvertedRangeEnd?.toString(),
          remarks: Remarks,
          cropYear: CropYear,
          terminal: Terminal,
          terminalLevel: TerminalLevel?.toString(),
          forexType: ForexType,
          forexValue: ForexValue?.toString(),
          updatedDate: UpdatedDate?.toString()
        }
      })

      const response = await apiAdapterCoffeeWeb.InsertGlobalRawCoffeePrice(postData)

      if (response.data === true) {
        setModalState({
          showModal: true,
          message: t('EXCEL_DATA_ADDED_SUCCESSFULLY'),
          modalType: 'success'
        })
      } else {
        setModalState({
          showModal: true,
          message: t('SOMETHING_WENT_WRONG'),
          modalType: 'error'
        })
      }
    } catch {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_ADD_GLOBAL_RAW_COFFEE_PRICE'),
        modalType: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const footerContent = <ButtonComponent type="custom" label="SUBMIT" variant="action" onClick={() => uploadRawCoffeePriceData()} disabled={xlData?.length === 0 || isSubmitButtonDisabled} />

  return (
    <Dialog className="global-raw-coffee-price-data-preview-modal-popup" header="Global Raw Coffee Price" visible={true} style={{ width: '95vw', height: '100vh' }} onHide={() => setShowRawCoffeePricePreviewDailogModal(false)} footer={footerContent}>
      <div className="add-button-wrapper">
        <ButtonComponent type="custom" label="ADD" variant="action" icon="pi pi-plus" onClick={() => addRowForGlobalRawCoffeePrice()} />
      </div>

      <div className="preview-table-wrapper">
        <DataTable className="card" scrollable scrollHeight="60vh" reorderableRows onRowReorder={(e) => setXlData(e.value)} value={xlData} editMode="cell" tableStyle={{ minWidth: '50rem' }}>
          {columns.map(({ field, header, className, rowReorder, body }, index) => (
            <Column key={index} field={field} header={header} rowReorder={rowReorder} className={className} body={body} editor={(options) => cellEditor(options)} />
          ))}
          <Column body={(_, options) => <Button icon="pi pi-trash" onClick={() => deleteRow(options)} />} />
        </DataTable>
      </div>
    </Dialog>
  )
}

export default Loader(GlobalRawCoffeePriceExcelUpload)
