import './styles.scss'
import LayoutContainer from 'Components/LayoutComponent'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabView, TabPanel } from 'primereact/tabview'
import { ROUTE_STRINGS } from 'Utils/Constants'
import AdminMenu from './AdminMenu'
import ClientMenu from './ClientMenu'
import MobileMenu from './MobileMenu'

const ListMenuGroup = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const [activeTab, setActiveTab] = useState(0)

  const updateHistoryAndSetActiveTab = (newTab, tabIndex, initialLoad = false) => {
    params.set('tab', newTab)

    if (!initialLoad) {
      history.replace({ pathname: ROUTE_STRINGS.listmenu, search: params.toString() })
    }
    setActiveTab(tabIndex)
  }

  useEffect(() => {
    const tabIndexMap = { admin_menu: 0, client_menu: 1, mobile_menu: 2 }
    const tabIndex = tabIndexMap[tab] ?? 0

    updateHistoryAndSetActiveTab(tab, tabIndex, true)
  }, [])

  const handleAddMenu = () => {
    const newTab = tab || 'admin_menu'

    params.set('tab', newTab)
    history.push({ pathname: ROUTE_STRINGS.addmenu, search: params.toString() })
  }

  const handleTabChange = (index) => {
    let newTab

    if (index === 0) {
      newTab = 'admin_menu'
    } else if (index === 1) {
      newTab = 'client_menu'
    } else {
      newTab = 'mobile_menu'
    }

    updateHistoryAndSetActiveTab(newTab, index)
  }

  return (
    <LayoutContainer title={'MENU_LIST'} breadCrumbsTitle={'listMenu'}>
      <div className="menu-tab-container">
        <TabView activeIndex={activeTab} onTabChange={(e) => handleTabChange(e.index)}>
          <TabPanel header={t('ADMIN_MENU')}>
            <AdminMenu handleAddMenu={handleAddMenu} />
          </TabPanel>
          <TabPanel header={t('WEB_MENU')}>
            <ClientMenu handleAddMenu={handleAddMenu} />
          </TabPanel>
          <TabPanel header={t('MOBILE_MENU')}>
            <MobileMenu />
          </TabPanel>
        </TabView>
      </div>
    </LayoutContainer>
  )
}

export default ListMenuGroup
