import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { SelectButton } from 'primereact/selectbutton'
import { InputText } from 'primereact/inputtext'
import { Form, Field } from 'react-final-form'
import Loader from 'Components/Loader'
import { REG_EX, ROUTE_STRINGS } from 'Utils/Constants'
import Validation from 'Utils/Validation'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ResetPasswordModal = ({ setLoading, generatedToken, resetModal, setResetModal, setModalState, passwordResetVia, setPasswordResetVia, setResetError, mockShowOtpFields, mockRegisteredPhoneError, mockSeconds, mockRegisteredEmailOrPhoneError, mockErrorState, mockEncryptIdFlag }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const [countryPhoneCode, setCountryPhoneCode] = useState(102)
  const [seconds, setSeconds] = useState(0)
  const [selectedCity, setSelectedCity] = useState([])
  const [encryptUserId, setEncryptUserId] = useState(null)
  const [showOtpFields, setShowOtpFields] = useState(false)
  const [encryptIdFLag, setEncryptIdFlag] = useState(false)
  const [showSuccessTextForEmail, setShowSuccessTextForEmail] = useState(false)
  const [registeredEmailOrPhoneError, setRegisteredEmailOrPhoneError] = useState(false)
  const [registeredPhoneError, setRegisteredPhoneError] = useState(false)
  const [isCountryCodeExists, setIsCountryCodeExists] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [mismatchPasswordError, setMismatchPasswordError] = useState(false)
  const [isResetDisabled, setIsResetDisabled] = useState(false)
  const [numberLength, setNumberLength] = useState(true)
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  })
  const [userInputValues, setUserInputValues] = useState({
    email: '',
    phoneNo: '',
    password: '',
    confirmPassword: '',
    otp: '',
    sessionId: ''
  })
  const [userIdValue, setUserIdValue] = useState({
    userId: ''
  })

  useEffect(() => {
    fetchAllCountries()
    setPasswordResetVia(history.location.search === `${'?'}${ROUTE_STRINGS.resetViaPhone}` ? 'phone' : 'email')

    const params = new URLSearchParams(history.location.search)
    const authModParam = params.get('authMod')

    if (authModParam) {
      const id = authModParam.split('/')[1]

      setEncryptUserId(id)
      setEncryptIdFlag(id !== undefined)
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      } else {
        clearInterval(timer)
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [seconds])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockShowOtpFields) {
      setShowOtpFields(true)
      setRegisteredPhoneError(mockRegisteredPhoneError)
      setSeconds(mockSeconds)
      setRegisteredEmailOrPhoneError(mockRegisteredEmailOrPhoneError)
      setErrorState(mockErrorState)
      setShowSuccessTextForEmail(true)
      setEncryptIdFlag(mockEncryptIdFlag)
    }
  }, [])

  const fetchAllCountries = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getAllCountries()

      if (response) {
        setSelectedCity(response.data)
        setIsCountryCodeExists((previousState) => !previousState)

        const item = response.data.find((element) => element.id === countryPhoneCode)

        setCountryPhoneCode(item)
      }
    } catch {
      setModalState({
        showModal: true,
        modalType: 'error',
        message: t('SORRY_COULD_NOT_FETCH_THE_DATA_PLEASE_TRY_AGAIN_LATER')
      })
    }
  }

  const checkIfUserIsAdmin = async (phone) => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAdminUserByPhoneNumber({ phone, countryId: countryPhoneCode.id })

      const {
        status: responseStatus,
        data: { id }
      } = response

      setUserIdValue((previousValue) => ({
        ...previousValue,
        userId: response?.data?.id
      }))

      if (responseStatus === 200) {
        sendOtpAndShowModal(phone)

        return id
      }
    } catch (error) {
      if (error.response.status === 404) {
        setRegisteredPhoneError(true)
      } else {
        setModalState({
          showModal: true,
          modalType: 'error',
          message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER')
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const checkIfAdminEmailExists = async () => {
    const { email } = userInputValues

    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.isEmailExist({ email }, generatedToken)

      if (data) {
        sendOtpOnMail(email)
      } else {
        setRegisteredEmailOrPhoneError(true)
        setErrorState(true)
      }
    } catch {
      setModalState({
        showModal: true,
        modalType: 'error',
        message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER')
      })
    } finally {
      setLoading(false)
    }
  }
  const sendOtpOnMail = async () => {
    setLoading(true)
    const { email } = userInputValues

    try {
      const response = await apiAdapterCoffeeWeb.forgotPasswordLink({ email }, generatedToken)

      if (response.status === 200) {
        setShowSuccessTextForEmail(true)
      }
    } catch {
      setModalState({
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_SEND_RESET_LINK')
      })
    } finally {
      setLoading(false)
    }
  }

  const sendOtpAndShowModal = async (number) => {
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.sendOtpWithTemplate(`${countryPhoneCode.phonecode.toString()}${number}`)
      const {
        status: responseStatus,
        data: { status, details }
      } = response

      if (responseStatus === 200) {
        if (status === 'Success') {
          setShowOtpFields(true)
          setUserInputValues((previousValue) => ({
            ...previousValue,
            sessionId: response?.data?.details,
            otp: '',
            password: '',
            confirmPassword: ''
          }))
          setSeconds(60)
        } else {
          setModalState({
            showModal: true,
            modalType: 'error',
            message: details
          })
        }
      } else {
        setModalState({
          showModal: true,
          modalType: 'error',
          message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER')
        })
      }
    } catch {
      setModalState({
        showModal: true,
        modalType: 'error',
        message: t('SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER')
      })
    } finally {
      setLoading(false)
    }
  }

  const updateAdminPassword = async (userId, password) => {
    setResetModal(false)
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.updateUserPassword({ userId, password }, generatedToken)

      if (response.status === 200) {
        setUserInputValues((previousValue) => ({ ...previousValue, phoneNo: '', otp: '', password: '', confirmPassword: '', sessionId: '' }))

        setModalState({
          showModal: true,
          modalType: 'success',
          message: t('NEW_PASSWORD_UPDATED_SUCCESSFULLY')
        })
      }
    } catch {
      setModalState({
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_UPDATE_PASSWORD')
      })
    } finally {
      setLoading(false)
    }
  }

  const updatePasswordWithEncryptedUserId = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.updatePasswordWithEncryptedUserId({ encryptUserId, password }, generatedToken)

      setResetModal(false)
      if (response.data === true) {
        setModalState({
          showModal: true,
          modalType: 'success',
          message: t('NEW_PASSWORD_UPDATED_SUCCESSFULLY')
        })
      } else {
        setModalState({
          showModal: true,
          modalType: 'error',
          message: t('FAILED_UPDATE_PASSWORD_STATUS')
        })
      }
    } catch {
      setModalState({
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_UPDATE_PASSWORD')
      })
    } finally {
      setLoading(false)
      history.push(ROUTE_STRINGS.login)
    }
  }

  const verifyOtpAndUpdatePassword = async (sessionId, otp, password, confirmPassword) => {
    const { userId } = userIdValue

    if (password === confirmPassword) {
      setLoading(true)
      setIsResetDisabled(true)
      try {
        const response = await apiAdapterCoffeeWeb.verifyOtp({ sessionId, otp })

        const {
          status: responseStatus,
          data: { details, status }
        } = response

        if (responseStatus === 200) {
          if (status === 'Error') {
            setModalState({
              showModal: true,
              modalType: 'error',
              message: details
            })
          } else {
            updateAdminPassword(userId, password)
          }
        }
      } catch {
        setModalState({
          showModal: true,
          modalType: 'error',
          message: t('FAILED_TO_SEND_OTP')
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const handleChange = (e) => {
    setRegisteredEmailOrPhoneError(false)
    setShowSuccessTextForEmail(false)
    setIsResetDisabled(false)
    setRegisteredPhoneError(false)
    const { name, value } = e.target

    const updatedValues = { ...userInputValues }

    if (name === 'otp' || name === 'password' || name === 'confirmPassword' || name === 'phoneNo') {
      updatedValues[name] = value
      if (name === 'phoneNo') {
        if ((value && Validation.phoneValidation(value)) || value === '' || value === '-') {
          setErrorState(false)
          const isNumberValid = REG_EX.NUMBER_WITH_REGEX.test(value)

          setNumberLength(!isNumberValid)
        } else {
          setErrorState(true)
        }
      }
    } else if (name === 'email') {
      updatedValues.email = value.toString().toLowerCase()
      setErrorState(value.trim() && !Validation.checkEmailValidity(value))
    }

    setUserInputValues(updatedValues)
  }

  const handleTogglePassword = (field) => {
    setShowPassword((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field]
    }))
  }

  const handleFormSubmit = () => {
    const { sessionId, otp, password, confirmPassword } = userInputValues

    if (otp) {
      verifyOtpAndUpdatePassword(sessionId, otp, password, confirmPassword)
    } else {
      updatePasswordWithEncryptedUserId()
    }
  }

  const handleLoginTypeChange = ({ value }) => {
    if (value !== null) {
      setPasswordResetVia(value)
    } else {
      setPasswordResetVia(passwordResetVia)
    }

    if (value === 'phone') {
      setRegisteredPhoneError(false)
      setUserIdValue({ ...userIdValue, phone: '' })
      setUserInputValues({ ...userInputValues, phoneNo: '' })
    } else if (value === 'email') {
      setRegisteredEmailOrPhoneError(false)
      setUserInputValues({ ...userInputValues, email: '' })
      setErrorState(false)
    }

    const newURL = value === 'phone' ? ROUTE_STRINGS.resetViaPhone : ROUTE_STRINGS.resetViaEmail
    const url = {
      pathname: history.location.pathname,
      search: newURL
    }

    history.replace(url)
  }

  const handleResendClick = () => {
    sendOtpAndShowModal(phoneNo)
  }

  const handleDismiss = () => {
    setResetError(true)
    setResetModal(false)
    history.push(ROUTE_STRINGS.login)
  }

  const onSubmit = (data) => {
    setUserInputValues(data)
  }

  const validate = (data) => {
    const errors = {}
    const { email, phoneNo, otp, password, confirmPassword } = data

    if (!phoneNo) {
      errors.phoneNo = t('PHONE_IS_REQUIRED')
    }
    if (!otp) {
      errors.otp = t('OTP_IS_REQUIRED')
    }
    if (!password) {
      errors.password = t('PASSWORD_IS_REQUIRED')
    }
    if (!confirmPassword) {
      errors.confirmPassword = t('CONFIRM_PASSWORD_IS_REQUIRED')
    }
    if (!email) {
      errors.email = t('EMAIL_IS_REQUIRED')
    }
    if (password && confirmPassword && password !== confirmPassword) {
      setMismatchPasswordError(true)
    } else {
      setMismatchPasswordError(false)
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const options = [
    { label: t('PHONE'), value: 'phone', disable: showOtpFields || encryptIdFLag || showSuccessTextForEmail, icon: 'pi pi-phone' },
    { label: t('EMAIL'), value: 'email', disable: showOtpFields || encryptIdFLag || showSuccessTextForEmail, icon: 'pi pi-envelope' }
  ]

  const selectButtonContent = (option) => (
    <div className="select-button-content">
      <i className={option.icon}></i>
      <p>{option.label}</p>
    </div>
  )

  const { otp, password, confirmPassword, email, phoneNo } = userInputValues

  const isEmailButtonDisabled = !email || errorState
  const isPhoneNoButtonDisabled = !phoneNo || errorState || numberLength || registeredPhoneError
  const isButtonDisabled = !otp || !password || !confirmPassword || password !== confirmPassword
  const isButtonDisabledForEmail = !password || !confirmPassword || password !== confirmPassword

  const handleChangePhoneNumber = () => {
    setSeconds(0)
    setShowOtpFields(false)
    setUserInputValues((previousState) => ({ ...previousState, sessionId: '', otp: '', password: '', confirmPassword: '' }))
    setShowPassword((previousState) => ({ ...previousState, password: false, confirmPassword: false }))
  }

  return (
    <Dialog className="reset-dialog" header={t('RESET_PASSWORD')} visible={resetModal} onHide={handleDismiss}>
      <div className="reset-otp-form">
        <div className="selected-buttons">
          <SelectButton value={passwordResetVia} onChange={handleLoginTypeChange} options={options} optionLabel="label" optionDisabled="disable" itemTemplate={selectButtonContent} className="select-type" />
        </div>
        <div>
          <Form
            onSubmit={onSubmit}
            initialValues={userInputValues}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                {passwordResetVia === 'phone' && (
                  <div>
                    <Field name="phoneNo">
                      {({ input, meta }) => (
                        <div className="field phone-field">
                          <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon">
                              <Dropdown
                                filter
                                value={countryPhoneCode}
                                name="countryId"
                                onChange={(e) => setCountryPhoneCode(e.value)}
                                options={selectedCity}
                                optionLabel="phonecode"
                                disabled={showOtpFields}
                                data-testid="country-dropdown"
                                itemTemplate={(option) => (
                                  <div className="dropdown-selector">
                                    <img src={option?.flagsUrl} alt="flag" className="flag-icon" />
                                    <div className="currency-details">
                                      <div className="country-code">
                                        {'+'}
                                        {option?.phonecode}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                valueTemplate={(selectedOption) => (
                                  <div className="dropdown-selector">
                                    <img src={selectedOption?.flagsUrl} alt="flag" className="flag-icon" />

                                    <div className="currency-details">
                                      <div className="country-code">
                                        {'+'}
                                        {selectedOption?.phonecode}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              />
                            </span>
                            <span className="p-float-label">
                              <InputText placeholder={t('PHONE_NUMBER')} {...input} id="phoneNo" name="phoneNo" value={userInputValues.phoneNo} onChange={handleChange} disabled={showOtpFields || !isCountryCodeExists} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields', 'phone-input')} keyfilter="int" maxLength={15} />
                              <label htmlFor="phoneNo" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                {t('PHONE')} <span className="required-star"> *</span>
                              </label>
                            </span>
                          </div>
                          <div className="error-message">{registeredPhoneError ? <p className="email-error">{t('PHONE_NUMBER_NOT_REGISTERED')}</p> : getFormErrorMessage(meta)}</div>
                          {showOtpFields && (
                            <div className="change-phone-number">
                              <div onClick={handleChangePhoneNumber} className="change-phone-number-text">
                                {t('CHANGE_PHONE_NUMBER')}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Field>

                    {showOtpFields && (
                      <>
                        <div className="otp-password-div">
                          <Field name="otp">
                            {({ input, meta }) => (
                              <div className="field">
                                <div className="p-inputgroup flex-1">
                                  <span className="p-float-label">
                                    <InputText {...input} placeholder={t('ENTER_OTP')} id="otp" name="otp" value={userInputValues.otp} onChange={handleChange} maxLength={6} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                                    <label htmlFor="otp" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                      {t('ENTER_OTP')} <span className="required-star"> *</span>
                                    </label>
                                  </span>
                                </div>
                                <div className="error-message">{getFormErrorMessage(meta)}</div>
                              </div>
                            )}
                          </Field>
                          <div className="otp-success-div">
                            <p className="otp-success-text">{t('OTP_SENT_SUCCESSFULLY')}</p>
                            {seconds === 0 ? (
                              <p className="otp-resend-text" onClick={handleResendClick}>
                                {t('RESEND_OTP')}
                              </p>
                            ) : (
                              <p className="otp-timer-text">
                                {'00'} : {seconds < 10 ? `0${seconds}` : seconds}
                              </p>
                            )}
                          </div>
                          <Field
                            name="password"
                            render={({ input, meta }) => (
                              <div className="field">
                                <div className="p-inputgroup flex-1">
                                  <span className="p-inputgroup-addon">
                                    <i className="pi pi-key" />
                                  </span>
                                  <span className="p-input-icon-right">
                                    {showPassword.password ? <i className="pi pi-eye" onClick={() => handleTogglePassword('password')} /> : <i className="pi pi-eye-slash" onClick={() => handleTogglePassword('password')} />}
                                    <span className="p-float-label">
                                      <InputText type={!showPassword.password ? 'password' : 'text'} id="password" placeholder={t('PASSWORD')} {...input} value={userInputValues.password} onChange={handleChange} feedback={false} className={classNames('password-input', { 'p-invalid': isFormFieldValid(meta) })} />
                                      <label htmlFor="password" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                        {t('PASSWORD')} <span className="required-star"> *</span>
                                      </label>
                                    </span>
                                  </span>
                                </div>
                                <div className="error-message">{getFormErrorMessage(meta)}</div>
                              </div>
                            )}
                          />
                          <Field
                            name="confirmPassword"
                            render={({ input, meta }) => (
                              <div className="field">
                                <div className="p-inputgroup flex-1">
                                  <span className="p-inputgroup-addon">
                                    <i className="pi pi-key" />
                                  </span>
                                  <span className="p-input-icon-right">
                                    {showPassword.confirmPassword ? <i className="pi pi-eye" onClick={() => handleTogglePassword('confirmPassword')} /> : <i className="pi pi-eye-slash" onClick={() => handleTogglePassword('confirmPassword')} />}
                                    <span className="p-float-label">
                                      <InputText type={!showPassword.confirmPassword ? 'password' : 'text'} id="confirmPassword" name="confirmPassword" placeholder={t('CONFIRM_PASSWORD')} {...input} value={userInputValues.confirmPassword} onChange={handleChange} feedback={false} className={classNames('password-input', { 'p-invalid': isFormFieldValid(meta) })} />
                                      <label htmlFor="confirmPassword" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                        {t('CONFIRM_PASSWORD')} <span className="required-star"> *</span>
                                      </label>
                                    </span>
                                  </span>
                                </div>
                                <div className="error-message">{mismatchPasswordError ? <p className="mismatch-password-error">{t('PASSWORD_AND_CONFIRM_NEW_PASSWORD_SHOULD_BE_SAME')}</p> : getFormErrorMessage(meta)}</div>
                              </div>
                            )}
                          />
                        </div>
                      </>
                    )}
                    {!showOtpFields ? <Button label={t('REQUEST_FOR_OTP')} className="login-with-otp-button" onClick={() => checkIfUserIsAdmin(userInputValues.phoneNo)} disabled={isPhoneNoButtonDisabled || registeredEmailOrPhoneError} /> : <Button label={t('RESET_PASSWORD')} className="login-with-otp-button" onClick={handleFormSubmit} disabled={isButtonDisabled || isResetDisabled} />}
                  </div>
                )}

                {passwordResetVia === 'email' && (
                  <div>
                    {!encryptIdFLag && (
                      <Field name="email">
                        {({ input, meta }) => (
                          <div className="field email-field">
                            <div className="p-inputgroup flex-1">
                              <span className="p-inputgroup-addon">
                                <i className="pi pi-envelope" />
                              </span>
                              <span className="p-float-label">
                                <InputText {...input} placeholder={t('EMAIL')} id="email" value={userInputValues.email} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} disabled={showSuccessTextForEmail} />
                                <label htmlFor="email" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                  {t('EMAIL')} <span className="required-star"> *</span>
                                </label>
                              </span>
                            </div>
                            <div className="error-message">{registeredEmailOrPhoneError ? <p className="error-message email-error">{t('EMAIL_IS_NOT_REGISTERED')}</p> : (errorState && <p className="error-message email-error">{t('INVALID_EMAIL_FORMAT')}</p>) || getFormErrorMessage(meta)}</div>
                            {showSuccessTextForEmail && (
                              <div className="change-email">
                                <div onClick={() => setShowSuccessTextForEmail(false)} className="change-email-text">
                                  {t('CHANGE_EMAIL')}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    )}

                    {showSuccessTextForEmail && (
                      <>
                        {' '}
                        <div>
                          <p className="reset-link-text">{t('PASSWORD_RESET_LINK_HAS_BEEN_SENT_TO_THE_EMAIL')}</p>
                        </div>
                      </>
                    )}
                    {encryptIdFLag && (
                      <div className="otp-password-div">
                        <Field
                          name="password"
                          render={({ input, meta }) => (
                            <div className="field">
                              <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                  <i className="pi pi-key" />
                                </span>
                                <span className="p-input-icon-right">
                                  {showPassword.password ? <i className="pi pi-eye" onClick={() => handleTogglePassword('password')} /> : <i className="pi pi-eye-slash" onClick={() => handleTogglePassword('password')} />}
                                  <span className="p-float-label">
                                    <InputText type={!showPassword.password ? 'password' : 'text'} id="password" placeholder={t('PASSWORD')} {...input} value={userInputValues.password} onChange={handleChange} feedback={false} className={classNames('password-input', { 'p-invalid': isFormFieldValid(meta) })} />
                                    <label htmlFor="password" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                      {t('PASSWORD')} <span className="required-star"> *</span>
                                    </label>
                                  </span>
                                </span>
                              </div>
                              <div className="error-message">{getFormErrorMessage(meta)}</div>
                            </div>
                          )}
                        />
                        <Field
                          name="confirmPassword"
                          render={({ input, meta }) => (
                            <div className="field">
                              <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                  <i className="pi pi-key" />
                                </span>
                                <span className="p-input-icon-right">
                                  {showPassword.confirmPassword ? <i className="pi pi-eye" onClick={() => handleTogglePassword('confirmPassword')} /> : <i className="pi pi-eye-slash" onClick={() => handleTogglePassword('confirmPassword')} />}
                                  <span className="p-float-label">
                                    <InputText type={!showPassword.confirmPassword ? 'password' : 'text'} id="confirmPassword" name="confirmPassword" placeholder={t('CONFIRM_PASSWORD')} {...input} value={userInputValues.confirmPassword} onChange={handleChange} feedback={false} className={classNames('password-input', { 'p-invalid': isFormFieldValid(meta) })} />
                                    <label htmlFor="confirmPassword" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                      {t('CONFIRM_PASSWORD')} <span className="required-star"> *</span>
                                    </label>
                                  </span>
                                </span>
                              </div>
                              <div className="error-message">{mismatchPasswordError ? <p className="mismatch-password-error">{t('PASSWORD_AND_CONFIRM_NEW_PASSWORD_SHOULD_BE_SAME')}</p> : getFormErrorMessage(meta)}</div>
                            </div>
                          )}
                        />
                      </div>
                    )}
                    {encryptIdFLag ? <Button label={t('SET_NEW_PASSWORD')} className="login-with-otp-button" onClick={handleFormSubmit} disabled={isButtonDisabledForEmail} /> : <Button label={t('REQUEST_FOR_EMAIL_LINK')} className="login-with-otp-button" onClick={() => checkIfAdminEmailExists(userInputValues.email)} disabled={isEmailButtonDisabled || showSuccessTextForEmail} />}
                  </div>
                )}
              </form>
            )}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default Loader(ResetPasswordModal)
