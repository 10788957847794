import './styles.scss'
import React, { useEffect, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { useTranslation } from 'react-i18next'
import { commonMethods } from 'Utils/commonMethods'
import { coffeeQuotesDefaultValue } from 'Utils/coffeeQuotesDummyData'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import EditCoffeeQuotesData from './EditCoffeeQuotesData'

function CoffeeQuotes({ setLoading, mockCoffeeQuotes, mockSetIceMarketData }) {
  const { t } = useTranslation()
  const { getOptionExpiryColor, getFirstNoticeDayColor } = commonMethods

  const [iceMarketData, setIceMarketData] = useState(coffeeQuotesDefaultValue)
  const [visible, setVisible] = useState(false)
  const [filteredRowData, setFilteredRowData] = useState({})

  useEffect(() => {
    getICEDataCoffeeQuotesDataFromHttpURL()

    // Testing Purpose
    if (mockCoffeeQuotes) {
      setIceMarketData(mockSetIceMarketData)
    }
  }, [])

  const getICEDataCoffeeQuotesDataFromHttpURL = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getICEMartketTerminalDataFromHttpURL()

      if (response?.data?.statusCode === 200) {
        const coffeeqotesData = response?.data?.returnLst?.coffeeQuotesICEData

        setIceMarketData(coffeeqotesData)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const robustaAndArabicaTerminalTableHeaderTemplate = (contractType) => (
    <tr className="table_head">
      <td className="contract_name">
        <img className="country-flag-icon" src={`https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/${contractType === 'robusta' ? 'GBR' : 'USA'}.svg`} alt="Flag" title="contract-flag" />
        {contractType === 'robusta' ? <>{t('ROBUSTA_CONTRACTS')}</> : <>{t('ARABICA_CONTRACTS')}</>}
      </td>
      <td>{t('LAST')}</td>
      <td className="chng-cell">{t('CHG')}</td>
      <td>%</td>
      <td>{t('VOL')}</td>
      <td>{t('HIGH')}</td>
      <td>{t('NET_HIGH')}</td>
      <td>{t('LOW')}</td>
      <td>{t('NET_LOW')}</td>
      <td>{t('OPEN')}</td>
      <td>{t('PRE_CLOSE')}</td>
      <td>{t('OPEN_INT')}</td>
      <td>{t('BID')}</td>
      <td>{t('BSIZE')}</td>
      <td>{t('ASK')}</td>
      <td>{t('ASIZE')}</td>
      <td>{t('OPTION_EXPIRY')}</td>
      <td>{t('FIRST_NOTICE_DAY')}</td>
      <td></td>
    </tr>
  )

  const handleClick = (rowData, rowId) => {
    const filterRow = rowData?.find(({ id }) => id === rowId)

    setVisible(true)
    setFilteredRowData(filterRow)
  }

  const arabicaAndRoubstaDataTable = (rowData) =>
    rowData?.map((ele) => {
      const { id, idMarket, contractName, lastChng, chng, percentageVal, volume, highRate, netHigh, lowRate, netLow, openRate, prevRate, openInterest, bid, bsize, ask, asize, optionExpiryStatus, firstNoticeDateStatus, optionExpiryDateFormat, firstNoticeDateFormat } = ele
      const isRobusta = idMarket === 1

      return (
        <tr key={id} className="data-tr">
          <td className="contract-name">{contractName}</td>
          <td className={lastChng >= 0 ? 'positive_green' : 'negative_red'}>
            <span>{isRobusta ? lastChng : formatNumber(lastChng)}</span>
          </td>
          <td className={chng >= 0 ? 'positive_green' : 'negative_red'}>
            {chng >= 0 ? '+' : ' '}
            <span>{isRobusta ? chng : formatNumber(chng)}</span>
          </td>
          <td>
            {percentageVal >= 0 ? '+' : ' '}
            <span>{isRobusta ? percentageVal : formatNumber(percentageVal)}</span>
          </td>
          <td>{volume}</td>
          <td>
            <span>{isRobusta ? highRate : formatNumber(highRate)}</span>
          </td>
          <td className={netHigh >= 0 ? 'positive_green' : 'negative_red'}>
            {netHigh >= 0 ? '+' : ' '}
            <span>{isRobusta ? netHigh : formatNumber(netHigh)}</span>
          </td>
          <td>
            <span>{isRobusta ? lowRate : formatNumber(lowRate)}</span>
          </td>
          <td className={netLow >= 0 ? 'positive_green' : 'negative_red'}>
            {netLow >= 0 ? '+' : ' '}
            <span>{isRobusta ? netLow : formatNumber(netLow)}</span>
          </td>
          <td>
            <span>{isRobusta ? openRate : formatNumber(openRate)}</span>
          </td>
          <td>
            <span>{isRobusta ? prevRate : formatNumber(prevRate)}</span>
          </td>
          <td>{openInterest}</td>
          <td>
            <span>{isRobusta ? bid : formatNumber(bid)}</span>
          </td>
          <td>{bsize}</td>
          <td>
            <span>{isRobusta ? ask : formatNumber(ask)}</span>
          </td>
          <td>{asize}</td>
          <td className={getOptionExpiryColor(optionExpiryStatus)}>{optionExpiryDateFormat}</td>
          <td className={getFirstNoticeDayColor(firstNoticeDateStatus)}>{firstNoticeDateFormat}</td>
          <td>
            <i className="pi pi-pencil" onClick={() => handleClick(rowData, id)} data-testid="edit-icon"></i>
          </td>
        </tr>
      )
    })

  function formatNumber(num) {
    return parseFloat(num).toFixed(2)
  }

  const handleSetResponse = (apiResponse) => {
    const modifiedData = iceMarketData.map((ele) => {
      if (filteredRowData.id === ele.id) {
        return apiResponse
      }

      return ele
    })

    setFilteredRowData({})
    setIceMarketData(modifiedData)
  }

  return (
    <LayoutContainer title={t('ICE_MARKET')} breadCrumbsTitle={'coffeeQuotesIceMarket'}>
      <div>
        <div className="coffee-quotes-container">
          <div className="card">
            <div className="tab-container">
              <TabView>
                <TabPanel header={t('ROBUSTA')}>
                  <p className="m-0">
                    <div className="coffee_quotes_screen_body">
                      <table className="robusta_and_arabica_table">
                        <tbody>{robustaAndArabicaTerminalTableHeaderTemplate('robusta')}</tbody>
                        {arabicaAndRoubstaDataTable(iceMarketData?.filter((ele) => ele.idMarket === 1))}
                      </table>
                    </div>
                  </p>
                </TabPanel>
                <TabPanel header={t('ARABICA')}>
                  <p className="m-0">
                    <div className="coffee_quotes_screen_body">
                      <table className="robusta_and_arabica_table">
                        <tbody>{robustaAndArabicaTerminalTableHeaderTemplate('arabica')}</tbody>
                        {arabicaAndRoubstaDataTable(iceMarketData?.filter((ele) => ele.idMarket === 2))}
                      </table>
                    </div>
                  </p>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
        {visible && <EditCoffeeQuotesData setVisible={setVisible} visible={visible} filteredRowData={filteredRowData} handleSetResponse={handleSetResponse} />}
      </div>
    </LayoutContainer>
  )
}

export default Loader(CoffeeQuotes)
