// https://translate.google.co.in/?sl=auto&tl=hi&text=Password%20Is%20Required&op=translate
const EN_LANGUAGES = {
  devInProgress: 'Dashboard is currently undergoing development.',
  welcomeToCW: 'Welcome to the CoffeeWeb Admin Console',
  COUNTRY: 'Country',
  COUNTRY_IS_REQUIRED: 'Country Name Is Required',
  PHONE: 'Phone',
  PHONE_IS_REQUIRED: 'Phone Number Is Required',
  LIST_PAYMENT_COUNTRY: 'LIST PAYMENT COUNTRY',
  COUNTRY_NAME: 'Country Name',
  PAYMENT_NAME: 'Payment Name',
  IS_ACTIVE: 'Is Active',
  FIRE_BASE_SETTING: 'FIREBASE SETTINGS',
  AUTH_DOMAIN: 'Auth Domain',
  AUTH_DOMAIN_REQUIRED: 'Auth Domain is required',
  API_KEY: 'Api Key',
  API_KEY_REQUIRED: 'Api Key is required',
  DATABASE_URL: 'Database Url',
  DATABASE_URL_REQUIRED: 'Database Url is required',
  PROJECT_ID: 'Project ID',
  PROJECT_ID_REQUIRED: 'Project id is required',
  STORAGE_BUCKET: 'Storage Bucket',
  STORAGE_BUCKET_REQUIRED: 'Storage Bucket is required',
  MESSAGING_SENDER: 'Messaging Sender Id',
  MESSAGING_SENDER_REQUIRED: 'Messaging Sender id is required',
  APP_ID: 'App ID',
  APP_ID_REQUIRED: 'App id is required',
  MEASUREMENT_ID: 'Measurement ID',
  MEASUREMENT_ID_REQUIRED: 'Measurement ID is required',
  UPDATE_BUTTON: 'Update',
  RESET: 'Reset',
  GO_BACK: 'Go Back',
  REJECT: 'Reject',
  APPROVE: 'Approve',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  PENDING_SA_APPROVAL: 'Pending For Super Admin Approval',
  TOOLTIP_USER_PREVIEW: 'User Preview',
  TOOLTIP_ADMIN_PREVIEW: 'Admin Preview',
  TOOLTIP_REJECT_REMARK_PREVIEW: 'Reject Remark Preview',
  VIEW_DOCUMENT: 'View Document',
  DOWNLOAD_DOCUMENT: 'Download Document',
  PLEASE_FILL_THIS: 'Please fill in this field',
  APPROVE_NEWS_REQUIRED: 'Are you sure you want to approve this news?',
  SEND_NOTIFICATION: 'Send Notification',
  CANCEL: 'Cancel',
  NOTIFICATION_PROCESS: 'Notification process is going on...',
  CLOSE: 'close',
  OPEN_FULL_SCREEN: 'Open Full Screen',
  SETTINGS: 'SETTINGS',
  OPTIONS_HEADING: 'OPTIONS',
  LIST_APP_SETTING: 'LIST APP SETTINGS',
  POSITIVE_COLOR: 'Positive Color',
  VALUE_REQUIRED: 'This value is required',
  NEGATIVE_COLOR: 'Negative Color',
  NEUTRAL_COLOR: 'Neutral Color',
  DATE_FORMAT: 'date Format',
  ROW_COLOR: 'Row Color',
  UPDATE_SUBMIT: 'Update Submit',
  SENDER_ID: 'Sender ID',
  SERVER_KEY: 'Server Key',
  APPLICATION_LOGO: 'Application Logo',
  MENU_LOGO: 'Menu Logo',
  DEFAULT_LOGO: 'Default flag',
  FLAG_URL: 'Flag Url',
  SHOW_SIGN_UP: 'Show Sign Up',
  SHOW_SHARE_BTN: 'Show Share Button',
  SHOW_LANGUAGE_BAR: 'Show Language Bar',
  ALLOW_EDIT_NEWS: 'Allow Edit News',
  SHOW_SUBSCRIPTION_END_DATE: 'Show Subscription End Date',
  RENEWAL_PROCESS: 'Show Process',
  ALLOW_EDIT_PROFILE: 'Allow Edit Profile',
  SHOW_VALID_TILL_DAYS: 'Show Valid Till Days',
  SHOW_LOGIN_OTP: 'Show Login Otp',
  NOTIFICATION: 'Notification',
  SHOW_WORK_BOOK: 'Show Work book',
  SHOW_LANGUAGE: 'show Language',
  PRODUCTION_RAZOR_PAY_KEY: 'Production Razor PayKey',
  SUBMIT: 'Submit',
  SELECT: 'Select Role',
  SELECT_A_PAYMENT: 'Select Payment',
  SEARCH_COUNTRY: 'Search country',
  // EDIT-MENU-PAGE
  IMAGE_UPLOAD: 'Drag and Drop a File Here To Upload',
  ADMIN_MENU: 'ADMIN MENU',
  ADD_ADMIN_MENU: 'ADD ADMIN MENU',
  CLIENT_MENU: 'CLIENT MENU',
  ADD_CLIENT_MENU: 'ADD CLIENT MENU',
  EDIT_ADMIN_MENU: 'EDIT ADMIN MENU',
  EDIT_CLIENT_MENU: 'EDIT CLIENT MENU',
  MENU: 'Menu',
  MENU_NAME: 'Menu Name',
  MENU_NAME_REQUIRED: 'Menu Name Is Required',
  MENU_URL: 'Menu Url',
  MENU_URL_REQUIRED: 'Menu Url Is Required.',
  PAGE_CODE: 'Page Code',
  PAGE_CODE_REQUIRED: 'Page Code Is Required',
  MENU_ICON: 'Menu Icon',
  EXISTING_IMG: 'Existing Image',
  NEW_IMG: 'New Image',
  MOBILE_PATH: ' Mobile Path',
  MOBILE_PATH_REQUIRED: 'Mobile Path is required',
  SCREEN_LIST: 'SCREEN LIST',
  SEARCH_BY_NAME: 'search by name',
  ADD_SCREEN: 'ADD SCREEN',
  SCREEN_NAME: 'Screen Name',
  ENTER_SCREEN_NAME: 'Enter Screen Name',
  SCREEN_NAME_IS_REQUIRED: 'Screen Name Is Required.',
  EDIT_SCREEN: 'EDIT SCREEN',
  ADD_USER: 'ADD USER',
  FIRST_NAME: 'First Name',
  ENTER_YOUR_FIRST_NAME: 'Enter Your First Name',
  FIRST_NAME_IS_REQUIRED: 'First Name Is Required',
  LAST_NAME: 'Last Name',
  ENTER_YOUR_LAST_NAME: 'Enter Your Last Name',
  LAST_NAME_IS_REQUIRED: 'Last Name Is Required',
  EMAIL: 'Email',
  ENTER_YOUR_EMAIL: 'Enter Your Email',
  PLEASE_ENTER_VALID_EMAIL: 'Please Enter Valid Email',
  OCCUPATION: 'Occupation',
  OCCUPATION_IS_REQUIRED: 'Occupation Is Required',
  USER_ROLE: 'User Role',
  USER_ROLE_IS_REQUIRED: 'User Role Is Required',
  STATE_NAME: 'State Name',
  STATE_IS_REQUIRED: 'State Is Required',
  PROFILE_INFORMATION: ' Profile Information',
  PAYMENT_TYPE: 'LIST PAYMENT',
  ADD_NEW_PAYMENT: 'ADD PAYMENT',
  ENTER_PAYMENT_NAME: 'Enter Payment Name',
  PAYMENT_NAME_IS_REQUIRED: 'Payment Name is required',
  IMAGE: 'Image',
  IMAGE_IS_REQUIRED: 'Image is required',
  NEW_IMAGE: 'New Image',
  SHOWING: 'Showing',
  TO: 'to',
  OF: 'of',
  RESULTS: 'Results',
  FIREBASE_SETTINGS: 'FIREBASE SETTINGS',
  FIRST: 'First',
  BACK: 'Back',
  NEXT: 'Next',
  LAST: 'Last',
  FIRST_PAGE: 'First page',
  PRE_PAGE: 'Pre page',
  NEXT_PAGE: 'Next page',
  LAST_PAGE: 'Last page',
  EDIT: 'Edit',
  CONFIG: 'Config',
  ADD_PAYMENT_COUNTRY: 'ADD PAYMENT COUNTRY',
  ADD_ROLES: 'ADD ROLE',
  ROLE_FEATURE: 'ROLE FEATURES',
  ROLE_NAME: 'Role Name',
  ROLE_CODE: 'Role Code',
  ADMIN_ROLE: 'Admin Role:',
  SCREENS: 'Screens',
  SAVE: 'Save',
  ADD: 'Add',
  UPDATE: 'Update',
  SUBSCRIPTION_PAYMENT_INFO: 'The regular subscription is the default freemium subscription, meaning no payment is necessary for this subscription.',
  SUBSCRIPTION_CONFIG: 'SUBSCRIPTION CONFIG',
  PLEASE_SELECT_A_ROLE_TYPE: 'Please Select Role Type',
  ROLE_NAME_IS_REQUIRED: 'Role Name Is Required',
  ROLE_CODE_IS_REQUIRED: 'Role Code Is Required',
  PAYMENT_IS_REQUIRED: 'Payment Is Required',
  LOGOUT: 'Logout',
  DO_YOU_WANT_TO_LOGOUT: 'Do you want to logout?',
  NO: 'No',
  Yes: 'YES',
  ADD_NEWS_FEED: 'ADD NEWS FEED',
  NEWS_AUTHOR: 'News Author',
  NEWS_HEADING: 'News Heading',
  TRENDING_NEWS: 'Trending News',
  BOLD: 'Bold',
  FREE_NEWS: 'Free News',
  FULL_NEWS: 'Full News',
  SELECT_REASON: 'Select Reason',
  PLEASE_CHOOSE_A_REASON: 'Please Choose A Reason',
  ENTER_CUSTOM_REASON: 'Enter Your Custom Reason',
  CHOOSE_REASON: 'Choose A Reason',
  ENTER_REASON: 'Enter Reason',
  TRENDING_NEWS_COLORS: 'Trending News Colors',
  TRENDING_NEWS_HOURS: 'Trending News Hours',
  SHORT_DESCRIPTION: 'Short Description',
  LANGUAGE: 'Language',
  LANGUAGE_IS_REQUIRED: 'Language Name Is Required',
  SUBSCRIPTION_TYPE: 'Subscription Type',
  PLEASE_SELECT_SUBSCRIPTION_TYPE: ' Please Select Subscription Type',
  SUBSCRIPTION_LOCK: 'Subscription Lock',
  SELECT_ALL: ' Select All',
  SELECT_IMAGE: 'Select Image',
  DEFAULT_IMAGE: 'Default Image',
  PLEASE_SELECT_COUNTRY_TYPE: 'Please Select Country Type',
  NEWS_THUMBNAIL_IMAGE: 'News Thumbnail Image',
  BROWSE: 'Browse',
  UPLOAD_DOCUMENT: 'Upload Document',
  HOURS_LIMIT: 'Value Must Be 200 Or Less',
  HOURS_IN_NUMBER: 'Please Enter A Number Only',
  EDIT_NEWS_FEED: 'EDIT NEWS FEED',
  PUBLISHED_DATA: 'Published Date',
  ADMIN_PREVIEW: 'ADMIN PREVIEW ',
  USER_PREVIEW: 'User Preview',
  ADMIN_NEWS_APPROVAL_SCREEN: 'ADMIN NEWS APPROVAL SCREEN',
  SUPER_ADMIN_NEWS_APPROVAL_SCREEN: 'SUPER ADMIN NEWS APPROVAL SCREEN',
  SEARCH: 'Search',
  NEWS_SUBJECT: 'News Subject',
  NEWS_DATE: 'News Date',
  CHOOSE: 'Choose',
  GLOBAL_COFFEE_PRICE: 'Global Coffee Price',
  PREVIEW: 'Preview',
  USER_DETAILS: 'View User Details',
  GLOBAL_DIFFERENTIAL: 'Global Differentials',
  DOWNLOAD_COUNTRY_LIST: 'Download Country List',
  DOWNLOAD_TEMPLATE: 'Download Template',
  BROWSE_WORKSHEET_FILES: 'Browse worksheet Files',
  UPLOAD_FILE: ' Upload File',
  GLOBAL_FREIGHT_RATES: 'Global Freight Rates',
  GLOBAL_RAW_COFFEE_PRICES: 'Global Raw Coffee Prices',
  SELECT_YOUR_CURRENCY: 'Select Your Currency',
  PRICE_PER_BAG: 'Price Per Bag',
  STANDARDS: 'Standards',
  REMARKS: 'Remarks',
  FOB_PORT: 'FOB Port',
  FOREX_VALUE: 'Forex Value',
  FOREX_TYPE: 'Forex Type',
  SIZE: 'Size',
  DIFFERENTIALS_BASED_ON_FOB: 'Differentials Based on FOB',
  QUALITY: 'Quality',
  BETWEEN: 'Between',
  BAG_WEIGHT: 'Bag Weight',
  MC: 'Mc',
  OUT_TURN: 'Out Turn',
  ACCOUNT_SETTINGS: 'Account Settings',
  DATE_FORMAT_IS_REQUIRED: 'Date Format Is Required',
  TIME_FORMAT: 'Time Format',
  TIME_IS_REQUIRED: 'Time Is Required',
  EN_US: 'en-us',
  LANDING_PAGE: ' Landing Page',
  LANDING_PAGE_IS_REQUIRED: 'Landing Page Is Required',
  TIME_ZONE: ' Time Zone',
  TIME_ZONE_IS_REQUIRED: 'Time Zone Is Required',
  UPDATE_FORM: ' Update For ',
  ENTER_MOBILE_WEB: 'Config For Device - Mobile/Browser',
  SHOW_MOBILE_NOTIFICATION: 'Show Mobile Notification',
  SHOW_WEB_NOTIFICATION: ' Show Web Notification',
  DATE_TIME_LABEL: 'Date Time Format',
  DELETE_ACCOUNT: 'Delete Account',
  DELETE_TRADING_ACCOUNT: 'Delete Trading Account',
  DELETE_ACCOUNT_DESCRIPTION: 'Once you delete trading account, need to re-initiate the verification process to get a new one later. All data belongs to the current trading account will be deleted permanently',
  DELETE_ACCOUNT_HEADING: 'Delete Account',
  DELETE_ACCOUNT_ALERT: 'If you delete your account, there is no getting it back. Make sure you want to do this',
  DELETE_ACCOUNT_ALERT_MESSAGE: 'Are you sure you want to delete your account?',
  YES: ' Yes',
  EDIT_USER: 'EDIT USER',
  ENTER_TEXT: 'Enter text',
  STATE_LIST: 'State List',
  RESET_BUTTON: 'Reset',
  USER_LIST: 'USER LIST',
  CLIENT: 'Client',
  CONTRIBUTOR: 'Contributor',
  USER: 'User',
  SUBSCRIPTION: 'Subscription',
  STATES: 'State',
  YES_DELETE: ' Yes, Delete',
  LIST_SCREEN_LANGUAGE: 'LIST SCREEN LANGUAGE',
  SCREEN_IS_REQUIRED: 'screen is required',
  ADD_MAPPING: 'ADD MAPPING',
  SCREEN_CONTROLLER_NAME: 'Screen Controller Name',
  LANGUAGE_NAME_IS_REQUIRED: 'Language Name Is Required',
  LANGUAGE_TEXT: 'Language Text',
  LANGUAGE_TEXT_IS_REQUIRED: 'Language Text Is Required',
  CONTROLLER_NAME_IS_REQUIRED: 'Screen Controller Name Is Required',
  ENTER_LANGUAGE_NAME: 'Enter Language Name',
  LIST_MAPPING: 'LIST MAPPING',
  BUSINESS_TYPE: 'Business Type',
  COMPANY_NAME: 'company Name',
  PLACE: 'Place',
  RAW_COFFEE_PRICES: 'Raw Coffee Prices',
  OUT_TURN_INTO_EP_PRICES: 'Out turn into EP prices',
  PRICE_VALID_TILL: 'Price Valid Till',
  PRICE_QUOTED_FOR: 'Price Quoted For',
  CONTACT: 'Contact',
  PRICES_ARE_QUOTES_AS_PER_INDIAN_STANDARD: 'Prices are quotes as per indian standard',
  ROBUSTRA_CHERRY_OT_AND_ARABICA_CHERRY_OT: 'Robustra Cherry 26 OT and Arabica Cherry 27 OT',
  COFFEE_PRICE_APPROVAL: 'COFFEE PRICE APPROVAL',
  BROWSE_FILES: 'Browse Files',
  IMAGE_URL: 'Payment Image',
  EDIT_PAYMENT: 'Edit payment',
  EXISTING_IMAGE: 'Existing Image',
  USER_FULL_NAME: 'User FullName',
  COMPANY_DETAILS: 'Company Details',
  COMPANY_APPROVAL: 'COMPANY APPROVAL',
  ARABICA_PARCHMENT: 'ArabicaParchment',
  ARABICA_CHERRY: 'ArabicaCherry',
  ROBUSTA_PARCHMENT: 'RobustaParchment',
  ROBUSTA_CHERRY: 'RobustaCherry',
  ROBUSTA_CHERRY_EP: 'RobustaCherry EP',
  XE_TABLE_DATA: 'XETable Data',
  UPLOAD: 'Upload',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'Are you sure you want to delete',
  EDIT_NEWS_FEED_REMARK: 'REJECT REMARK PREVIEW',
  REMARK_DESCRIPTION: 'Reject Remark Description',
  AVAILABLE_COLORS: 'Available Colors',
  BEST_VIEWED_RESOLUTION: 'Best viewed resolution',
  CLICK: 'Click',
  HERE: 'here',
  TO_RESIZE_IMAGE_ONLINE: 'to resize image online',
  WIDTH: 'Width',
  HEIGHT: 'Height',
  EDIT_NEWS: 'EDIT NEWS',
  NO_AUTHOR_OR_SUBJECT_FOUND: 'No Author or Subject found',
  VIEWS: 'Views',
  ENTER_OTP: 'Enter OTP',
  PLEASE_ENTER_OTP: 'Please Enter OTP.',
  LIST_NEWS_FEED: 'LIST NEWS FEED',
  ALL_LANGUAGE: 'All Language',
  DELETE: 'Delete',
  SUCCESS: 'Success',
  NEWS_DELETED_SUCCESSFULLY: 'News Deleted Successfully',
  FAILURE: 'Failure',
  FAILED_TO_DELETE_NEWS_FEED: 'Failed to Delete News Feed',
  LANGUAGES: 'Languages',
  TRENDING_NEWS_REJECT: 'TRENDING NEWS REJECT',
  MOBILE_VERSION_ADDED_SUCCESSFULLY: 'Mobile Version Added Successfully.',
  DATA_ALREADY_EXIST: 'Data Already Exist',
  PLEASE_ENTER_ANOTHER_BUILD_NUMBER: 'please enter another Build Number',
  FAILED_TO_ADD_MOBILE: 'Failed To Add Mobile Version. Please Try Again Later.',
  ADD_MOBILE_VERSION: 'ADD MOBILE VERSION',
  CURRENT_VERSION: 'Current Version',
  ENTER_CURRENT_VERSION: 'Enter Current Version',
  CURRENT_VERSION_IS_REQUIRED: 'Current Version is required',
  BUILD_NUMBER: 'Build Number',
  ENTER_BUILD_NUMBER: 'Enter Build Number',
  BUILD_NUMBER_IS_REQUIRED: 'Build Number Is Required.',
  MOBILE_OS: 'Mobile OS',
  ENTER_MOBILE_OS: 'Enter Mobile Os',
  MOBILE_OS_IS_REQUIRED: 'Mobile OS is required',
  BUILD_DATE: 'Build Date',
  IS_FORCE_UPDATE: 'Is ForceUpdate',
  BUILD_DATE_IS_REQUIRED: 'Build Date Is Required.',
  ADD_MOBILE: 'ADD MOBILE VERSION',
  LIST_MOBILE_VERSION: 'LIST MOBILE VERSION',
  ICE_MARKET_DATA: 'Ice Market Data',
  GLOBAL_DIFFERENTIALS: 'GLOBAL DIFFERENTIALS',
  SELECT_ORIGIN: 'SELECT ORIGIN',
  CROP_2021_2022: 'CROP : 2021-2022',
  UPDATED_ON: 'UPDATED ON',
  NEXT_UPDATE: 'NEXT UPDATE',
  PRICES_BASED_ON_FOB: 'PRICES BASED ON FOB',
  DIFFERENTIALS: 'Differentials',
  QUALITY_STANDARDS: 'Quality Standards',
  MOISTURE: 'Moisture',
  SCREEN_RETENTION: 'Screen Retention',
  DEFECTS: 'Defects',
  CENTS: 'CENTS',
  SUBSCRIPTION_IS_REQUIRED: 'Subscription Is Required',
  AMOUNT: 'Amount',
  AMOUNT_IS_REQUIRED: 'Amount Is Required',
  SUBSCRIPTION_CURRENCY: 'Subscription Currency',
  SUBSCRIPTION_CURRENCY_IS_REQUIRED: 'Subscription Currency Is Required',
  SUBSCRIPTION_CODE: 'Subscription Code',
  SUBSCRIPTION_CODE_IS_REQUIRED: 'Subscription Code Is Required',
  BASE_PRICE_FOR_IOS: 'Base Price For IOS',
  BASE_PRICE_FOR_IOS_IS_REQUIRED: 'Base Price For IOS Is Required',
  IN_APP_PURCHASE_ID: 'In App Purchase ID',
  APP_PURCHASE_IS_REQUIRED: 'In App Purchase Is Required',
  AMOUNT_IN_DOLLARS: 'Amount In Dollars',
  RENEW_PROCESS: 'Renew Process',
  RENEW_IS_REQUIRED: 'Renew Process Is Required',
  SUBSCRIPTION_COLOR: 'Subscription Color',
  COLOR_IS_REQUIRED: 'Subscription Color Is Required',
  EDIT_SUBSCRIPTION: 'EDIT SUBSCRIPTION',
  MAP_MENU: 'Map Menu',
  MENUS: 'Menus',
  SUBSCRIPTION_TYPES: 'SUBSCRIPTION TYPES',
  ADD_SUBSCRIPTION: 'ADD SUBSCRIPTION',
  SUBSCRIPTION_FEATURES: 'SUBSCRIPTION FEATURES',
  ORDER_BY: 'Order By',
  ORDER_BY_IS_REQUIRED: 'Order By Is Required',
  ROLE_ADDED_SUCCESSFUL: 'Role Added Successfully!',
  SORRY_COULD_NOT_ADD_THE_DATA: 'Sorry, could not Add the Data',
  PLEASE_ENTER_ANOTHER_ROLE: 'Please enter another Role',
  DISMISS: 'Dismiss',
  NEWS_FEED_ADDED_SUCCESSFULLY: 'News Feed Added Successfully.',
  FAILED_TO_ADD_NEWS_FEED: 'Failed To Add News Feed.',
  NEWS_FEED_UPDATED_SUCCESSFULLY: 'News Feed Updated Successfully.',
  FAILED_TO_UPDATE_NEWS_FEED: 'Failed To Update News Feed',
  FAILED_TO_DELETE_IMAGE: 'Failed To Delete Image',
  FAILED_TO_UPLOAD: 'Failed To Upload File',
  NAME_OF_THE_DOCUMENT_FILE: 'Name Of The Document File',
  NAME_OF_THE_IMAGE_FILE: 'Name Of The Image File',
  GET_API_FAILED: 'Sorry, Could not Fetch The Data Please Try Again later',
  USER_ADDED_SUCCESSFULLY: 'User Added Successfully.',
  NULL: 'NULL',
  PLEASE_TRY_AFTER_SOMETIME: 'Please Try After Sometime',
  SOMETHING_WENT_WRONG: 'Something Went Wrong!',
  NUMBER: 'Number',
  ENTER_ANOTHER_MAIL_ID: 'Enter Another Mail ID',
  ENTER_ANOTHER_PHONE_NUMBER: 'Enter Another Phone Number',
  EMAIL_IS_REQUIRED: 'Email Is Required',
  INVALID_EMAIL_ADDRESS: 'Invalid Email Address E.g.example@email.com',
  SORRY_COULD_NOT_FETCH_THE_DATA_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Fetch The Data. Please Try Again Later.',
  ERROR_FETCHING_FOR_OCCUPATION: 'Error Fetching Occupation Types Please Try Again Later',
  ERROR_FETCHING_FOR_USER_ROLE: 'Error Fetching User Role Types Please Try Again Later',
  ERROR_FETCHING_FOR_CHECK_EMAIL_EXIST: 'Error Fetching Email Exist Please Try Again Later',
  ERROR_FETCHING_FOR_CHECK_PHONE_NUMBER: 'Error Fetching Phone Number Please Try Again Later',
  ERROR_FETCHING_FOR_STATE: 'Error Fetching State Types Please Try Again Later.',
  ERROR_FETCHING_FOR_SUBMIT: 'An Error Occurred While Submitting The Data Please Try Again Later.',
  OK: 'OK',
  FIREBASE_DATA_UPDATE_SUCCESS: 'Firebase Data Updated Successfully!',
  MAJOR_VERSION: 'Major Version',
  ENTER_MAJOR_VERSION: 'Enter Major Version',
  MAJOR_VERSION_IS_REQUIRED: 'Major Version Is Required.',
  MINOR_VERSION: 'Minor Version',
  ENTER_MINOR_VERSION: 'Enter Minor Version',
  MINOR_VERSION_IS_REQUIRED: 'Minor Version Is Required.',
  PATCH_VERSION: 'Patch Version',
  ENTER_PATCH_VERSION: 'Enter Patch Version',
  PATCH_VERSION_IS_REQUIRED: 'Patch Version Is Required.',
  VERSION_NAME: 'Version Name',
  LAUNCH_PLATFORM: 'Launch Platform',
  LAUNCH_PLATFORM_IS_REQUIRED: 'Launch Platform Is Required.',
  RELEASE_NOTES: 'Release Notes',
  ENTER_RELEASE_NOTES: 'Enter Release Notes',
  RELEASE_NOTES_IS_REQUIRED: 'Release Notes Is Required.',
  MARKET_NAME: 'Market Name',
  CONTRACT_NAME: 'Contract Name',
  DATE: 'Date',
  HIGH_RATE: 'High Rate',
  LOW_RATE: 'Low Rate',
  OPEN_RATE: 'Open Rate',
  CLOSE_RATE: 'Close Rate',
  NET_CHANGE: 'Net Change',
  VOLUME: 'Volume',
  COMPARE_TO_PREVIOUS_DAY: 'Compare To Previous Day',
  COMPARE_TO_PREVIOUS_ONE_DAY: 'Compare To Previous One Day',
  OPEN_INTEREST: 'Open Interest',
  TERMINAL_DATA_TABLE_HEADING: 'TERMINAL DATA TABLE',
  HIGH_RATE_FIELD: 'Enter High Rate',
  LOW_RATE_FIELD: 'Enter Low Rate',
  OPEN_RATE_FIELD: 'Enter Open Rate',
  CLOSE_RATE_FIELD: 'Enter Close Rate',
  NET_CHARGE_FIELD: 'Enter Net Charge',
  VOLUME_FIELD: 'Enter Volume',
  COMPARE_TO_PREVIOUS_DAY_FIELD: 'Enter Previous Day',
  COMPARE_TO_PREVIOUS_ONE_DAY_FIELD: 'Enter Previous One Day',
  OPEN_INTEREST_FIELD: 'Enter Open Interest',
  RETRY: 'Retry',
  ERROR_FETCHING_GENERATED_AUTH_Token: 'Error Fetching For Generated Auth Token',
  NO_DATA_FOUND: 'Sorry, Could Not Fetch The Data. Please Try Again Later.',
  FIREBASE_DATA_UPDATE_FAIL: 'Failed to Update Firebase Data!',
  NETWORK_ERROR: 'Network Error',
  ERROR: 'Error',
  UNABLE_TO_FETCH_COUNTRY_DATA: 'Unable to fetch Country Data.',
  PLEASE_ENTER_ANOTHER_SCREEN_NAME: 'Please Enter Another Screen Name',
  FAILED_TO_CONNECT_TO_THE_SERVER: 'Failed to connect to the server',
  SCREEN_ADDED_SUCCESSFUL: 'Screen Added Successful',
  ARE_YOU_SURE_YOU_WANT_TO_REJECT_TRENDING_NEWS: 'Are You Sure, You Want To Reject Trending News?',
  SORRY_COULD_NOT_ADD_THE_DATA_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Add The Data. Please Try Again Later.',
  ARE_YOU_SURE_YOU_WANT_TO_PROCEED: 'Are You Sure, You Want To Proceed?',
  SUBSCRIPTION_DATA_ADDED_SUCCESSFULLY: 'Subscription Added Successfully.',
  AMOUNT_IN_DOLLARS_IS_REQUIRED: 'Amount In Dollars Is Required',
  ERROR_FETCHING: 'Error While Fetching List Map Data, Please Try Again Later.',
  SCREEN_DETAILS_ADDED_SUCCESSFULLY: 'Screen Details Added Successfully!',
  DATA_ALREADY_EXISTS: 'Data Already Exists!',
  SCREEN_ADDED_SUCCESSFULLY: 'Screen Added Successfully.',
  ERROR_TOAST: 'error',
  PLEASE_ENTER_ANOTHER_PAYMENT_NAME: 'Please Enter Another Payment Name',
  PLEASE_ENTER_ANOTHER_MENU_NAME: 'Please Enter Another Menu Name',
  PLEASE_SELECT_ANOTHER_PAYMENT_NAME: 'Please Select Another Payment Name',
  PAYMENT_FOR_COUNTRY_ADDED_SUCCESSFULLY: 'Payment For Country added Successfully.',
  LIST_PAYMENT_COUNTRY_MAPPING_ERROR: 'Error Fetching List Payment Mapping. Please Try Again Later',
  SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Add The Data. Please Try Again Later.',

  // Global Data
  FAILED_TO_ADD_GLOBAL_DIFFERENTIALS: 'Failed To Add Global Differentials',
  FAILED_TO_ADD_GLOBAL_RAW_COFFEE_PRICE: 'Failed To Add Global Raw Coffee Price',
  GLOBAL_DATA: 'GLOBAL DATA',
  PRODUCING_COUNTRY: 'Producing Country',
  EXCEL_DATA_ADDED_SUCCESSFULLY: 'Excel Data Added Successfully!',
  CLICK_HERE_TO_DOWNLOAD_THE_EMPTY_TEMPLATE: 'Click here to download the empty template',
  INVALID_EXCEL_FORMAT: 'Invalid Excel Format, Please Upload The Correct Format.',
  PLEASE_SELECT_A_CURRENCY: 'Currency Is Required',
  GLOBAL_DIFFERENTIAL_USER_PREVIEW: 'Global Differential User Preview',

  UPLOAD_FILE_HERE: 'Upload File Here',
  CHOOSE_A_CURRENCY: 'Choose a Currency',
  CLEAR: 'Clear',
  TRENDING_NEWS_DEACTIVATED_SUCCESSFULLY: 'Trending News, Deactivated Successfully.',
  FAILED_TO_DEACTIVATE_TRENDING_NEWS: 'Failed To Deactivate Trending News, Please Try Again Later.',
  FAILED_ACTIVATION_STATUS: 'Failed To Change The Activation Status. Please Try Again.',
  APPROVE_GLOBAL_DATA: 'APPROVE GLOBAL DATA',
  ARE_YOU_SURE_YOU_WANT_TO_APPROVE_GLOBAL_DIFFERENTIALS: 'Are You Sure, You Want To Approve Global Differentials.',
  ARE_YOU_SURE_WANT_TO_DELETE_THIS_ROW: 'Are You Sure Want To Delete This Row?',
  GLOBAL_DIFFERENTIALS_APPROVED_SUCCESSFULLY: 'Global Differentials Approved Successfully',
  FAILED_TO_APPROVE_GLOBAL_DIFFERENTIALS: 'Failed To Approve Global Differentials',
  CROP_YEAR: 'Crop Year',
  QUALITY_NAME: 'Quality Name',
  QUALITY_CODE: 'Quality Code',
  RANGE_STARTS: 'Range Starts',
  RANGE_ENDS: 'Range Ends',
  RANGE_START: 'Range Start',
  RANGE_END: 'Range End',
  CONVERTED_RANGE_START: 'Conv Range Start',
  CONVERTED_RANGE_END: 'Conv Range End',
  REMARK: 'Remark',
  UPDATED_DATE: 'Updated Date',
  TERMINAL: 'Terminal',
  TERMINAL_LEVEL: 'Terminal Level',
  SCREEN: 'Screen',
  QUALITY_GROUP: 'Quality Group',
  NO_DATA_FOUND_FOR_THIS_COUNTRY: 'No Data Found For This Country',
  INDIA_RAW_COFFEES: 'INDIA RAW COFFEES',
  CONFIRM_THE_ACTIVATION_OF_PAYMENT: 'Can You Confirm The Activation Of This Payment? ',
  CONFIRM_THE_DEACTIVATION_OF_PAYMENT: 'Can You Confirm The Deactivation Of This Payment?',
  DEACTIVATE: 'Deactivate',
  ARE_YOU_SURE_YOU_WANT_TO_EDIT_THIS_NEWS: 'Are You Sure? You Want To Edit This News.',
  SEND_OTP: 'Send OTP',
  ENTER_OTP_FOR_EDIT_NEWS: 'Enter OTP',
  OTP: 'OTP',
  SORRY_COULD_NOT_PROCEED_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Proceed. Please Try Again Later.',
  OTP_MISS_MATCH: 'Sorry, The Otp Is Incorrect. Please Enter A Valid Otp.',
  SORRY_UNABLE_TO_SEND_OTP_PLEASE_TRY_AGAIN_LATER: 'Sorry, Unable To Send OTP. Please Try Again Later.',
  OTP_SENT_SUCCESSFULLY: 'OTP Sent Successfully',
  ERROR_WHILE_FETCHING_SUBSCRIPTION_CURRENCY: 'Error While Fetching Subscription Currency',
  ERROR_WHILE_FETCHING_COUNTRY: 'Error While Fetching Country',
  SELECT_CURRENCY: 'Select Currency',
  ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_SUBSCRIPTION: 'Are You Sure You Want To Deactivate Subscription?',
  CURRENCY_TYPE: 'Currency',
  APP_SETTINGS_DATA_UPDATED: 'App Details Updated Successfully',
  APP_SETTINGS_DATA_UPDATE_FAIL: 'Failed To Update App Details',
  VALUE_IS_REQUIRED: 'Value Is Required',
  WORK_BOOK: 'WORK BOOK',
  COFFEE_QUOTES: 'COFFEE QUOTES',
  ERROR_WHILE_FETCHING_CONTRACT_NAME: 'Error while fetching Contract Name.',
  TERMINAL_DATA_ADDED_SUCCESSFULLY: 'Terminal Data added Successfully.',
  ADD_TERMINAL_DATA: 'ADD TERMINAL DATA',
  EDIT_TERMINAL_DATA: 'EDIT TERMINAL DATA',
  ACTIONS: 'Actions',
  TERMINAL_DATA: 'TERMINAL DATA',
  DATE_CONTRACT_NAME_ERROR: 'Date And Contract Name Combination Already Exists',
  SHOW_RENEWAL: 'Show Renewal',
  OPTIONS: 'Options',
  STATUS: 'Status',
  ERROR_WHILE_FETCHING_SCREEN_NAME: 'Error While Fetching Screen Name.',
  ERROR_WHILE_FETCHING_LANGUAGE_NAME: 'Error While Fetching Language Name.',
  MAP_DATA_ADDED_SUCCESSFULLY: 'Map Menu Added Successfully.',
  SELECT_SCREEN_ID: 'Select Screen Name',
  SELECT_LANGUAGE_ID: 'Select Language Name',
  LANGUAGE_NAME: 'Language Name',
  CONFIRMATION: 'Confirmation',
  OKAY: 'Ok',
  ERROR_FETCHING_SUBSCRIPTION_TYPES_PLEASE_TRY_AGAIN_LATER: 'Error While Fetching Subscription Types, Please Try Again Later.',
  ROLE_TYPE: 'Role Type',
  ERROR_FETCHING_ROLE_TYPE_PLEASE_TRY_AGAIN_LATER: 'Error While Fetching Role List, Please Try Again Later',
  ERROR_WHILE_FETCHING_ROLE_TYPE: 'Error While Fetching Role Type',
  LANGUAGE_LIST: 'LANGUAGE LIST',
  IN_LOCAL: 'In Local',
  FOR_NEWS: 'For News',
  ERROR_FETCHING_LIST_LANGUAGE_PLEASE_TRY_AGAIN_LATER: 'Error While Fetching List Language, Please Try Again Later.',
  ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_LANGUAGE: 'Are You Sure You Want To Deactivate Language?',
  ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_NEWS: 'Are you sure you want to deactivate news?',
  FAILED_TO_ACTIVATE_INACTIVATE_LANGUAGE: 'Sorry, could not change the status. Please try again later.',
  SELECT_COUNTRY: 'Select Country',
  SELECT_OCCUPATION: 'Select Occupation',
  SELECT_USER_ROLE: 'Select User Role',
  SELECT_STATE_NAME: 'Select State',
  INVALID_PHONE_NUMBER: 'Invalid Phone Number',
  EMAIL_EXIST: 'Email already exists',
  PLEASE_ENTER_A_VALID_EMAIL: 'Please Enter Valid Email.',
  FAILED_TO_ACTIVATE_INACTIVATE: 'Sorry, Could Not Change Payment Method Status. Please Try Again Later.',
  USERS_WERE_NOT_ADDED_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Add The User. Please Try Again Later.',
  ARE_YOU_SURE_YOU_WANT_TO_SWITCH_TABS: 'You have unsaved changes. Are you sure you want to switch tabs?',
  ADD_WORK_BOOK_NUMBER: 'Add Work Book Number',
  ADD_COFFEE_QUOTE_NUMBER: 'Add Coffee Quotes Number',
  ERROR_WHILE_FETCHING_COUNTRY_DATA: 'Error while fetching country data. Please try again later.',
  ERROR_WHILE_FETCHING_GLOBAL_DIFFERENTIAL_DATA: 'Error while fetching global differential data. Please try again later.',
  NEWS_DELETE_FAILED: 'Sorry, could not delete the news. Please try again later',
  NEWS_DELETION_CONFIRMATION: 'Are you sure you want to delete this news?',
  ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_PAYMENT: 'Are You Sure You Want To Deactivate Payment?',
  FAILED_TO_ACTIVATE_DEACTIVATE: 'Sorry, Could Not Change Payment Type Status. Please Try Again Later',
  ADD_LANGUAGE: 'ADD LANGUAGE',
  IN_LOCAL_IS_REQUIRED: 'In local is required',
  SELECT_IN_LOCAL: 'Select In local',
  LANGUAGE_ADDED_SUCCESSFULLY: 'Language Added Successfully',
  PLEASE_ENTER_ANOTHER_LANGUAGE_NAME: 'Please Enter Another Language Name',
  ERROR_WHILE_FETCHING_IN_LOCAL: 'Error While Fetching In Local',
  FAILED_TO_ACTIVATE_INACTIVATE_MOBILE_STATUS: 'Sorry, Could Not Change Mobile Update Status Please Try Again Later.',
  CONFIRM_THE_DEACTIVATION_OF_MOBILE_UPDATE: 'Are You Sure, You Want To Deactivate This Mobile Version?',
  LIST_TERMINAL_DETAILS: 'LIST TERMINAL DETAILS',
  OPTION_EXPIRY: 'Option Expiry',
  FIRST_NOTICE_DAY: 'First Notice Day',
  WEEK_HIGH_RATE: '52 Week High Rate',
  WEEK_LOW_RATE: '52 Week Low Rate ',
  WEEK_HIGH_DATE: '52 Week High Date ',
  WEEK_LOW_DATE: '52 Week Low Date',
  CONFIRM_THE_DEACTIVATION_OF_TERMINAL_DETAILS_STATUS: 'Are You Sure, You Want To Deactivate The Terminal Detail?',
  FAILED_TO_ACTIVATE_INACTIVATE_TERMINAL_DETAILS_STATUS: 'Sorry, Could Not Change Terminal Details Status. Please Try Again Later.',
  RAW_COFFEES: 'Raw Coffees',
  DATA_FOR_THIS_CURRENCY_NOT_FOUND: 'Data for this currency not found.',
  FAILED_TO_APPROVE_DATA: 'Failed to approve data.',
  DATA_APPROVED_SUCCESSFULLY: 'Data Approved Successfully.',
  NOTHING_TO_APPROVE: 'The Data Is Already Approved.',
  COULD_NOT_APPROVE_THE_GLOBAL_RAW_COFFEE_DATA: 'Sorry, Could Not Approve The Global Raw Coffee Data. Please Try Again Later.',
  COULD_NOT_REJECT_THE_GLOBAL_RAW_COFFEE_DATA: 'Sorry, Could Not Reject The Global Raw Coffee Data. Please Try Again Later.',
  SELECT_LANGUAGE: 'Select Language',
  SELECT_SCREEN: 'Select Screen',
  ERROR_FETCHING_LIST_SCREEN_LANGUAGE_PLEASE_TRY_AGAIN_LATER: 'Error While Fetching List Screen Language. Please Try Again Later.',
  WEB_DISPLAY: 'Web Display',
  MOBILE_DISPLAY: 'Mobile Display',
  GUEST_WEB_DISPLAY: 'Guest Web Display',
  GUEST_MOBILE_DISPLAY: 'Guest Mobile Display',
  IS_LAUNCH: 'Is Launch',
  MENU_LIST: 'MENU LIST',
  SELECT_COUNTRIES_TO_BE_MAPPED_TO_MENUS: 'Select Countries To Be Mapped To {{menuName}}',
  GUEST_MENU_TOGGLE_FAILED: 'Sorry, Could Not Toggle Guest Menu Status. ',
  MENU_COUNTRY_MAPPED_DATA_LOAD_FAILED: 'Sorry, Could Not Fetch Menu With Country Mapped Data. Please Try Again Later.',
  MENU_COUNTRY_MAPPED_DATA_UPDATE_FAILED: 'Sorry, Could Not Update Menu With Country Mapped Data. Please Try Again Later.',
  COUNTRY_BASED_MENU: 'Country Based',
  ENABLE_COUNTRY_BASED_TO_MAP_FOR: 'Enable Country Based Mapping to Map For {{menuName}}',
  FAILED: 'FAILED',
  ADD_MENU: 'ADD MENU',
  LIST_ROLES: 'LIST ROLES',
  ROLE_FEATURES: 'Role Features',
  PAYMENT_TYPE_ADDED_SUCCESSFULLY: 'Payment has been successfully added.',
  ADD_COUNTRY_PAYMENT: 'Currently, there are no countries available for this payment. Click "Continue" to add one.',
  SUBSCRIPTION_TYPE_ADDED_SUCCESSFULLY: ' {{subscriptionName}} Subscription Added Successfully.',
  ADD_SUBSCRIPTION_DETAILS: 'Click on "Continue" to Configure.',
  FAILED_TO_UPLOAD_IMAGE: 'Failed To Upload Image',
  EDIT_NEW_PAYMENT: 'EDIT PAYMENT',
  TERMINAL_DETAILS_UPDATED_SUCCESSFULLY: 'Terminal Details Updated Successfully.',
  TERMINAL_DATA_UPDATE_FAIL: 'Failed To Update Terminal Data.',
  PHONE_NUMBER_EXIST: 'Phone Number Already Exists',
  TERMINAL_DETAILS_ERROR_MESSAGE: '{{dataField}} Is Required',
  WEEKS_HIGH_AND_LOW_ERROR_MESSAGE: '52 Weeks {{dataField}} Is Required',
  COMPARE_TO_PREVIOUS_IS_REQUIRED: 'Compare To Previous {{dataField}} Is Required',
  ADD_TERMINAL_DETAILS: 'ADD TERMINAL DETAILS',
  EDIT_TERMINAL_DETAILS: 'EDIT TERMINAL DETAILS',
  MARKET_TYPE: 'Market Type',
  TERMINAL_ID: 'Terminal ID',
  WEEKS_HIGH_RATE: '52 Weeks High Rate',
  WEEKS_HIGH_DATE: '52 Weeks High Date',
  WEEKS_LOW_RATE: ' 52 Weeks Low Rate',
  WEEKS_LOW_DATE: '52 Weeks Low Date',
  ROBUSTA: 'Robusta',
  ARABICA: 'Arabica',
  ADDED_TERMINAL_DATA_SUCCESSFULLY: 'Added Terminal Data Successfully.',
  COULD_NOT_ADD_TERMINAL_DATA: 'Sorry, Could Not Add Terminal Data. Please Try Again Later.',
  LIST_USERS_TITLE: 'LIST USERS',
  ADD_TERMINAL_DATA_TOOL_TIP: 'Add Terminal Data',
  USER_TYPE_CLIENT: 'CLIENTS',
  USER_TYPE_OTHER: 'CONTRIBUTORS',
  ACTIVATE_USER_CONFIRMATION: 'Are You Sure You Want To Activate The User?',
  DEACTIVATE_USER_CONFIRMATION: 'Are You Sure You Want To Deactivate The User?',
  USER_ACCOUNT_STATUS_CHANGE_FAIL: 'Sorry, Failed To Change User Account Status. Please Try Again Later',
  INVALID_INPUT: 'Invalid Input',
  MENU_IS_REQUIRED: 'Menu Is Required',
  SELECT_MENU: 'Select Menu',
  SEARCH_MENU: 'Search Menu',
  MENU_ADDED_SUCCESSFULLY: 'Menu Added Successfully',
  FAILED_TO_UPDATE_MENU: 'Sorry, Could Not Update The Menu. Please Try Again Later.',
  FAILED_TO_ADD_MENU: 'Sorry, Could Not Add The Menu. Please Try Again Later.',
  MENU_UPDATED_SUCCESSFULLY: 'Menu Updated Successfully',
  SELECT_GUEST_MENU_STATUS: 'Select Guest Menu Status',
  GUEST_MENU_FOR_WEB: 'Guest Menu For Web',
  GUEST_MENU_FOR_MOBILE: 'Guest Menu For Mobile',
  SUBSCRIPTION_PAYMENT_TYPE: 'SUBSCRIPTION PAYMENT TYPE',
  SELECT_SUBSCRIPTION: 'Select Subscription',
  SELECT_PAYMENT: 'Select Payment',
  SUBSCRIPTION_NAME_IS_REQUIRED: 'Subscription Name Is Required',
  PAYMENT_ID_IS_REQUIRED: 'Payment ID Is Required',
  ADDED_PAYMENT_MAPPING_SUCCESSFULLY: 'Subscription Payment Type Added Successfully.',
  EDIT_MAPPING: 'EDIT MAPPING',
  FAILED_TO_EDIT_SCREEN_CONTROL_MAPPING: 'Sorry, Could Not Update The Screen Control Mapping. Please Try Again Later.',
  SCREEN_CONTROL_MAPPING_UPDATED_SUCCESSFULLY: 'Screen Control Mapping Details Updated Successfully.',
  SUBSCRIPTION_PAYMENT_TYPE_WERE_NOT_ADDED: 'Sorry, Could Not Add The Subscription Payment Type. Please Try Again Later.',
  SCREEN_DATA_UPDATED_SUCCESSFULLY: 'Screen Data Updated Successfully.',
  FAILED_TO_UPDATE_SCREEN_DATA: 'Failed To Update Screen Data, Please Try Again Later.',
  PAYMENT_UPDATED_SUCCESSFULLY: ' Payment updated Successfully.',
  SUBSCRIPTION_TYPE_IS_REQUIRED: 'Subscription Type Is Required',
  ERROR_FETCHING_FOR_MAP_MENU: 'Sorry, Could Not Fetch Menus For Subscription. Please Try Again Later.',
  SORRY_COULD_NOT_UPDATE_DATA_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Update Subscription Data. Please Try Again Later.',
  SORRY_COULD_NOT_EDIT_THE_DATA_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Edit The Data. Please Try Again Later',
  ERROR_FETCHING_FOR_SUBSCRIPTION_FEATURE: 'Sorry, Could Not Fetch Subscription Features. Please Try Again Later.',
  EDIT_LANGUAGE: 'EDIT LANGUAGE',
  ENTER_LANGUAGE: 'Enter Language',
  LOCAL_LANGUAGE_NAME: 'Local Language Name',
  EDIT_COUNTRY_IS_REQUIRED: 'Country Is Required.',
  LOCAL_LANGUAGE_IS_REQUIRED: 'Local Language Is Required.',
  LANGUAGE_DETAILS_UPDATED_SUCCESSFULLY: 'Language Details Updated Successfully.',
  SORRY_UNABLE_TO_UPDATE_LANGUAGE_DETAILS: 'Sorry, Unable To Update Language Details. Please Try Again Later.',
  SORRY_COULD_NOT_LOGOUT_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Logout. Please Try Again Later.',
  EDIT_USER_TITLE: 'EDIT USER',
  VIEW_USER_TITLE: 'VIEW USER DETAILS',
  PROFILE_INFORMATION_TAB: 'PROFILE INFORMATION',
  ACCOUNT_SETTINGS_TAB: 'ACCOUNT SETTINGS',
  DELETE_ACCOUNT_TAB: 'DELETE ACCOUNT',
  DELETE_TRADING_ACCOUNT_MESSAGE: 'Are You Sure You Want To Delete Trading Account?',
  DELETE_TRADING_ACCOUNT_FAILED: 'Sorry, Could Not Delete Trading Account. Please Try Again Later',
  DELETE_ACCOUNT_FAILED: 'Sorry, Could Not Delete Account. Please Try Again Later',
  DELETE_TRADING_ACCOUNT_SUCCESS: 'Trading Account Deleted Successfully',
  DELETE_ACCOUNT_SUCCESS: 'Account Deleted Successfully',
  UPDATE_USER_FAILED: 'Sorry, Could Not Update User Details. Please Try Again Later',
  UPDATE_USER_SUCCESS: 'User Details Updated Successfully',
  PLEASE_ENTER_ANOTHER_DATA: 'Please Enter Another Data',
  SUBSCRIPTION_DATA_UPDATED_SUCCESSFULLY: 'Subscription Updated Successfully.',
  SUBSCRIPTION_FEATURE_ADDED_SUCCESSFULLY: 'Subscription Feature Added Successfully.',
  PLEASE_ENTER_ANOTHER_MOBILE_VERSION: 'Please, Enter Another Mobile Version.',
  ARE_YOU_SURE_YOU_WANT_TO_REJECT_THIS_NEWS: 'Are You Sure? You Want To Reject This News.',
  ARE_YOU_SURE_YOU_WANT_TO_APPROVE_THIS_NEWS: 'Are You Sure? You Want To Approve This News.',
  DELETE_REMARK: 'Reason For Deleting News',
  REJECT_REMARK: 'Reject Remark',
  ENTER_REMARK: 'Enter Remark',
  FORGOT_PASSWORD: 'Forgot Password?',
  SUPPORT: 'Support',
  DOWNLOAD_ON_THE: 'Download on the',
  APP_STORE: 'App Store',
  GET_IT_ON: 'Get it on',
  GOOGLE_PLAY: 'Google Play',
  LOGIN_THROUGH_OTP: 'LOGIN THROUGH OTP',
  SIGN_IN: 'SIGN IN',
  EMAIL_OR_PHONE_NUMBER_IS_REQUIRED: 'Email Or Phone Number Is Required',
  COUNTRY_CODE_IS_REQUIRED: 'Country Code Is Required',
  PASSWORD_IS_REQUIRED: 'Password Is Required',
  EMAIL_OR_PHONE: 'Email or Phone',
  PASSWORD: 'Password',
  LOGIN: 'Login',
  CHOOSE_A_COUNTRY: 'Choose a Country',
  PLEASE_SELECT_A_COUNTRY: 'Country Is Required',
  ERROR_WHILE_FETCHING_PAYMENT_NAME: 'Error While Fetching Payment Name',
  NEWS_APPROVED_SUCCESSFULLY: 'News Approved Successfully.',
  NEWS_REJECTED_SUCCESSFULLY: 'News Rejected Successfully.',
  FAILED_TO_APPROVE_THE_NEWS: 'Unable To Update The News Status.',
  PHONE_NUMBER_NOT_REGISTERED: 'Phone Number Is Not Registered',
  LOGIN_WITH: 'Login With',
  PHONE_NUMBER: 'Phone Number',
  RESEND_OTP: 'Resend OTP',
  REQUEST_FOR_OTP: 'Request For OTP',
  REQUEST_FOR_EMAIL: 'Request For Email Link',
  SUBMIT_OTP: 'Submit OTP',
  CONFIRM_PASSWORD: 'Confirm Password',
  OTP_IS_REQUIRED: 'OTP Is Required',
  CONFIRM_PASSWORD_IS_REQUIRED: 'Confirm Password Is Required',
  NEW_PASSWORD_IS_REQUIRED: 'New Password Is Required',
  CURRENT_PASSWORD_IS_REQUIRED: 'Current Password Is Required',
  PASSWORD_AND_CONFIRM_NEW_PASSWORD_SHOULD_BE_SAME: 'Password And Confirm Password Should Be Same',
  EMAIL_IS_NOT_REGISTERED: 'Email Is Not Registered',
  CHANGE_PASSWORD: 'Change Password',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  USER_TYPE: 'User Type',
  USER_EMAIL: 'User Email',
  USER_PHONE: 'User Phone',
  USER_PROFILE: 'USER PROFILE',
  SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER: 'Something Went Wrong. Please Try Again Later',
  PASSWORD_RESET_LINK_HAS_BEEN_SENT_TO_THE_EMAIL: 'Password Reset Link Has Been Sent To The Email',
  NEW_PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME: 'New Password And Confirm Password Should Be Same',
  USER_NOT_FOUND: 'User Not Found. Please Check The Phone Number And Try Again',
  FAILED_TO_SEND_RESET_LINK: 'Failed To Send Reset Link',
  FAILED_TO_UPDATE_PASSWORD: 'Unable To Reset Your Password. Please Try Again',
  FAILED_TO_SEND_OTP: 'Failed To Send OTP',
  INVALID_EMAIL_FORMAT: 'Enter A Valid Email',
  FAILED_TO_FETCH_THE_LOCAL_LANGUAGE_DATA: 'Failed To Fetch The Local Language Data. Please Try Again.',
  OCCUPATION_FETCH_FAILED: 'Unable To Fetch Occupation Data',
  USER_ROLE_FETCH_FAILED: 'Unable To Fetch User Role Data',
  STATE_FETCH_FAILED: 'Unable To Fetch State Data',
  EMAIL_EXIST_FETCH_FAILED: 'Unable To Fetch Email Data',
  PHONE_EXIST_FETCH_FAILED: 'Unable To Fetch Phone Number Data',
  COUNTRY_FETCH_FAILED: 'Unable To Fetch Country Data',
  SUBSCRIPTION_FETCH_FAILED: 'Unable To Fetch Subscription Id',
  PAYMENT_FETCH_FAILED: 'Unable To Fetch Payment Id',
  SCREEN_NAME_FETCH_FAILED: 'Unable To Fetch Screen Name',
  Language_FETCH_FAILED: 'Unable To Fetch Language Name',
  MARKET_TYPE_FETCH_FAILED: 'Unable To Fetch Market Type',
  CONTRACT_NAME_FETCH_FAILED: 'Unable To Fetch Contract Name',
  OPTION_EXPIRY_FETCH_FAILED: 'Unable To Fetch Option Expiry Date',
  FIRST_NOTICE_DAY_FETCH_FAILED: 'Unable To Fetch First Notice Day',
  TERMINAL_ID_FETCH_FAILED: 'Unable To Fetch Terminal Id',
  WEEKS_HIGH_RATE_FETCH_FAILED: 'Unable To Fetch 52 Weeks High Rate',
  WEEKS_HIGH_DATE_FETCH_FAILED: 'Unable To Fetch 52 Weeks High Date',
  WEEKS_LOW_RATE_FETCH_FAILED: 'Unable To Fetch 52  Weeks Low Rate',
  WEEKS_LOW_DATE_FETCH_FAILED: 'Unable To Fetch 52 Weeks Low Date',
  IS_ENABLED: 'Is Enabled',
  DEVICE_TYPE_IS_REQUIRED: 'Device Type Is Required',
  WORK_BOOK_DATA_UPDATED: 'Work Book Data Updated Successfully',
  WORK_BOOK_DATA_UPDATE_FAIL: 'Failed To Update Work Book Details',
  SERVICE_UPDATE_SUCCESS: 'Service Updated Successfully',
  SERVICE_UPDATE_FAILED: 'Service Failed Successfully',
  COFFEE_QUOTES_DATA_UPDATED: 'Coffee Quotes Data Updated Successfully',
  COFFEE_QUOTES_DATA_UPDATE_FAIL: 'Failed To Update Coffee Quotes Details',
  DRAG_AND_DROP_A_FILE_HERE_TO_UPLOAD: 'Drag and drop a file here to upload',
  UPDATE_USER_ACCOUNT_SETTINGS_FAILED: 'Sorry, Failed To Update User Account Settings',
  UPDATE_USER_ACCOUNT_SETTINGS_SUCCESS: 'User Account Settings Updated Successfully',
  SELECT_DATE_FORMAT: 'SelectDate Format',
  SELECT_LANDING_PAGE: 'Select Landing Page',
  SELECT_TIME_ZONE: 'Select Time Zone',
  ENABLE_NOTIFICATION_FOR_DEVICES: 'Enable Notification For Devices',
  DEVICE_DETAILS_MOBILE_BROWSER: 'Device Info: Mobile / Browser',
  MOBILE_LABEL: 'Mobile',
  BROWSER_LABEL: 'Browser',
  INVALID_CREDENTIALS: 'Invalid Credentials!',
  NEW_PASSWORD_UPDATED_SUCCESSFULLY: 'New Password Updated Successfully',
  ERROR_WHILE_FETCHING_MARKET_NAME: 'Error while fetching Market Name.',
  SUBSCRIPTION_NAME: 'Subscription Name',
  SUBSCRIPTION_PAYMENT_LIST: 'SUBSCRIPTION PAYMENT LIST',
  EDIT_SUBSCRIPTION_PAYMENT_LIST: 'EDIT SUBSCRIPTION PAYMENT',
  PAYMENT_MAPPING_UPDATED: 'Subscription Payment Mapping Updated Successfully',
  FAILED_TO_UPDATE_PAYMENT_MAPPING: 'Failed To Update Subscription Payment Mapping',
  CUSTOM_BUTTON: 'Custom Button',
  NO_SCREENS_FOR_THIS_SUBSCRIPTION: 'No Screens For This Subscription',
  LOCAL_LANGUAGE_NAME_REQUIRED: 'Local Language Name Is Required',
  ERROR_WHILE_FETCHING_SUBSCRIPTION_NAME: 'Error While Fetching Subscription Name',
  BASE_PRICE_IS_REQUIRED: 'Base Price Is Required',
  BASE_PRICE_WITHOUT_GST: 'Base Price (excluding gst)',
  AMOUNT_INCLUDING_GST: 'Amount (including gst)',
  BASE_PRICE: 'Base Price',
  GST: 'Gst',
  FILTER_MARKET_NAME: 'Filter By Market Name',
  ALL_MARKET: 'All Market Name',
  ROBUSTA_MARKET: 'Robusta',
  ARABICA_MARKET: 'Arabica',
  FILTER_NEWS: 'Filter by Status',
  FILTER_BY_LANGUAGE: 'Filter By Language',
  FAILED_TO_UPDATE_STATUS: 'Sorry, Could Not Change Trending News Status. Please Try Again Later.',
  FAILED_TO_UPDATE_SUBSCRIPTION_PAYMENT: 'Failed To Update Subscription Payment.',
  NO_DATA_FOUND_FOR_THIS_SELECTED_COUNTRY: 'No Data Found For This Selected Country',
  ADD_THUMBNAIL_IMG: 'Add Thumbnail Image',
  SELECT_FROM_BELOW_IMAGES: 'Select From Below Default Images',
  UPLOAD_YOUR_OWN: 'Upload Your Own',
  OR: 'OR',
  DRAG_AND_DROP_IMAGES: 'Drag and Drop Image Here',
  ERROR_UPLOADING_MEDIA: 'Error uploading media',
  FAILED_UPDATE_PASSWORD_STATUS: 'Sorry, Could Not Update Password. Please Try Again Later.',
  SET_NEW_PASSWORD: 'Set New Password',
  REQUEST_FOR_EMAIL_LINK: 'Request for Email Link',
  RESET_PASSWORD: 'Reset Password',
  CHANGE_PHONE_NUMBER: 'Change Phone Number',
  TRENDING_NEWS_TIME_DURATION: 'Trending News Time Duration',
  SELECT_DURATION: 'Select Duration (In Hours)',
  ENTER_DURATION: 'Enter Duration (In Hours)',
  HOURS_SHOULD_BE_LESS_THAN_200: 'Maximum Duration Can Be 200 hrs',
  SELECT_TRENDING_NEWS: 'Select Trending News Hour',
  ENTER_TRENDING_NEWS: 'Enter Trending News Hour',
  FULL_FREE_NEWS: 'Full Free News',
  SELECT_FREE_NEWS_STATUS: 'Select Free News Status',
  FAILED_TO_UPDATE_FREE_NEWS_STATUS: 'Failed To Update Free News Status.',
  SELECT_MOBILE_OS_PLATFORM: 'Select Mobile OS Platform',
  CHANGE_EMAIL: 'Change Email',
  SEARCH_OCCUPATION: 'Search Occupation',
  SEARCH_USER_ROLE: 'Search User Role',
  SEARCH_STATE: 'Search State',
  PAYMENT: 'PAYMENT MODES',
  FEATURES: 'FEATURES',
  SUBSCRIPTION_MENU: 'MENU',
  PLEASE_SELECT_COUNTRY: 'Please Select Country',
  CHOOSE_FILE: 'Choose File',
  AVAILABLE_COUNTRIES: 'Available Countries',
  SELECTED_COUNTRIES: 'Selected Countries',
  PAYMENT_CHANNEL_NAME: 'Payment Channel Name',
  PAYMENT_CHANNEL_LOGO: 'Payment Channel Logo',
  PAYMENT_CHANNEL_REGION: 'Payment Channel Regions',
  ADD_EMAIL_OR_PHONE_NUMBER: 'Add Email or Phone Number',
  ARE_YOU_SURE_YOU_WANT_TO_REJECT_GLOBAL_DIFFERENTIALS: 'Are You Sure You Want To Reject Global Differentials?',
  ARE_YOU_SURE_YOU_WANT_TO_REJECT_GLOBAL_RAW_COFFEE_PRICE: 'Are You Sure You Want To Reject Global Raw Coffee Price?',
  GLOBAL_DIFFERENTIALS_REJECTED_SUCCESSFULLY: 'Global Differentials Rejected Successfully',
  GLOBAL_RAW_COFFEE_PRICE_REJECTED_SUCCESSFULLY: 'Global Raw Coffee Price Rejected Successfully',
  FAILED_TO_REJECT_GLOBAL_DIFFERENTIALS: 'Failed To Reject Global Differentials.',
  FAILED_TO_FETCH_THE_COUNTRY_DATA: ' Failed To Fetch The Country Data',
  FETCHING: 'Fetching... ',
  NO_RESULTS_FOUND: 'No Results Found',
  INACTIVE_SUBSCRIPTION_MESSAGE: 'This subscription is currently inactive. Kindly activate it to add payment methods.',
  NO_DATA_FOUND_MESSAGE: 'No Data Found',

  // PAYMENT REPORTS
  PAYMENT_REPORTS: 'PAYMENT REPORTS',
  INVOICE_NUMBER: 'Invoice Number',
  TOTAL_AMOUNT: 'Total Amount',
  PAYMENT_DATE: 'Payment Date',
  PAYMENT_SOURCE: 'Payment Source',
  PAYMENT_STATUS: 'Payment Status',
  SUBSCRIPTION_ID: 'Subscription Name',
  ORDER_ID: 'Order Id',
  ALL_REPORTS: 'All Reports',
  PENDING_REPORT: 'Pending',
  SUCCESS_REPORT: 'Success',
  AUTHORIZED_REPORT: 'Authorized',
  FILTER_REPORT: 'Filter by Status',
  INVOICE: 'Invoice',
  DOWNLOAD_INVOICE: 'Download Invoice',
  RECONCILE: 'Reconcile',
  RECONCILE_FORM: 'Reconcile Form',
  TALLY_DATE: 'Tally Date',
  UTR_NO: 'UTR No',
  SETTLEMENT_NO: 'Settlement No',
  TRANSACTION_DATE: 'Transaction Date',
  DATE_AND_TIME: 'Bank Deposit Date And Time',

  GROSS_AMOUNT: 'Gross Amount',
  DEDUCTIONS: 'Deductions',
  NET_AMOUNT: 'Net Amount',
  PAYMENT_ID: 'Payment ID',
  FEE: 'Fee Amount',
  TAX: 'Tax Amount',
  INVOICE_AMOUNT: 'Invoice Amount',

  FILTER_BY_PAYMENT_SOURCE: 'Payment Source',
  FILTER_STATUS: 'Payment Status',
  TALLY_DATE_IS_REQUIRED: 'Tally Date Is Required',
  UTR_NO_IS_REQUIRED: 'UTR No Is Required',
  SETTLEMENT_NO_IS_REQUIRED: 'Settlement No. Is Required',
  TRANSACTION_DATE_IS_REQUIRED: 'Transaction Date Is Required',
  GROSS_AMOUNT_IS_REQUIRED: 'Gross Amount Is Required',
  DEDUCTIONS_ARE_REQUIRED: 'Deductions Are Required',
  DATE_AND_TIME_IS_REQUIRED: 'Bank Deposit Date And Time Are Required',
  TAX_IS_REQUIRED: 'Tax Amount Is Required',
  FEE_IS_REQUIRED: 'Fee Amount Is Required',
  INVOICE_AMOUNT_IS_REQUIRED: 'Invoice Amount Is Required',
  PAYMENT_SOURCE_IS_REQUIRED: 'Payment Source Is Required',
  PAYMENT_DATE_IS_REQUIRED: 'Payment Date Is Required',
  DEDUCTIONS_IS_REQUIRED: 'Deduction Amount Is Required',
  NET_AMOUNT_IS_REQUIRED: 'Net Amount Is Required',

  RAZOR_PAY: 'RazorPay',
  PAY_PAL: 'PayPal',
  STRIPE: 'Stripe',
  GOLD: ' Gold',
  PLATINUM: 'Platinum',
  DO_YOU_WANT_TO_DOWNLOAD_CURRENT_REPORTS: 'Do You Want To Download Current Reports?',
  INVOICE_GENERATION_FAILED: 'Sorry, Could Not Generate Invoice. Please Try Again Later',

  // Reconciled Report
  RECONCILED_REPORT: 'RECONCILED PAYMENT REPORTS',
  RECONCILED_REPORT_API_FAIL: 'Sorry, Could Not Fetch The Reconciled Report. Please Try Again Later',
  RECONCILED_REPORT_NO_DATA: 'No Reconciled Data Is Available To Display',
  YOU_NEED_TO_SELECT_MINIMUM_OF_4_COLUMNS: 'You Need To Select Minimum of 4 Columns',
  FILTER: 'Filter',

  // Missing Payment Reprot
  ADD_MISSING_REPORT: 'Add Missing Report',
  ADD_MISSING_REPORT_FORM: 'Add Missing Payment Report Form',
  ENTER_VALID_EMAIL: 'Enter A Valid Email',
  SUBSCRIPTION_ID_IS_REQUIRED: 'Subscription Name Is Required',

  // USER STATS
  USER_STATS: 'USER STATS',
  USER_NAME: 'User Name',
  TOTAL_USERS: 'Total Users',
  TOTAL: 'Total',
  ACTIVE_USERS: 'Active Users',
  MORE_OPTIONS: 'More Options',
  REPORTS: 'Reports',
  APPLY: 'Apply',
  DOWNLOAD: 'Download',
  DOWNLOAD_REPORTS: 'Download Reports',
  FROM_DATE_TO_DATE: 'From Date - To Date',
  DEVICE_USED: 'Device Used',
  CREATED_ON: 'Created On',
  SUBSCRIPTION_EXPIRY_DATE: 'Subscription Expiry Date',
  USER_REGISTERED: 'User Registered',
  SL: 'SL',
  USER_STATS_REPORT: 'User_Stats_Report',
  STATS_REPORT: 'Stats_Report',

  // Coffee Quotes
  VOL: 'Volume',
  HIGH: 'High',
  LOW: 'Low',
  OPEN: 'Open',
  BID: 'Bid',
  ASK: 'Ask',
  OPEN_INT: 'Open Int',
  NET_HIGH: 'Net-High',
  NET_LOW: 'Net-Low',
  NET_CHG: 'Net Chg',
  PRE_CLOSE: 'Pre Close',
  BSIZE: 'B-size',
  ASIZE: 'A-size',
  LET_S_HELP: 'Let’s help',
  CHG: 'Chg',

  GRADE: 'Grade',
  NET_PRICE: 'Net Price',
  OUT_RATE: 'Out Rate',
  DIFFERENTIAL: 'Differential',
  TIME: 'Time',
  ROBUSTA_CONTRACTS: 'Robusta Contracts',
  ARABICA_CONTRACTS: 'Arabica Contracts',
  CONTRACT_TYPE: 'Contract Type',
  WEEK_HIGH: 'Weeks High',
  WEEK_LOW: 'Weeks Low',
  TERMINALS: 'Terminals',
  B_SIZE: 'B-Size',
  A_SIZE: 'A-Size',
  COMPARED_TO_PREV_DAY: 'Compared to prev day',
  ROBUSTA_DATA: 'Robusta Data',
  ARABICA_DATA: 'Arabica Data',
  TINECAPHE: 'Tinecaphe',
  ICE_MARKET: 'Ice Market',

  // Footer
  COPYRIGHT: '© Copyright',
  COFFEE_WEB: 'CoffeeWeb Technologies Private Limited',
  ALL_RIGHTS_RESERVED: 'All Rights Reserved.',

  // Coffee Reports

  COFFEE_REPORTS: 'COFFEE REPORTS',
  CERTIFIED_STOCKS: 'Certified Stocks',
  COMPARE_TO_PRE_DAY: 'Compare To Pre Day',
  NON_TENDERABLE: 'Non Tenderable',
  SUSPENDED: 'Suspended',
  PENDING_GRADING_REPORT: 'Pending Grading Report',
  DAILY_GRADING_SUMMARY: 'Daily Grading Summary',
  ORIGIN: 'Origin',
  VALID: 'Valid',
  ADD_COUNTRY_STOCK_REPORT: 'ALL COUNTRY CERTIFIED STOCKS',
  ANTWERP: 'Antwerp',
  HAMBURG: 'Hamburg',
  HOUSTON: 'Houston',
  NEW_ORLEANS: 'New Orleans',
  NEW_YORK: 'New York',
  VIRGINIA: 'Virginia',
  BARCELONA: 'Barcelona',
  MIAMI: 'Miami',
  ORIGIN_ERROR_MESSAGE: 'Origin Is Required',
  DATE_ERROR_MESSAGE: 'Date Is Required',
  ANTWERP_ERROR_MESSAGE: 'Antwerp Is Required',
  BARCELONA_ERROR_MESSAGE: 'Barcelona Is Required',
  HAMBURG_ERROR_MESSAGE: 'Hamburg Is Required',
  HOUSTON_ERROR_MESSAGE: 'Houston Is Required',
  NEW_ORLEANS_ERROR_MESSAGE: 'New Orleans Is Required',
  NEW_YORK_ERROR_MESSAGE: 'New York Is Required',
  VIRGINIA_ERROR_MESSAGE: 'Virginia Is Required',
  MIAMI_ERROR_MESSAGE: 'Miami Is Required',
  PRE_DAY_ERROR_MESSAGE: 'Compare To Previous Day Is Required',
  VALID_ERROR_MESSAGE: 'Valid Is Required',
  SELECT_PORT: 'Select Port',
  PORT_ERROR_MESSAGE: 'Port Is Required',
  VALID_IS_REQUIRED: 'Valid Is Required',
  NON_TENDERABLE_ERROR_MESSAGE: 'Non Tenderable Is Required',
  SUSPENDED_ERROR_MESSAGE: 'Suspended Is Required',
  SELECT_MARKET: 'Select Market',
  MARKET_FIELD_ERROR_MESSAGE: 'Market Is Required',
  COUNTRY_ERROR_MESSAGE: 'Country Is Required',
  TOTAL_CERTIFIED_STOCK: 'Total Certified Stocks',
  ALL_COUNTRY_CERTIFIED_STOCK: ' All Country Certified Stocks',
  PORT_WISE_STOCK_REPORT: 'Port Wise Certified Stocks',
  CHOOSE_ORIGIN: 'Select Origin',
  CHOOSE_PORT: 'Select Port',
  USDA_COFFEE_REPORT: 'USDA COFFEE REPORT',
  ADD_USDA_COFFEE_REPORT: 'ADD USDA COFFEE REPORT',
  EDIT_USDA_COFFEE_REPORT: 'EDIT USDA COFFEE REPORT',
  TAG: 'Tag',
  TITLE: 'Title',
  REPORT_LINK: 'Report Link',
  IS_UPCOMING: 'Is Upcoming',
  ENTER_REPORT_CONTENT: 'Enter Report Content',
  CONTENT: 'Content',
  TITLE_IS_REQUIRED: 'Title Is Required',
  TAG_IS_REQUIRED: 'Tag Is Required',
  CONTENT_IS_REQUIRED: 'Content Is Required',
  REPORT_LINK_IS_REQUIRED: 'Report Link Is Required',
  DATE_IS_REQUIRED: 'Date Is Required',
  PUBLISH_DATE: 'Publish Date',
  POST_USDA_REPORT_DATA_SUCCESS: 'USDA Coffee Report Created Successfully',
  DELETE_USDA_COFFEE_REPORT: 'Are you sure you want to delete this report?',
  DELETE_USDA_COFFEE_REPORT_FAILED: 'Sorry, Could Not Delete USDA Coffee Report Data. Please Try Again Later',
  USDA_COFFEE_REPORT_DATA_FAILED: 'Sorry, Could Not Fetch USDA Coffee Report Data. Please Try Again Later',
  USDA_COFFEE_REPORT_NO_DATA: 'Sorry, Currently No USDA Coffee Report Data. Please Try Again Later',
  USDA_COUNTRIES_LABEL: 'USDA Countries',
  REPORT_DATA_ADDED_SUCCESSFULLY: 'Report Data Added Successfully',
  PRE_DAY: 'Previous Day',
  PREVIOUS_DAY_REQUIRED: 'Previous Day Is Required',
  EDIT_CERTIFIED_STOCK_REPORT: 'Edit Total Certified Stock Report',
  SORRY_COULD_NOT_UPDATE_REPORT_DATA_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Update The Data. Please Try Again Later.',
  CERTIFIED_STOCK_ERROR_MESSAGE: 'Certified Stocks Is Required',
  PENDING_GRADING_REPORT_ERROR_MESSAGE: 'Pending Grading Report Is Required',
  GRADING_ERROR_MESSAGE: 'Daily Grading Summary Is Required',
  REPORT_DATA_UPDATED_SUCCESSFULLY: 'Report Data Updated Successfully',
  EDIT_PORT_WISE_CERTIFIED_REPORT: 'Edit Port Wise Certified Report',
  PORT_NAME: 'Port Name',
  EDIT_ALL_COUNTRY_CERTIFIED_STOCK_REPORT: 'Edit All Country Certified Stock Report',
  REPORT_DATA_DELETED_SUCCESSFULLY: 'Report Data Deleted Successfully',
  FAILED_TO_DELETE_REPORT_DATA: 'Failed to Delete Report Data',
  BANK_DEPOSIT_DATE_AND_TIME_IS_REQUIRED: 'Bank Deposit Date And Time Are Required',

  // COT Reports
  LOT_REPORTS: 'LOT WISE REPORTS',
  LOT_WISE_REPORT: 'Lot Wise Report',
  TRADER_WISE_REPORT: 'Trader Wise Report',
  WEEKLY_WISE_REPORT: 'Weekly Wise Report',
  SELECT_REPORT: 'Select Report',
  LONG: 'Long',
  SHORT: 'Short',
  NET: 'Net',
  SPREADING: 'Spreading',
  PERCENTAGE_LONG: '% Long',
  PERCENTAGE_SHORT: '% Short',
  ADD_LOT_WISE_REPORT: 'Add Lot Wise Report',
  SELECT_CATEGORY: 'Select Category',
  LONG_ERROR_MESSAGE: 'Long Is Required',
  SHORT_ERROR_MESSAGE: 'Short Is Required',
  PERCENTAGE_LONG_ERROR_MESSAGE: '% Long Is Required',
  PERCENTAGE_SHORT_ERROR_MESSAGE: '% Short Is Required',
  NET_ERROR_MESSAGE: 'Net Is Required',
  SPREADING_ERROR_MESSAGE: 'Spreading Is Required',
  TOTAL_ERROR_MESSAGE: 'Total Is Required',
  CATEGORY_ERROR_MESSAGE: 'Category Is Required',
  TERMINAL_LEVEL_ERROR_MESSAGE: 'Terminal Level Is Required',
  OPEN_INTEREST_ERROR_MESSAGE: 'Open Interest Is Required',
  NET_CHANGE_ERROR_MESSAGE: 'Net Change Is Required',
  CATEGORY_TITLE: 'Categories',
  DIFFERENCE: 'Difference',
  ROBUSTA_TITLE: 'ROBUSTA',
  ARABICA_TITLE: 'ARABICA',
  PERCENT_LONG: '%Long',
  PERCENT_SHORT: '%Short',
  COMPARE_PRE_DAY_ERROR_MESSAGE: 'Compare To Previous Day Is Required',

  WEEKLY_WISE_REPORTS: 'WEEKLY WISE REPORT ',
  ADD_WEEKLY_WISE_REPORTS: 'ADD WEEKLY WISE REPORT',
  DELETE_WEEKLY_WISE_REPORT: 'Failed To Delete Weekly Wise Reports',
  SELECT_DATE: 'Select Date',
  SELECT_LABEL: 'Select Label',
  CONTRACT_DATE: ' Contract Date',
  PERCENTAGE_OPEN_INTEREST: ' % Of Open Interest',

  CONTRACT_DATE_IS_REQUIRED: 'Contract Date Is Required',
  OPEN_INTEREST_DATE_IS_REQUIRED: 'Open Interest Date Is Required',
  NET_CHANGE_DATE_IS_REQUIRED: 'Net Change Date Is Required',
  PERCENTAGE_OPEN_INTEREST_IS_REQUIRED: ' % Open Interest Is Required',
  TOTAL_TRADERS_IS_REQUIRED: 'Total Traders Is Required',
  SPREADING_IS_REQUIRED: ' Spreading Is Required',
  FAILED_TO_ADD_DATA: 'Failed To Add Data',
  LABEL_IS_REQUIRED: 'Label Is Required',
  WEEKLY_WISE_REPORT_HAS_BEEN_SUBMITTED_SUCCESSFULLY: 'Weekly Wise Report Has Been Submitted Successfully',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_WEEKLY_REPORT: 'Are You Sure You Want To Delete Weekly Wise Report?',
  REPORT_HAS_BEEN_DELETED_SUCCESSFULLY: 'Report Has Been Deleted Successfully',
  EDIT_WEEKLY_WISE_REPORT: 'Edit Weekly Wise Report',
  EDIT_LOT_WISE_REPORT: 'Edit Lot Wise Report',

  // TRADER WISE REPORT
  TRADER_WISE_REPORT_TITLE: 'TRADER WISE REPORT',
  ADD_TRADER_WISE_REPORT_TITLE: 'ADD TRADER WISE REPORT',
  EDIT_TRADER_WISE_REPORT_TITLE: 'EDIT TRADER WISE REPORT',
  TOTAL_TRADERS: 'Total Traders',
  TOTAL_TRADERS_ERROR_MESSAGE: 'Total Traders Is Required',

  // Mobile menus
  ARE_YPU_SURE_WANT_TO_DELETE_THIS_MOBILE_MENU: 'Are you sure you want to delete this menu?',
  MENU_PATH: 'Menu Path',
  COUNTRY_BASED: 'Country Based',
  EDIT_MENU: 'Edit Menu',
  WEB_MENU: 'WEB MENU',
  MOBILE_MENU: 'MOBILE MENU',
  MENU_NAME_IS_REQUIRED: 'Menu name is required',
  MENU_PATH_IS_REQUIRED: 'Menu path is required',
  REORDER_MENUS: 'Reorder Menus',
  CHOOSE_MENU_ICON: 'Choose Menu Icon',

  // Holiday Module
  LIST_HOLIDAY: 'LIST HOLIDAY',
  STARTS_FROM: 'Starts from',
  ENDS_AT: 'Ends at',
  ADD_NEW_HOLIDAY: 'ADD NEW HOLIDAY',
  EDIT_HOLIDAY: 'EDIT HOLIDAY',
  FAILED_TO_GET_HOLIDAY_LIST: 'Failed to Get Holiday List',
  ARE_YPU_SURE_WANT_TO_DELETE_THIS_HOLIDAY: 'Are you sure you want to delete this holiday?',
  HOLIDAY_HAS_BEEN_DELETED_SUCCESSFULLY: 'Holiday Has Been Deleted Successfully',
  HOLIDAY_DATA_ADDED_SUCCESSFULLY: 'Holiday Data Added Successfully',
  HOLIDAY_DATA_UPDATED_SUCCESSFULLY: 'Holiday Data Updated Successfully'
}

export default EN_LANGUAGES
