import './styles.scss'
import React, { useEffect, useState } from 'react'
import { Sidebar } from 'primereact/sidebar'
import { PanelMenu } from 'primereact/panelmenu'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { Avatar } from 'primereact/avatar'
import { useHistory } from 'react-router-dom'
import CoffeeWebMainLogo from 'Assets/Icons/Logo/Main_Logo.svg'
import coffeeweblogo from 'Assets/Icons/Logo/Only_Logo.svg'
import { devCWTestLogo, devCwLogo } from 'Assets/Icons'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import packageJson from '../../../../../package.json'

const SideBar = ({ drawerState, handleToggleDrawer, mockInActiveStatus }) => {
  const userDetails = getLocalUserDetails()
  const roleNameWords = userDetails?.roleName.split(' ')

  const history = useHistory()
  const [inactive, setInactive] = useState(false)
  const [expandedMenu, setExpandedMenu] = useState(null)
  const [expandedSubMenu, setExpandedSubMenu] = useState(null)
  const [allMenus, setAllMenus] = useState([])
  const [profileText, setProfileText] = useState('')

  useEffect(() => {
    if (userDetails?.menus != null) {
      if (userDetails?.menus[0]) {
        setAllMenus(userDetails?.menus[0]?.subMenuModels)
      }
    }
    if (roleNameWords.length === 1) {
      setProfileText(roleNameWords[0][0])
    } else {
      const firstWordFirstLetter = roleNameWords[0][0]
      const lastWordIndex = roleNameWords.length - 1
      const lastWordFirstLetter = roleNameWords[lastWordIndex][0]

      setProfileText(firstWordFirstLetter + lastWordFirstLetter)
    }
  }, [])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockInActiveStatus) {
      setInactive(true)
    }
  }, [])

  const toggleSidebar = () => {
    setInactive(!inactive)
  }

  const handleMainMenuClick = (menuName) => {
    setExpandedMenu((prevMenu) => (prevMenu === menuName ? null : menuName))
  }

  const handleSubMenuClick = (menuName) => {
    setExpandedSubMenu((prevSubMenu) => (prevSubMenu === menuName ? null : menuName))
  }

  const handleMenuItemClick = (url) => {
    history.push(url)
  }
  const handleSubMenuItemClick = (url) => {
    history.push(url)
    handleToggleDrawer()
  }
  const handleUserInfoClick = () => {
    history.push(ROUTE_STRINGS.profile)
  }

  return (
    <>
      <div className={`layout-sidebar ${inactive ? 'inactive' : ''}`}>
        <div className="sidebar-header">
          <div className="logo">{inactive ? <img alt="logo" src={process.env.REACT_APP_NODE_ENV === 'production' ? coffeeweblogo : devCwLogo} onClick={toggleSidebar} className={process.env.REACT_APP_NODE_ENV === 'production' ? 'sidebar-inactive-logo' : 'cw-logo'} /> : <img alt="logo" src={process.env.REACT_APP_NODE_ENV === 'production' ? CoffeeWebMainLogo : devCWTestLogo} className="sidebar-inactive-logo" />}</div>
          <div className="toggle-menu-btn">{inactive ? null : <Button icon="pi pi-arrow-left" className="close-btn" onClick={toggleSidebar} />}</div>
        </div>

        <div className="menu-items">
          <PanelMenu
            data-testid="panel-menu"
            model={allMenus.map((menuItem) => ({
              label: menuItem.menuName,
              icon: 'pi pi-th-large',
              expanded: expandedMenu === menuItem.menuName,
              command: () => handleMainMenuClick(menuItem.menuName),
              items: menuItem.subMenuModels.map((subMenuItem) => {
                if (subMenuItem.subMenuModels.length > 0) {
                  return {
                    label: subMenuItem.menuName,
                    icon: 'pi pi-sort-amount-down',
                    expanded: expandedSubMenu === subMenuItem.menuName,
                    items: subMenuItem.subMenuModels.map((subSubMenuItem) => ({
                      label: subSubMenuItem.menuName,
                      icon: 'pi pi-sort-up',

                      command: () => handleMenuItemClick(subSubMenuItem.menuUrl),
                      expanded: expandedSubMenu === subSubMenuItem.menuName
                    })),
                    command: () => handleSubMenuClick(subMenuItem.menuName)
                  }
                }

                return {
                  label: subMenuItem.menuName,
                  icon: 'pi pi-sort-amount-down',
                  command: () => handleMenuItemClick(subMenuItem.menuUrl)
                }
              })
            }))}
            className="w-full md:w-25rem"
          />
        </div>
        <div className="side-menu-footer">
          <Divider />
          <div className="footer-content">
            <div className="avatar">
              <div>
                <Avatar label={profileText} shape="circle" className="avatar-logo" data-testid="avatar" />
              </div>
            </div>
            <div className="user-info" onClick={handleUserInfoClick}>
              <div>{`${userDetails?.firstName} ${userDetails?.lastName}`}</div>
              <div>{userDetails?.email}</div>
            </div>
          </div>
        </div>
        <div className="version-number">{packageJson.version}</div>
      </div>

      <div className="card flex justify-content-center mobile-sidebar">
        <Sidebar visible={drawerState} showCloseIcon={false} onHide={() => handleToggleDrawer()} className="p-sidebar">
          <PanelMenu
            data-testid="panel-menu"
            model={allMenus.map((menuItem) => ({
              label: menuItem.menuName,
              icon: 'pi pi-th-large',
              items: menuItem.subMenuModels.map((subMenuItem) => {
                if (subMenuItem.subMenuModels.length > 0) {
                  return {
                    label: subMenuItem.menuName,
                    icon: 'pi pi-sort-amount-down',
                    items: subMenuItem.subMenuModels.map((subSubMenuItem) => ({
                      label: subSubMenuItem.menuName,
                      icon: 'pi pi-sort-up',
                      command: () => handleSubMenuItemClick(subSubMenuItem.menuUrl)
                    }))
                  }
                }

                return {
                  label: subMenuItem.menuName,
                  icon: 'pi pi-sort-amount-down',
                  command: () => handleSubMenuItemClick(subMenuItem.menuUrl)
                }
              })
            }))}
            className="w-full md:w-25rem"
          />
        </Sidebar>
      </div>
    </>
  )
}

export default SideBar
