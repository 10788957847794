import './styles.scss'
import { React, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { TabView, TabPanel } from 'primereact/tabview'
import { Messages } from 'primereact/messages'
import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import DialogModal from 'Components/UIComponents/DialogModal'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { TABLE_CONST } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import { ButtonComponent } from 'Components/UIComponents'
import GlobalDifferentialUserPreview from './GlobalDifferentialUserPreview'

const ApproveGlobalDifferential = ({ setLoading, mockApproveGlobalDifferential, mockGlobalDifferentialData, mockShowUserPreview }) => {
  const { t } = useTranslation()
  const errorMessage = useRef(null)
  const rowCount = 10
  let paginationButtons = 5
  const { id: idUser } = getLocalUserDetails()

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [globalDifferentialData, setGlobalDifferentialData] = useState([])
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showUserPreview, setShowUserPreview] = useState(false)
  const [dropdownError, setDropdownError] = useState(false)
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    softAction: '',
    confirmAction: null
  })

  if (windowSize < TABLE_CONST.maxWidthTab) {
    paginationButtons = 1
  }

  useEffect(() => {
    getAllCountryData()
    window.addEventListener('resize', handleWindowResize)

    // Testing Purpose
    if (mockApproveGlobalDifferential) {
      setShowUserPreview(mockShowUserPreview)
      setGlobalDifferentialData(mockGlobalDifferentialData)
      globalDifferentials()
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (errorMessage.current) {
      errorMessage.current.clear()
      errorMessage.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: t('NO_DATA_FOUND_FOR_THIS_COUNTRY'), closable: false }])
    }
  })

  const getAllCountryData = async (id) => {
    try {
      setLoading(true)
      const { data } = await apiAdapterCoffeeWeb.getAllCountries()
      const sellerCountries = data.filter((ele) => ele.isCoffeeProducingCountry)

      setCountryList(sellerCountries)

      setLoading(false)
    } catch {
      setLoading(false)
      setModalState({
        showModal: true,
        message: t('FAILED_TO_FETCH_THE_COUNTRY_DATA'),
        modalType: 'error'
      })
    }
  }

  const globalDifferentials = async (idCountry) => {
    setLoading(true)
    try {
      let apiMethod

      if (idUser === 2) {
        apiMethod = apiAdapterCoffeeWeb.getGlobalDifferentialDataForAdmin
      } else if (idUser === 1) {
        apiMethod = apiAdapterCoffeeWeb.getGlobalDifferentialDataForSuperAdmin
      }
      if (apiMethod) {
        const response = await apiMethod(idCountry)

        const globalDifferentialData = response?.data?.returnLst

        setGlobalDifferentialData(globalDifferentialData)
      }
      setShowErrorMessage(false)
    } catch {
      setGlobalDifferentialData(null)
      setShowErrorMessage(true)
    } finally {
      setLoading(false)
    }
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleCurrencyChange = (selectedCountry) => {
    if (selectedCountry) {
      const item = countryList.find((element) => element?.id === selectedCountry.id)

      setSelectedCountry(selectedCountry, item)
      globalDifferentials(selectedCountry?.id)
    } else {
      setSelectedCountry(null)
      setGlobalDifferentialData(null)
      setShowErrorMessage(false)
      setDropdownError(false)
    }
  }

  const handleBlur = () => {
    if (!selectedCountry) {
      setDropdownError(true)
    }
  }

  const columns = [
    { dataField: 'qualityName', text: t('QUALITY') },
    { dataField: 'qualityCode', text: t('SCREENS') },
    { dataField: 'rangeStart', text: t('BETWEEN') },
    { dataField: 'rangeEnd', text: t('BETWEEN') },
    { dataField: 'terminal', text: t('TERMINAL') },
    { dataField: 'terminalLevel', text: t('TERMINAL_LEVEL') },
    { dataField: 'moisture', text: t('MOISTURE') },
    { dataField: 'screenRetention', text: t('SCREEN_RETENTION') },
    { dataField: 'defects', text: t('DEFECTS') },
    { dataField: 'remark', text: t('REMARKS') }
  ]

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header={t('QUALITY')} className="column-quality" />
        <Column header={t('DIFFERENTIALS')} colSpan={4} className="column-differential" />
        <Column header={t('QUALITY_STANDARDS')} colSpan={3} className="column-quality-standards" />
        <Column header={t('REMARKS')} className="column-remarks" />
      </Row>
      <Row>
        <Column header={''} />
        <Column header={t('SCREENS')} />
        <Column header={t('BETWEEN')} />
        <Column header={t('TERMINAL')} />
        <Column header={t('TERMINAL_LEVEL')} />
        <Column header={t('MOISTURE')} className="column-moisture" />
        <Column header={t('SCREEN_RETENTION')} className="column-screen-retention" />
        <Column header={t('DEFECTS')} className="column-defects" />
        <span className="space-between-columns">&nbsp;&nbsp;</span>
      </Row>
    </ColumnGroup>
  )

  const approveGlobalDifferential = () => {
    if (globalDifferentialData) {
      setModalState({
        showModal: true,
        message: t('ARE_YOU_SURE_YOU_WANT_TO_APPROVE_GLOBAL_DIFFERENTIALS'),
        modalType: 'info',
        confirmAction: confirmApproveGlobalDifferential
      })
    }
  }

  const confirmApproveGlobalDifferential = async () => {
    setLoading(true)
    setModalState({ showModal: false })
    const postData = {
      idUser,
      idCountry: selectedCountry?.id
    }

    try {
      let apiMethod

      if (idUser === 2) {
        apiMethod = apiAdapterCoffeeWeb.apporveGlobalDifferenceForAdmin
      } else if (idUser === 1) {
        apiMethod = apiAdapterCoffeeWeb.apporveGlobalDifferenceForSuperAdmin
      }
      if (apiMethod) {
        const response = await apiMethod(postData)

        setGlobalDifferentialData(null)
        setSelectedCountry(null)
        setDropdownError(false)
        setModalState({
          showModal: true,
          message: t('GLOBAL_DIFFERENTIALS_APPROVED_SUCCESSFULLY'),
          modalType: 'success'
        })
      }
    } catch {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_APPROVE_GLOBAL_DIFFERENTIALS'),
        modalType: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const rejectGlobalDifferential = () => {
    if (globalDifferentialData) {
      setModalState({
        showModal: true,
        message: t('ARE_YOU_SURE_YOU_WANT_TO_REJECT_GLOBAL_DIFFERENTIALS'),
        modalType: 'info',
        confirmAction: confirmRejectGlobalDifferential
      })
    }
  }

  const confirmRejectGlobalDifferential = async () => {
    setLoading(true)
    setModalState({ showModal: false })

    const IdCountry = selectedCountry?.id

    try {
      let apiMethod

      if (idUser === 2) {
        apiMethod = apiAdapterCoffeeWeb.rejectGlobalDifferentialData
      } else if (idUser === 1) {
        apiMethod = apiAdapterCoffeeWeb.rejectGlobalDifferentialData
      }

      if (apiMethod) {
        await apiMethod(IdCountry)
        setGlobalDifferentialData(null)
        setSelectedCountry(null)
        setDropdownError(false)
        setModalState({
          showModal: true,
          message: t('GLOBAL_DIFFERENTIALS_REJECTED_SUCCESSFULLY'),
          modalType: 'success'
        })
      }
    } catch (error) {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_REJECT_GLOBAL_DIFFERENTIALS'),
        modalType: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const onHide = () => {
    setModalState({ showModal: false })
  }

  const onDismissFunction = () => {
    setModalState({ showModal: false })
  }

  const onSuccess = () => {
    setModalState({ showModal: false })
  }

  const isButtonDisabled = !globalDifferentialData?.length

  const getTextColor = (value) => {
    if (value) {
      const convertedToNumber = parseFloat(value?.replace(/[$¢]/g, ''))

      if (convertedToNumber >= 0) {
        return { color: 'green' }
      }

      return { color: 'red' }
    }

    return {}
  }

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.name} src={option.flagsUrl} className={`mr-2 flag flag-${option.iso3.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.name}</div>
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const countryOptionTemplate = (option) => (
    <div className="flex align-items-center">
      <img alt={option.name} src={option.flagsUrl} className={`mr-2 flag flag-${option.iso3.toLowerCase()}`} style={{ width: '18px' }} />
      <div>{option.name}</div>
    </div>
  )

  return (
    <div className="global-differential-container">
      <div className="country-select-component">
        <Dropdown size="small" filter placeholder="Select a Country" inputId="country-list" value={selectedCountry} options={countryList} optionLabel="name" onChange={(e) => handleCurrencyChange(e.value)} valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="" data-testid="country-dropdown" />

        <div className="actions">
          <ButtonComponent type="custom" variant="neutral" className="user-preview-button" label={t('USER_PREVIEW')} icon="pi pi-eye" disabled={isButtonDisabled} onClick={() => setShowUserPreview(true)} />
          <ButtonComponent type="custom" variant="action" label="APPROVE" icon="pi pi-check-circle" disabled={isButtonDisabled} onClick={approveGlobalDifferential} />
          <ButtonComponent type="custom" variant="action" label="REJECT" icon="pi pi-times-circle" disabled={isButtonDisabled} onClick={rejectGlobalDifferential} />
        </div>
      </div>
      {globalDifferentialData?.length > 0 && (
        <TabView className="card">
          {globalDifferentialData?.map((ele, index) => (
            <TabPanel header={ele.country} key={index}>
              <div>
                <TabView>
                  {ele.cropyear?.map((cropyearEle, ind) => (
                    <TabPanel header={cropyearEle.croyear} key={ind}>
                      <TabView>
                        {cropyearEle?.globalDifferentialAdminDTOs?.map((qualityGroup, cropYearIndex) => (
                          <TabPanel header={qualityGroup.qualityGroupName} key={cropYearIndex}>
                            <DataTable value={qualityGroup.globalDifferentialData} headerColumnGroup={headerGroup} paginator rows={rowCount} pageLinkSize={paginationButtons}>
                              <Column field={columns[0].dataField}></Column>
                              <Column field={columns[1].dataField}></Column>
                              <Column
                                body={(rowData) => (
                                  <div className="global-differential-column-container">
                                    <div className="global-differential-column-div">
                                      <span className="global-differential-column-span" style={getTextColor(rowData[columns[2].dataField])}>
                                        {rowData[columns[2].dataField]}
                                      </span>
                                      <span className="global-differential-column-span" style={getTextColor(rowData[columns[3].dataField])}>
                                        {rowData[columns[3].dataField]}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              />
                              <Column field={columns[4].dataField}></Column>
                              <Column field={columns[5].dataField}></Column>
                              <Column field={columns[6].dataField}></Column>
                              <Column field={columns[7].dataField}></Column>
                              <Column field={columns[8].dataField}></Column>
                              <Column field={columns[9].dataField}></Column>
                            </DataTable>
                          </TabPanel>
                        ))}
                      </TabView>
                    </TabPanel>
                  ))}
                </TabView>
              </div>
            </TabPanel>
          ))}
        </TabView>
      )}
      <GlobalDifferentialUserPreview showUserPreview={showUserPreview} setShowUserPreview={setShowUserPreview} globalDifferentialData={globalDifferentialData} confirmApproveGlobalDifferential={() => confirmApproveGlobalDifferential()} />
      {showErrorMessage && <Messages ref={errorMessage} style={{ width: 'fitContent' }} />}
      <DialogModal {...modalState} onDismiss={onDismissFunction} onRetry={commonMethods.handleRetry} onHide={onHide} onSuccess={onSuccess} onConfirmation={modalState?.confirmAction} />
    </div>
  )
}

export default Loader(ApproveGlobalDifferential)
